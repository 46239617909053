<div *ngIf="!isToExport && !hiddeFilter" class="card">
    <div class="row ">
        <div class="col-xs col-md-3 ">
            <div class="inline-flex">

                <label class="">Proveedor</label>
                <div *ngIf="proveedor" class="pointer close-icon ml-auto">
                    <button (click)="proveedor = null; buscar() " type="button" class="btn btn-sm btn-light"><i
                            class=" ti-close  "></i></button>
                </div>
            </div>
            <div class="" *ngIf="!proveedor">
                <ng-select [typeahead]="inputProveedores" [loading]="loadingProveedores" [items]="proveedores"
                    name="dsfdbrdafwseqcda" [(ngModel)]="proveedor" (ngModelChange)="buscar()"
                    notFoundText="no se encontraron resultados" placeholder="Buscar Proveedor" bindLabel="NOMBRES"
                    [searchFn]="customSearchFn">
                    <ng-template ng-option-tmp let-item="item">
                        <div>
                            <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>



                            <small>{{item.RUC | ruc}}</small>
                        </div>

                    </ng-template>
                </ng-select>
            </div>


            <div class="" *ngIf="proveedor">
                <div>
                    <div *ngIf="proveedor.APELLIDOS">{{proveedor .NOMBRES}} {{proveedor .APELLIDOS}}
                    </div>

                    <small>{{proveedor.RUC | ruc}}</small>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-3 ">
            <div class="inline-flex">

                <label class="">Cliente</label>
                <div *ngIf="cliente" class="pointer close-icon ml-auto">
                    <button (click)="cliente = null;buscar() " type="button" class="btn btn-sm btn-light"><i
                            class=" ti-close  "></i></button>
                </div>
            </div>
            <div class="" *ngIf="!cliente">
                <ng-select [typeahead]="inputClientes" [loading]="loadingClientes" [items]="clientes"
                    name="dsfdbrdafwseqcda" [(ngModel)]="cliente" (ngModelChange)="buscar()"
                    notFoundText="no se encontraron resultados" placeholder="Buscar Cliente" bindLabel="NOMBRES"
                    [searchFn]="customSearchFn">
                    <ng-template ng-option-tmp let-item="item">
                        <div>
                            <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>

                            <small>{{item.RUC | ruc}}</small>
                        </div>

                    </ng-template>
                </ng-select>
            </div>


            <div class="" *ngIf="cliente">
                <div>
                    <div *ngIf="cliente.APELLIDOS">{{cliente .NOMBRES}} {{cliente .APELLIDOS}}
                    </div>

                    <small>{{cliente.RUC | ruc}}</small>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-3 ">
            <div style="display: flex;" class="">
                <span>Contrato</span>
                <div *ngIf="contrato" style="height: fit-content;" class="pointer close-icon ml-auto">
                    <button (click)="contrato = null; buscar()" type="button" class="btn btn-sm btn-light"><i
                            class=" ti-close  "></i></button>
                </div>
            </div>

            <div *ngIf="!contrato" class="">

                <div style="display: flex;" class="">

                    <button type="button" (click)="showModal = true" class="btn btn-inverse">Buscar
                        Contrato</button>

                </div>
            </div>
            <div *ngIf="contrato" class="">
                <div style="display: flex;" class="">

                    <div class="card bg-light">
                        <div class="card-body">

                            <span><b>Titular:</b> {{contrato.titular.NOMBRES}}
                                {{contrato.titular.APELLIDOS}}</span>
                            <br>
                            <span><b>Servicio:</b> {{contrato.producto.NOMBRE}}</span>
                            <br>
                            <span><b>Número de contrato:</b> {{contrato.nro_contrato}}</span>
                            <br>
                            <span><b>Fecha de creación:</b> {{contrato.fecha_creacion_unix | date
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-3 ">

            <p>Fondo: </p>
            <div style="min-width: max-content;" class="inline-flex">
                <div style="min-width: 100%;" *ngIf="!fondo">
                    <ng-select [items]="fondos" [(ngModel)]="fondo" (ngModelChange)="seleccionarFondo(fondo._id)"
                        (search)="searchBancos($event)" notFoundText="no se encontraron resultados"
                        placeholder="Filtrar por fondo" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                <small>{{item.RUC | ruc}}</small>
                            </div>

                        </ng-template>
                    </ng-select>
                </div>

                <div class="ml-2" *ngIf="fondo">
                    <span>
                        <div *ngIf="fondo.APELLIDOS">{{fondo.APELLIDOS}} {{fondo.NOMBRES}} </div>

                        <small>{{fondo.RUC | ruc}}</small>
                    </span>
                </div>
                <div *ngIf="fondo" (click)="fondo = null; " class="pointer close-icon ml-2">
                    <button type="button" (click)="seleccionarFondo(null)" class="btn btn-sm btn-light"><i
                            class=" ti-close  "></i></button>
                </div>




            </div>

            <div class="  mt-3 mb-0 form-group">
                tipo de Movimiento
                <ng-select [(ngModel)]="tipoMovimiento" (ngModelChange)="buscar()">
                    <ng-option *ngFor="let item of ['GASTO','TRANSFERENCIA', 'TODOS']" [value]="item">
                        <div>
                            {{item}}
                        </div>
                    </ng-option>
                </ng-select>
            </div>

        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-xs col-md-3 ">
            <div class="form-group">
                <label for="nro_factura">Nro Factura</label>
                <input type="text" #nroContrato [(ngModel)]="nro_factura" (ngModelChange)=" buscar()"
                    id='nro_factura_search' name="nro_factura" class="form-control" placeholder="numero de factura"
                    aria-describedby="nrofacturaid">
            </div>
        </div>
        <div class="col-xs col-md-3 ">
            <div class="inline-flex">
                <div style="min-width: 100%;">
                    <mat-form-field appearance="fill">
                        <mat-label>Filtrar por Fecha</mat-label>
                        <mat-date-range-input [formGroup]="rangeFecha" [rangePicker]="pickerFecha">
                            <input autocomplete="off" matStartDate formControlName="start" placeholder="fecha inicio">
                            <input matEndDate formControlName="end" (dateChange)="filtroPorFecha()"
                                placeholder="fecha fin">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="pickerFecha"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerFecha></mat-date-range-picker>
                        <mat-error *ngIf="rangeFecha.controls.start.hasError('matStartDateInvalid')">
                            Invalid start date</mat-error>
                        <mat-error *ngIf="rangeFecha.controls.end.hasError('matEndDateInvalid')">Invalid
                            end date</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-3 ">
            <div class="inline-flex">
                <div style="min-width: 100%;">
                    <div class="mx-auto">
                        <h5>Cuenta Gasto</h5>

                        <div *ngIf="cuentaGasto" style="display: block;">
                            <span><b class="lead">Tipo: </b> {{cuentaGasto.nombre_padre}}</span>
                            <br>
                            <span><b class="lead">Descripcion: </b> {{cuentaGasto.descripcion}}</span>
                        </div>

                        <button *ngIf="!cuentaGasto" type="button" (click)="showModalCuentas = true"
                            class="btn mt-2  btn-info">Seleccionar Cuenta</button>
                        <button *ngIf="cuentaGasto" type="button" (click)="showModalCuentas = true"
                            class="btn mt-2 btn-inverse">Cambiar Cuenta</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-3 ">
            <div class="inline-flex">
                <div style="min-width: 100%;">
                    <div class="mx-auto">
                        <h5>Rango Monto</h5>
                        <div class="d-flex w-100">
                            <div class="d-block">
                                <label for="inicio">Desde</label>
                                <input type="number" id="inicio" class="form-control" [(ngModel)]="rangoMontoInicio">
                            </div>
                            <div class="d-block ml-2">

                                <label for="fin">Hasta</label>

                                <input type="number" id="fin" class="form-control" [(ngModel)]="rangoMontoFin">
                            </div>
                        </div>
                        <button class="btn mt-2 btn-light" (click)="buscar()">Filtrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="ribbon-wrapper card">
    <div class="card-body">
        <h4 *ngIf="showTitle" class="ribbon ribbon-danger"> Egresos </h4>

        <table *ngIf="movimientos" class="table table-hover">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Fondo</th>
                    <th>Nro Factura</th>
                    <th style="min-width: 150px;">Fecha</th>
                    <th>Nombre</th>
                    <th style="width: 25%;">Comentario</th>
                    <th style="min-width: 150px;">Proveedor</th>
                    <th>Monto</th>

                </tr>
            </thead>
            <tbody>
                <tr [routerLink]="['/admin/gasto', item._id]" routerLinkActive="router-link-active"
                    *ngFor="let item of movimientos | paginate: { itemsPerPage: 10,totalItems:count, currentPage: page } ; let i = index; "
                    [ngClass]="'pointer'">
                    <td>{{page*10 + (i+1) - 10}}</td>
                    <td *ngIf="item.fondo">{{item.fondo.APELLIDOS}}</td>
                    <td *ngIf="!item.fondo">--</td>
                    <td>{{item.nro_comp_banco}}</td>

                    <td>
                        <p style="font-family:'Lucida Console', monospace">{{item.fecha_creacion_unix | date:' dd MMM
                            y'}}</p>


                        <!-- <br> {{item.fecha_creacion_unix | date:' h:mm a'}} -->


                    </td>
                    <td>{{item.nombre}}</td>
                    <td>{{item.comentario}}</td>
                    <td *ngIf="item.proveedor">{{item.proveedor.APELLIDOS}} {{item.proveedor.NOMBRES}}</td>
                    <td *ngIf="!item.proveedor">---</td>


                    <td *ngIf="item.monto_haber">{{item.monto_haber | currency: '':'':'2.0'}} Gs</td>
                    <td *ngIf="item.monto_total">{{item.monto_total | currency: '':'':'2.0'}} Gs</td>

                </tr>

            </tbody>
        </table>
        <div *ngIf="!isToExport">
            <div style="width: 100%;">
                <div style="display: flex;">
                    <div class="mx-auto">
                        <pagination-controls *ngIf="count>10" (pageChange)="pageChanged($event); page = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
            <div>Total: {{total | currency: '':'':'2.0'}} Gs</div>

            <button type="button" (click)="exportarPDF()" class="btn btn-info">Exportar PDF</button>
            <button type="button" (click)="excelAbaco()" class="btn btn-info">Excel Abaco</button>
        </div>
    </div>
</div>

<app-modal-contratos (contratoSelected)="contrato  = $event; buscar()" [showFilters]="true" [cliente]="cliente"
    (onClose)="showModal = false" *ngIf="showModal"></app-modal-contratos>



<app-modal-cuentas-gastos *ngIf="showModalCuentas" (onSelectedCategory)="cuentaPadreSeleccionada = $event; buscar(); showModalCuentas = false;" (onSelected)="
    cuentaSeleccionada = $event;
    buscar();
   showModalCuentas = false;
   " (onClose)="
showModalCuentas = false
">
</app-modal-cuentas-gastos>