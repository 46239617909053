<div class="card">
    <div style="font-size: medium;" class="card-body">
        <h4><span class="lstick"></span> Filtros </h4>

        <div class="row">
            <!-- <div class="col-xs col-md">
                <div class="form-group">
                    <label for="fecha_inicio">Fecha inicio :
                        <span><b>{{date_start | date}}</b></span>
                    </label>
                    <input type="text" name="fecha_inicio" [(ngModel)]="date_start_StiringTemporal" (ngModelChange)="date_start = calcularFecha(date_start_StiringTemporal)" id="fecha_inicio" class="form-control" placeholder="AAAA MM DD" aria-describedby="fechainicioid">
                </div>
            </div>
            <div class="col-xs b-r col-md">
                <div class="form-group">
                    <label for="fecha_fin">Fecha fin:
                        <span><b>{{date_end | date}}</b></span>
                    </label>
                    <input type="text" name="fecha_fin" id="fecha_fin" [(ngModel)]="date_end_StiringTemporal" (ngModelChange)="date_end = calcularFecha(date_end_StiringTemporal)" class="form-control" placeholder="AAAA MM DD" aria-describedby="fechafinid">
                </div>
            </div> -->
            <!-- Clientes -->
            <div class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Cliente</label>
                    <div *ngIf="cliente" (click)="cliente = null; cambiarQueryParams([{cliente: null}])"
                        class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <div class="" *ngIf="!cliente">
                    <ng-select [typeahead]="inputClientes" [loading]="loadingClientes" [items]="clientes"
                        name="dsfdbrweqcda" [(ngModel)]="cliente"
                        (ngModelChange)="cambiarQueryParams([{cliente: cliente._id}]);filtrar()"
                        notFoundText="no se encontraron resultados" placeholder="buscar cliente" bindLabel="NOMBRES"
                        [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                <div *ngIf="item.RAZON">{{item.RAZON}} </div>


                            </div>

                        </ng-template>
                    </ng-select>
                </div>
                <div class="" *ngIf="cliente">
                    <div>
                        <div *ngIf="cliente.NOMBRES">{{cliente.NOMBRES}} {{cliente.APELLIDOS}}</div>
                        <div *ngIf="cliente.NOMBRES && cliente.RAZON"> - </div>
                        <div *ngIf="cliente.RAZON">{{cliente.RAZON}} </div>

                        <small>{{cliente.RUC | ruc}}</small>
                    </div>
                </div>
            </div>
            <!-- Fondo -->

            <!-- Servicio -->
            <div style="min-width: 550px;" class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Servicio</label>
                    <div *ngIf="servicio" (click)="servicio = null; filtrar()" class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <ng-select *ngIf="!servicio" [(ngModel)]="servicio"
                    (ngModelChange)="seleccionarProducto(servicio); filtrar()" [items]="servicios"
                    notFoundText="no se encontraron resultados" placeholder="buscar servicio" bindLabel="NOMBRE">
                    <ng-template ng-option-tmp let-item="item">
                        <div> {{item.NOMBRE}} {{item.COD_CORTO}} <br>
                        </div>
                    </ng-template>
                </ng-select>

                <div class="" *ngIf="servicio">
                    <div *ngIf="servicio.NOMBRE">{{servicio.NOMBRE}} </div>

                </div>
            </div>


            <div class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Vendedor</label>
                    <div *ngIf="vendedor" (click)="vendedor = null; filtrar()" class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <div class="" *ngIf="!vendedor">
                    <ng-select [typeahead]="inputVendedores" [loading]="loadingVendedores" [items]="vendedores"
                        name="ddfsfdsfdgfdbrweqcda" [(ngModel)]="vendedor" (ngModelChange)="filtrar()"
                        notFoundText="no se encontraron resultados" placeholder="buscar vendedor" bindLabel="NOMBRES"
                        [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>

                            </div>
                        </ng-template>
                    </ng-select>
                </div>


                <div class="" *ngIf="vendedor">
                    <div>
                        <div *ngIf="vendedor.NOMBRES">{{vendedor .NOMBRES}} {{vendedor .APELLIDOS}}</div>

                        <small>{{vendedor.RUC | ruc}}</small>
                    </div>
                </div>
            </div>
            <div class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Estado</label>

                </div>
                <div class="">
                    <ng-select [(ngModel)]="estadoSeleccionado" (ngModelChange)="filtrar()">
                        <ng-option *ngFor="let item of estados" [value]="item.estado">
                            <div class="text-{{item.color}}">
                                {{item.estado}}
                            </div>

                        </ng-option>
                    </ng-select>
                </div>


            </div>
        </div>
        <hr>
        <div class="row">
            <!-- Cobrador -->
            <div class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Cobrador</label>
                    <div *ngIf="cobrador && is_admin_role" (click)="cobrador = null; filtrar()"
                        class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <div class="" *ngIf="!cobrador">
                    <ng-select [typeahead]="inputCobradores" [loading]="loadingCobradores" [items]="cobradores"
                        name="dsfdsfdgfdbrweqcda" [(ngModel)]="cobrador" (ngModelChange)="filtrar()"
                        notFoundText="no se encontraron resultados" placeholder="buscar cobrador" bindLabel="NOMBRES"
                        [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                <div *ngIf="item.RAZON">{{item.RAZON}} </div>


                            </div>

                        </ng-template>
                    </ng-select>
                </div>


                <div class="" *ngIf="cobrador">
                    <div>
                        <div *ngIf="cobrador.NOMBRES">{{cobrador.NOMBRES}} {{cobrador.APELLIDOS}}</div>
                        <div *ngIf="cobrador.NOMBRES && cobrador.RAZON"> - </div>
                        <div *ngIf="cobrador.RAZON">{{cobrador.RAZON}} </div>

                        <small>{{cobrador.RUC | ruc}}</small>
                    </div>
                </div>
            </div>
            <div class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Fondo</label>
                    <div *ngIf="fondo" (click)="fondo = null; filtrar()" class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <div class="" *ngIf="!fondo">
                    <ng-select [items]="fondos" [(ngModel)]="fondo" (ngModelChange)="filtrar()"
                        (search)="searchFondos($event)" notFoundText="no se encontraron resultados"
                        placeholder="buscar fondo" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>



                                <small>{{item.RUC | ruc}}</small>
                            </div>

                        </ng-template>
                    </ng-select>
                </div>

                <div class="" *ngIf="fondo">
                    <div>
                        <div>{{fondo.NOMBRES}} {{fondo.APELLIDOS}}</div>


                        <small>{{fondo.RUC | ruc}}</small>
                    </div>
                </div>
            </div>
            <div *ngIf="false" class="col-xs mt-3  col-md">
                <!-- <label for="fecha_fin">Fecha de emision</label> -->
                <mat-form-field appearance="fill">
                    <mat-label>Fecha de Emision</mat-label>
                    <mat-date-range-input [formGroup]="rangeEmision" [rangePicker]="pickerEmision">
                        <input autocomplete="off" matStartDate formControlName="start" placeholder="fecha inicio">
                        <input matEndDate formControlName="end" (dateChange)="filtrar()" placeholder="fecha fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="pickerEmision"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerEmision></mat-date-range-picker>
                    <mat-error *ngIf="rangeEmision.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                    <mat-error *ngIf="rangeEmision.controls.end.hasError('matEndDateInvalid')">Invalid end date
                    </mat-error>
                </mat-form-field>

                <!-- <p>Selected range: {{range.value | json}}</p> -->
            </div>
            <div class="col-xs mt-3  col-md">
                <!-- <label for="fecha_fin">Fecha de emision</label> -->
                <mat-form-field appearance="fill">
                    <mat-label>Fecha de vencimiento</mat-label>
                    <mat-date-range-input [formGroup]="rangeVencimiento" [rangePicker]="pickerVencimiento">
                        <input matStartDate formControlName="start" placeholder="fecha inicio">
                        <input matEndDate formControlName="end" (dateInput)="filtrar('fecha_vencimiento')"
                            placeholder="fecha fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="pickerVencimiento"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerVencimiento></mat-date-range-picker>

                    <mat-error *ngIf="rangeVencimiento.controls.start.hasError('matStartDateInvalid')">Invalid start
                        date</mat-error>
                    <mat-error *ngIf="rangeVencimiento.controls.end.hasError('matEndDateInvalid')">Invalid end date
                    </mat-error>
                </mat-form-field>

                <!-- <p>Selected range: {{range.value | json}}</p> -->
            </div>
            <div class="col-xs mt-3  col-md">
                <mat-form-field appearance="fill">
                    <mat-label>Fecha de Pago</mat-label>
                    <mat-date-range-input [formGroup]="rangePagado" [rangePicker]="pickerPagado">
                        <input matStartDate formControlName="start" placeholder="fecha inicio">
                        <input matEndDate formControlName="end" (dateInput)="filtrar('fecha_pago')"
                            placeholder="fecha fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="pickerPagado"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerPagado></mat-date-range-picker>

                    <mat-error *ngIf="rangePagado.controls.start.hasError('matStartDateInvalid')">Fecha inicio inválida
                    </mat-error>
                    <mat-error *ngIf="rangePagado.controls.end.hasError('matEndDateInvalid')">Fecha fin inválida
                    </mat-error>
                </mat-form-field>

                <!-- <p>Selected range: {{range.value | json}}</p> -->
            </div>

            <div class="col-xs col-md mt-3">
                <h4>Estado</h4>
                <input [(ngModel)]="utilizado" (ngModelChange)="filtrar()" type="checkbox" name="check_utilizado"
                    id="check_utilizado" class="filled-in chk-col-light-blue" checked />
                <label class="mx-3" for="check_utilizado">Utilizado</label>

                <input [(ngModel)]="de_baja" (ngModelChange)="filtrar()" type="checkbox" name="check_de_baja"
                    id="check_de_baja" class="filled-in chk-col-light-blue" checked />
                <label class="mx-3" for="check_de_baja">de baja</label>
            </div>


            <!-- <div class="col-xs col-md mt-3">
                <div class="form-group">
                    <input type="number" class="form-control" #nro_factura name="" id="" aria-describedby="helpId" placeholder="buscar por nro de factura">
                    <small>ingresar sin nro de talonario</small>

                </div>
            </div> -->

            <!-- contrato -->
            <div *ngIf="cliente && !contrato" class="col-xs col-md">
                <div *ngIf="cliente && !contrato" class="d-flex mt-3">
                    <button type="button" (click)="showModal = true" class="btn mx-auto btn-info">Seleccionar
                        Contrato</button>
                </div>

                <div style="width: fit-content;" class="mt-3" *ngIf="contrato">
                    <div style="display: flex;" class="">
                        <span>Contrato</span>
                        <div *ngIf="contrato" style="height: fit-content;" class="pointer close-icon ml-auto">
                            <button (click)="contrato = null; filtrar()" type="button" class="btn btn-sm btn-light"><i
                                    class=" ti-close  "></i></button>
                        </div>
                    </div>

                    <div *ngIf="contrato">
                        <div style="display: flex;" class="">

                            <div class="card bg-light">
                                <div class="card-body">

                                    <span><b>Titular:</b> {{contrato.titular.NOMBRES}}
                                        {{contrato.titular.APELLIDOS}}</span>
                                    <br>
                                    <span><b>Servicio:</b> {{contrato.producto.NOMBRE}}</span>
                                    <br>
                                    <span><b>Número de contrato:</b> {{contrato.nro_contrato}}</span>
                                    <br>
                                    <span><b>Fecha de creación:</b> {{contrato.fecha_creacion_unix | date }}</span>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <!-- <hr> -->
        <div class="row">

            <div class="col-xs col-md-3 ">
                <div class="form-group">
                    <label for="nro-factura">
                        Nro Factura
                    </label>

                    <div class="input-group ">
                        <input [(ngModel)]="nro_factura" class="form-control" type="text" name="" id="">
                        <button class="btn btn-outline-secondary" (click)="filtrar()">Filtrar</button>
                    </div>



                    <small>OBS: sin numero de talonario</small>
                    <!-- <div><button class="btn btn-info mt-2" (click)="filtrar()">Buscar</button></div> -->
                </div>
            </div>
            <div class="col-xs col-md-3 ">
                <div class="form-group">
                    <label for="nro-contrato">
                        Nro Contrato
                    </label>

                    <div class="input-group mb-3">
                        <input type="text" class="form-control" [(ngModel)]="nro_contrato" placeholder="Buscar por Nro. de contrato">
                        <button class="btn btn-outline-secondary" (click)="filtrar()">Filtrar</button>
                    </div>

                    <!-- <input [(ngModel)]="nro_contrato" class="form-control" type="text" name="" id="">
                <div><button class="btn btn-info mt-2" (click)="filtrar()">Buscar</button></div> -->
                </div>
            </div>
            <div class="col-xs col-md-3">
                <h4>Cod Servicio</h4>
                <div class="">
                    <ng-select [(ngModel)]="codSeleccionado" (ngModelChange)="filtrar()">
                        <ng-option *ngFor="let item of cod_servicios" [value]="item">
                            <div>
                                {{item}}
                            </div>

                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-xs col-md-3">
                <h4>Recibido</h4>
                <div class="">
                    <ng-select [(ngModel)]="recibidoSeleccionado" (ngModelChange)="filtrar()">
                        <ng-option *ngFor="let item of recibidos" [value]="item">
                            <div>
                                {{item}}
                            </div>

                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>

        <!-- <button type="button" (click)="filtrar()" class=" float-right btn btn-primary">filtrar</button> -->
    </div>
</div>

<!-- <div  class="card">
    <div class="card-body">
        <div class="d-flex">
            <button type="button" [routerLink]="['/admin/crear_ingreso']" routerLinkActive="router-link-active" class="btn btn-info mx-auto">Crear Nuevo Ingreso</button>
        </div>
    </div>
</div> -->
<app-facturas *ngIf="!showModal" [count]="count" [total]="montoTotal" [options]="opciones" [sort]="sort" [total]="true"
    [facturas]="facturas"></app-facturas>

<div *ngIf="is_admin_role" class="card">
    <div class="card-body">
        <div class="d-flex">
            <div class="mx-auto">
                <span>Aplicar Interes por mora</span>
                <div class="form-group">
                    <input type="number" class="form-control" #interes name="" id="" aria-describedby="helpId"
                        placeholder="interes a agregar">
                    <small>EL interes aplicará a todo el filtro establecido</small>

                </div>

                <button type="button" (click)="aplicarInteres(interes.value)" class="btn btn-info mx-auto">Aplicar
                    Interes</button>
            </div>
            <div class="mx-auto">
                <span>Confirmar Recibo</span>
                <div><mat-form-field appearance="fill">
                    <mat-label>Fecha de Recibo</mat-label>
                    <input matInput name="asdrdsvet321" [(ngModel)]="fecha_recibo" [matDatepicker]="pickerRecibo">
                    <mat-datepicker-toggle matSuffix [for]="pickerRecibo">
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerRecibo></mat-datepicker>
                </mat-form-field></div>
                <div class="form-group">
                    <div style="width: 100%;">
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <button (click)="accionRecibo('cancelar')" class="btn btn-danger btn-rounded">Cancelar Recibo</button>

                            </div>
                            <div class="mx-auto">
                                <button (click)="accionRecibo('confirmar')" class="ml-2 btn btn-inverse btn-rounded">Confirmar Recibo</button>

                            </div>
                        </div>
                    </div>
                    <small>La acción aplicará a todo el filtro establecido</small>

                </div>

            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xs col-md">
        <div *ngIf="is_admin_role" class="card">
            <div class="card-body">
                <h3>Reporte Cuadro ingreso</h3>
                <div class="d-flex">
                    <div class="mx-auto">
                        <mat-form-field appearance="fill">
                            <mat-label>Fecha</mat-label>
                            <mat-date-range-input [formGroup]="rangeReporte" [rangePicker]="pickerReporte">
                                <input autocomplete="off" matStartDate formControlName="start"
                                    placeholder="fecha inicio">
                                <input matEndDate formControlName="end" placeholder="fecha fin">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="pickerReporte"></mat-datepicker-toggle>
                            <mat-date-range-picker #pickerReporte></mat-date-range-picker>
                            <mat-error *ngIf="rangeReporte.controls.start.hasError('matStartDateInvalid')">Invalid start
                                date</mat-error>
                            <mat-error *ngIf="rangeReporte.controls.end.hasError('matEndDateInvalid')">Invalid end date
                            </mat-error>
                        </mat-form-field>
                        <div style="display: block;">

                            <button type="button" (click)="generarReporte()"
                                class="btn btn-rounded btn-inverse mx-auto">Descargar Excel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="col-xs col-md">
        <div *ngIf="is_admin_role" class="card">
            <div class="card-body">
                <h3>Reporte Anual CMP</h3>
                <div class="d-flex">
                    <div class="mx-auto">
                        <mat-form-field appearance="fill">
                            <mat-label>Fecha</mat-label>
                            <mat-date-range-input [formGroup]="rangeReporteCMP" [rangePicker]="pickerReporteCMP">
                                <input autocomplete="off" matStartDate formControlName="start"
                                    placeholder="fecha inicio">
                                <input matEndDate formControlName="end" placeholder="fecha fin">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="pickerReporteCMP"></mat-datepicker-toggle>
                            <mat-date-range-picker #pickerReporteCMP></mat-date-range-picker>
                            <mat-error *ngIf="rangeReporteCMP.controls.start.hasError('matStartDateInvalid')">Invalid
                                start date</mat-error>
                            <mat-error *ngIf="rangeReporteCMP.controls.end.hasError('matEndDateInvalid')">Invalid end
                                date</mat-error>
                        </mat-form-field>
                        <div style="display: block;">

                            <button type="button" (click)="generarReporteAnualCMP()"
                                class="btn btn-rounded btn-inverse mx-auto">Descargar Excel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs col-md">
        <div *ngIf="is_admin_role" class="card">
            <div class="card-body">
                <h3>Reporte Movimientos</h3>
                <div class="d-flex">
                    <div class="mx-auto">
                        <mat-form-field appearance="fill">
                            <mat-label>Fecha</mat-label>
                            <mat-date-range-input [formGroup]="rangeReporteMovimientos"
                                [rangePicker]="pickerReporteCMP">
                                <input autocomplete="off" matStartDate formControlName="start"
                                    placeholder="fecha inicio">
                                <input matEndDate formControlName="end" placeholder="fecha fin">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="pickerReporteCMP"></mat-datepicker-toggle>
                            <mat-date-range-picker #pickerReporteCMP></mat-date-range-picker>
                            <mat-error *ngIf="rangeReporteMovimientos.controls.start.hasError('matStartDateInvalid')">
                                Invalid start date</mat-error>
                            <mat-error *ngIf="rangeReporteMovimientos.controls.end.hasError('matEndDateInvalid')">
                                Invalid end date</mat-error>
                        </mat-form-field>
                        <div></div>
                        <button style="width: 155.57px;" [disabled]="!rangeReporteMovimientos.value.start
                    || !rangeReporteMovimientos.value.end
                    || loadingGenerarReporteMov " (click)="generarReporteMovimientos()"
                            class="btn btn-rounded mr-auto btn-inverse">
                            <span *ngIf="!loadingGenerarReporteMov">Descargar Excel</span>
                            <div *ngIf="loadingGenerarReporteMov" style="width: 100%;">
                                <div style="display: flex;">

                                    <div class="mx-auto">
                                        <mat-spinner [color]="'accent'" [diameter]="20"></mat-spinner>
                                    </div>
                                </div>
                            </div>

                        </button>






                        <!-- <div style="display: block;">

                        <button type="button" [disabled]="!rangeReporteMovimientos.value.start || !rangeReporteMovimientos.value.end" (click)="generarReporteMovimientos()" class="btn btn-rounded btn-inverse mx-auto">Descargar Excel</button></div>
                   </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div *ngIf="contrato" class="card">
        <div class="card-body">
            <h4> <span class="lstick"></span>Pagar Cuotas</h4>

            <div class="my-3 d-flex">
                <div class="mx-auto">
                    <div class="form-group">
                        <input type="text" class="form-control" #montoAPagar name="" id="" aria-describedby="helpId"
                            placeholder="ingresar monto a pagar">
                    </div>
                </div>

            </div>
            <div class="d-flex">
                <div class="mx-auto">
                    <button (click)="getFacturasApagar(contrato._id, montoAPagar.value)"
                        class="btn btn-info">calcular</button>
                </div>

            </div>
            <div class="" *ngIf="facturasAPagar">

                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Cuotas</h3>

                        <div style="overflow: auto; max-height: 400px;">

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Vencimiento</th>
                                        <th>Monto</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr *ngFor="let cuota of facturasAPagar; let i = index">
                                        <td scope="row">{{i+1}}</td>
                                        <td>{{cuota.vencimiento | date}}</td>
                                        <td>{{cuota.haber| currency:'':'':'2.0' }} Gs</td>
                                    </tr>


                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>

                <div class="inline-flex">
                    <h4 class="mr-3"><b>Fondo:</b></h4>
                    <div class="" *ngIf="!fondo">
                        <ng-select [ngStyle]="{minWidth: '300px'}" [items]="fondos" [(ngModel)]="fondo"
                            (search)="searchBancos($event)" notFoundText="no se encontraron resultados"
                            placeholder="buscar origen fondo" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                            <ng-template ng-option-tmp let-item="item">
                                <div>
                                    <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                    <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                    <div *ngIf="item.RAZON">{{item.RAZON}} </div>


                                    <small>{{item.RUC | ruc}}</small>
                                </div>

                            </ng-template>
                        </ng-select>
                    </div>

                    <div class="" *ngIf="fondo">
                        <h4>
                            <div *ngIf="fondo.NOMBRES">{{fondo.NOMBRES}} {{fondo.APELLIDOS}}</div>
                            <div *ngIf="fondo.NOMBRES && fondo.RAZON"> - </div>
                            <div *ngIf="fondo.RAZON">{{fondo.RAZON}} </div>

                            <small>{{fondo.RUC | ruc}}</small>
                        </h4>
                    </div>

                </div>

                <div class="d-flex">
                    <div class="mx-auto">
                        <button (click)="confirmarPago(contrato._id, montoAPagar.value, fondo)" [disabled]="!fondo"
                            class="btn btn-success">Confirmar Pago</button>
                    </div>

                </div>
            </div>
        </div>
    </div>



    <app-modal-contratos (contratoSelected)="onContratoSelected($event)" [cliente]="cliente"
        (onClose)="showModal = false" *ngIf="showModal"></app-modal-contratos>
    <notifier-container></notifier-container>
