<div class="card">
    <div class="card-body">
        <form ngNativeValidate (ngSubmit)="crearProducto(nombre.value, cod.value, precio.value, codigo.value, cantidad.value)" #form="ngForm">

            <div class="form-group">
                <label for="">Nombre</label>
                <input type="text" name="nombre" id="" #nombre class="form-control" placeholder="" required aria-describedby="helpId">

                <label for="">Codigo corto</label>
                <input type="text" name="cod" id="" #cod class="form-control" placeholder="" aria-describedby="helpId">

                <label for="">Precio</label>
                <input type="number" name="precio" id="" #precio class="form-control" placeholder="" required aria-describedby="helpId">
                <label for="">Codigo</label>
                <input type="text" name="codigo" id="" #codigo class="form-control" placeholder="" required aria-describedby="helpId">
                <label for="">Cantidad</label>
                <input type="number" name="cantidad" id="" #cantidad class="form-control" placeholder="" aria-describedby="helpId">

            </div>

            <button type="reset" (click)="cancelar()" class="btn mr-3 btn-danger">Cancelar</button>
            <button type="submit" class="btn btn-info">Crear Producto</button>
        </form>
    </div>
</div>