<div class="my-container">
    <ng-template #customLoadingTemplate>

    </ng-template>
    <div class="card">
        <div class="card-body">
            <button class="btn btn-info" (click)="onClickExcel()" >Excel de usuarios</button>
            <form class="form-material mt-2 ">
                <div class="form-group">

                    <input #searchInput id="search" type="text" [(ngModel)]="searching" name="search" autocomplete="off" #val class="form-control form-control-line" placeholder="Busca usuario">
                </div>
                <small *ngIf="isSearching">Buscando... <i class="fa fa-spin fa-circle-o-notch"></i></small>
            </form>

            <div *ngIf="usuarios" class="table-responsive">

                <table class=" table  table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Apellidos</th>
                            <th>Nombres</th>

                            <th>Ruc</th>
                            <th>Accion</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr [routerLink]="['/admin/usuario', usuario._id]" class="pointer" *ngFor="let usuario of usuarios; let i = index">
                            <td scope="row">{{i+1}}</td>
                            <td>{{usuario.APELLIDOS}} 

                                <span *ngFor="let item of usuario.fantasia">{{item}}, </span>
                            </td>
                            <td>{{usuario.NOMBRES}} </td>

                            <td>{{usuario.RUC| ruc}}</td>
                            <td>
                                <button type="button" class="btn btn-sm btn-outline-dark">
                                    <i class="fa fa-pencil"></i>
            
                                </button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading>
</div>