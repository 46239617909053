<div (click)="print($event)" class="fondo-negro animated fadeIn">

    <div id="afuera" class="modal"  style="z-index: 200; display: block;" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Contratos</h5>
                    <button (click)="onClose.emit()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true"  >&times;</span>
                    </button>
                </div>
                <div [ngStyle]="style" class="modal-body " align="center">

                    <app-lista-contratos (selected)="onContratoSelected($event)" [cliente]="cliente" [selectable]='true' [showFilter]='showFilters'></app-lista-contratos>
                </div>

                <div class="modal-footer">
                    <button (click)="onClose.emit()" class="btn btn-secondary"> Cancelar</button>
                    <button class='btn btn-primary'>Seleccionar</button>

                </div>
            </div>
        </div>
    </div>
</div>