<div (click)="print($event)" class="fondo-negro animated fadeIn">

    <div id="afuera" class="modal" style="display: block;" role="dialog">
        <div class="modal-dialog modal-dialog-scrollable modal-xl " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Contratos</h5>
                    <button (click)="onClose.emit()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true"  >&times;</span>
                    </button>
                </div>
                <div *ngIf="style" [ngStyle]="style" class=" modal-body ">
                    <app-contrato-pdf [printAltoke]="false" [contrato]='contrato'></app-contrato-pdf>
                </div>

                <div class="modal-footer">
                    <button (click)="onClose.emit()" class="btn btn-danger">cerrar</button>
                    <button (click)="printContrato()" class='btn btn-info'>Imprimir</button>

                </div>
            </div>
        </div>
    </div>
</div>