<p>modal-billetes works!</p>
<div (click)="print($event)" class="fondo-negro animated fadeIn">

    <div id="afuera" class="modal" style="display: block;" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Contratos</h5>
                    <button (click)="onClose.emit( )" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body " align="center">
                    <div style="width: 100%; overflow-y: auto; max-height: 30rem;">
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>100.000</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" #cienmilInput [(ngModel)]="cienmil" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="cienmil" >{{(cienmil *100000) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>50.000</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="cincuentamil" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="cincuentamil" >{{(cincuentamil *50000) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>20.000</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="veintemil" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="veintemil" >{{(veintemil *20000) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>10.000</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="diezmil" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="diezmil" >{{(diezmil *10000) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>5.000</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="cincomil" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="cincomil" >{{(cincomil *5000) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>2.000</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="dosmil" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="dosmil" >{{(dosmil *2000) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>1.000</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="mil" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="mil" >{{(mil *1000) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>500</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="quinientos" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="quinientos" >{{(quinientos *500) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>100</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="cien" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="cien" >{{(cien *100) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>50</h2>

                                    </div>
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="cincuenta" type="number">

                                    </div>
                                    <div class="col-2">
                                        <span *ngIf="cincuenta" >{{(cincuenta *50) | currency: '':'':'2.0'}}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <div class="row">
                                    <div class="col-3 mx-3">
                                        <h2>Cheque</h2>

                                    </div>
                                    <div class="col">
                                        <input [(ngModel)]="cheque"  class="form-control" type="number">

                                    </div>
                                    <div class="col">
                                         <input [(ngModel)]="omitirCheque" type="checkbox" name="check_de_baja"
                                        id="check_de_baja" class="filled-in chk-col-light-blue" title="click para afectar al total" checked />
                                    <label class="mx-3" title="click para afectar al total" for="check_de_baja">Activar</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div style="width: 100%;">
                        <div class="mx-auto">
                            <div style="display: flex;">
                                <div class="mx-auto">
                                    <div class="row">
                                        <div class="col">
                                            <h2>Monto Total: {{montoTotal | currency: '':'':'2.0'}}</h2>
                                        </div>
                                        <div class="col">
                                            <h2>suma: {{sumaTotal() | currency: '':'':'2.0'}}</h2>
                                            <h2>restante: {{montoTotal- sumaTotal() | currency: '':'':'2.0'}}</h2>
                                        </div>
                                    </div>
                                    <div *ngIf="montoTotal === sumaTotal()" class="alert alert-success" role="alert">
                                        Los montos coinciden ✔
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button (click)="onClose.emit()" class="btn btn-secondary"> Cancelar</button>
                    <button (click)="cerrarCaja.emit()" [disabled]="montoTotal != sumaTotal()" class='btn btn-rounded btn-info'>Cerrar Caja</button>

                </div>
            </div>
        </div>
    </div>
</div>