<div class="card">
    <div class="card-body">
        <div class="row">

            <div class="col-xs col-md">
                <div class="card bg-light">
                    <div class="card-body">
                        <div class="inline-flex">
                            <label class="">Cliente</label>
                            <div *ngIf="cliente" class="pointer close-icon ml-auto">
                                <button (click)="cliente = null" type="button" class="btn btn-sm btn-light"><i
                                        class=" ti-close  "></i></button>
                            </div>
                        </div>
                        <div class="" *ngIf="!cliente">
                            <ng-select [items]="clientes" [(ngModel)]="cliente" (search)="searchClientes($event)"
                                notFoundText="no se encontraron resultados" placeholder="buscar cliente"
                                bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div>
                                        <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                        <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                        <div *ngIf="item.RAZON">{{item.RAZON}} </div>


                                        <small>{{item.RUC | ruc}}</small>
                                    </div>

                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="" *ngIf="cliente">
                            <div>
                                <div *ngIf="cliente.NOMBRES">{{cliente.NOMBRES}} {{cliente.APELLIDOS}}</div>
                                <div *ngIf="cliente.NOMBRES && cliente.RAZON"> - </div>
                                <div *ngIf="cliente.RAZON">{{cliente.RAZON}} </div>

                                <small>{{cliente.RUC | ruc}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs col-md">
                <div class="card bg-light">
                    <div class="card-body">
                        <div class="inline-flex">
                            <label class="">Cobrador</label>
                            <div *ngIf="cobrador" class="pointer close-icon ml-auto">
                                <button (click)="cobrador = null" type="button" class="btn btn-sm btn-light"><i
                                        class=" ti-close  "></i></button>
                            </div>
                        </div>
                        <div class="" *ngIf="!cobrador">
                            <ng-select [items]="cobradores" [(ngModel)]="cobrador" (search)="searchCobradores($event)"
                                notFoundText="no se encontraron resultados" placeholder="buscar cobrador"
                                bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div>
                                        <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                        <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                        <div *ngIf="item.RAZON">{{item.RAZON}} </div>


                                        <small>{{item.RUC | ruc}}</small>
                                    </div>

                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="" *ngIf="cobrador">
                            <div>
                                <div *ngIf="cobrador.NOMBRES">{{cobrador.NOMBRES}} {{cobrador.APELLIDOS}}</div>
                                <div *ngIf="cobrador.NOMBRES && cobrador.RAZON"> - </div>
                                <div *ngIf="cobrador.RAZON">{{cobrador.RAZON}} </div>

                                <small>{{cobrador.RUC | ruc}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs col-md">
                <div class="card bg-light">
                    <div class="card-body">
                        <div class="inline-flex">
                            <label class="">Vendedor</label>
                            <div *ngIf="vendedor" class="pointer close-icon ml-auto">
                                <button (click)="vendedor = null" type="button" class="btn btn-sm btn-light"><i
                                        class=" ti-close  "></i></button>
                            </div>
                        </div>
                        <div class="" *ngIf="!vendedor">
                            <ng-select [items]="vendedores" [(ngModel)]="vendedor" (search)="searchVendedores($event)"
                                notFoundText="no se encontraron resultados" placeholder="buscar vendedor"
                                bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div>
                                        <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                        <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                        <div *ngIf="item.RAZON">{{item.RAZON}} </div>


                                        <small>{{item.RUC | ruc}}</small>
                                    </div>

                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="" *ngIf="vendedor">
                            <div>
                                <div *ngIf="vendedor.NOMBRES">{{vendedor.NOMBRES}} {{vendedor.APELLIDOS}}</div>
                                <div *ngIf="vendedor.NOMBRES && vendedor.RAZON"> - </div>
                                <div *ngIf="vendedor.RAZON">{{vendedor.RAZON}} </div>

                                <small>{{vendedor.RUC | ruc}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="max-width: 500px;" class="col-xs col-md">
                <div class="card">
                    <div class="card-body bg-light">
                        <div class="d-flex">
                            <label for="">Servicio o Producto</label>

                            <div *ngIf="servicio" style="height: fit-content;" class="pointer close-icon ml-auto">
                                <button (click)="servicio = null" type="button" class="btn btn-sm btn-light"><i
                                        class=" ti-close  "></i></button>
                            </div>
                        </div>

                        <div class="" *ngIf="!servicio">
                            <ng-select *ngIf="!servicio" [(ngModel)]="servicio" [items]="servicios"
                                notFoundText="no se encontraron resultados" placeholder="buscar servicio"
                                bindLabel="NOMBRE">
                                <ng-template ng-option-tmp let-item="item">
                                    <div> {{item.NOMBRE}} {{item.COD_CORTO}} <br>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>

                        <div class="" *ngIf="servicio">
                            <div>
                                <div> {{servicio.NOMBRE}} </div>
                                <small>{{servicio.COD_CORTO}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">


        </div>

        <div *ngIf="servicio" class="form-group">
            <label for="">Monto</label>
            <input type="number" class="form-control" [(ngModel)]="servicio.PRECIO_MAYORISTA" name="" id=""
                aria-describedby="helpId" placeholder="">
            <br>
            <label for="">Nota</label>
            <input type="text" class="form-control" [(ngModel)]="nota" name="nota" id=""
                aria-describedby="helpId" placeholder="">
            <br>
            <label for="fecha_inicio">Vencimiento :
                <span><b>{{vencimiento | date}}</b></span>
            </label>
            <!-- <input type="text" name="fecha_inicio" [(ngModel)]="vencimientoString"
                (ngModelChange)="vencimiento = calcularFecha(vencimientoString)" id="fecha_inicio" class="form-control"
                placeholder="AAAA MM DD" aria-describedby="fechainicioid"> -->
                <mat-form-field appearance="fill">
                    <mat-label>Fecha</mat-label>
                    <input matInput name="asdas" [(ngModel)]="fecha_vencimiento" [matDatepicker]="picker_fecha_vencimiento"  >
                    <mat-datepicker-toggle matSuffix [for]="picker_fecha_vencimiento"></mat-datepicker-toggle>
                    <mat-datepicker #picker_fecha_vencimiento></mat-datepicker>
                </mat-form-field>

        </div>

        <!-- <button *ngIf="!contrato" type="button" class="btn btn-inverse btn-rounded" (click)="showModal = true;">
            Relacionar Contrato
        </button> -->
        <div style="width: fit-content;">
            <div *ngIf="contrato"style="display: flex;" class="">
                <span>Contrato</span>
                <div  style="height: fit-content;" class="pointer close-icon ml-auto">
                    <button (click)="contrato = null" type="button" class="btn btn-sm btn-light"><i
                            class=" ti-close  "></i></button>
                </div>
            </div>
    
            <div *ngIf="contrato" class="">
                <div style="display: flex;" class="">
    
                    <div class="card bg-light">
                        <div class="card-body">
    
                            <span><b>Titular:</b> {{contrato.titular.NOMBRES}}
                                {{contrato.titular.APELLIDOS}}</span>
                            <br>
                            <span><b>Servicio:</b> {{contrato.producto.NOMBRE}}</span>
                            <br>
                            <span><b>Número de contrato:</b> {{contrato.nro_contrato}}</span>
                            <br>
                            <span><b>Fecha de creación:</b> {{contrato.fecha_creacion_unix | date
                                }}</span>
                        </div>
                    </div>
                </div>
            </div></div>
            
        <div class="d-flex">
            <button *ngIf="!contrato" type="button" class="btn btn-inverse mx-auto btn-rounded" (click)="showModal = true;">
                Relacionar Contrato
            </button>
        </div>
        <div class="d-flex">
            <button type="button" (click)="crearFactura()" class="btn mt-1 mx-auto btn-info">Crear Ingreso</button>
        </div>
    </div>
</div>

<app-modal-contratos (contratoSelected)="contrato = $event" [showFilters]="true" [cliente]="cliente"
    (onClose)="showModal = false" *ngIf="showModal"></app-modal-contratos>