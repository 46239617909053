<div class="row">
    <div class="col-6">
        <app-dona title="Ventas"></app-dona>
    </div>
    <div class="col-6">
        <app-dona title="Compras" [labels]="labels1" [data]='data1'></app-dona>
    </div>
    <div class="col-6">
        <app-dona title="Mas Vendidos" [labels]="labels2" [data]='data2'></app-dona>
    </div>
    <div class="col-6">
        <app-dona title="Menos Vendidos" [labels]="labels3" [data]='data3'></app-dona>
    </div>
</div>