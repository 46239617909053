<div class="my-container">

    <div class="card">
        <ul *ngIf="is_admin_role" class="nav nav-tabs profile-tab" role="tablist">
            <li class="nav-item"> <a class="nav-link " [ngClass]="{'active': secction === 'home'}" data-toggle="tab"
                    (click)="cancelarCambiosEditar('home'); ocultarOnCategory = false; cuentaGasto = null; goHome()" href="#home"
                    role="tab">Nuevo Gasto</a>
            </li>
            <li *ngIf="is_admin_role" class="nav-item"> <a class="nav-link"
                    [ngClass]="{'active': secction === 'newCuentaGasto'}" data-toggle="tab"
                    (click)="cancelarCambiosEditar('newCuentaGasto'); ocultarOnCategory = true; cuentaGasto = null"
                    href="#newCuentaGasto" role="tab">Crear
                    Cuenta
                    Gasto</a>
            </li>
            <li *ngIf="is_admin_role" class="nav-item">
                <a class="nav-link" data-toggle="tab"
                    (click)="cancelarCambiosEditar('newCategoria');  ocultarOnCategory = true; cuentaGasto = null"
                    [ngClass]="{'active': secction === 'newCategoria'}" href="#newCategoria" role="tab">Crear Categoria
                </a>
            </li>
            <li *ngIf="is_admin_role" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#newCuentaAbaco"
                    [ngClass]="{'active': secction === 'newCuentaAbaco'}"
                    (click)="cancelarCambiosEditar('newCuentaAbaco'); cuentaGasto = null  " role="tab">Cuentas
                    Abaco</a>
            </li>
            <li *ngIf="is_admin_role" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#updateCuentas"
                    [ngClass]="{'active': secction === 'updateCuentas'}"
                    (click)="cancelarCambiosEditar('updateCuentas'); ocultarOnCategory = true; cuentaGasto = null  "
                    role="tab">Modificar Cuentas</a>
            </li>
            <li class="nav-item"> <a class="nav-link" (click)="cancelarCambiosEditar('listaGastos');" data-toggle="tab"
                    href="#listaGastos" [ngClass]="{'active': secction === 'listaGastos'}" role="tab">Lista de
                    Gastos</a> </li>

        </ul>
        <div class="card-body ">
            <div class="tab-content">
                <div class="tab-pane " id="home" [ngClass]="{'active': secction === 'home'}" role="tabpanel">
                    <div class="">

                        <!-- <h3>Nuevo Movimiento</h3> -->
                        <div class="row">
                            <div class="col-xs col-md-3 mt-2">
                                <div class="inline-flex">

                                    <label class="">Proveedor</label>
                                    <div *ngIf="proveedor" class="pointer close-icon ml-auto">
                                        <button (click)="proveedor = null; removeQueryParam('proveedor')" type="button"
                                            class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                                    </div>
                                </div>
                                <div class="" *ngIf="!proveedor">
                                    <ng-select #search [typeahead]="inputProveedores" [loading]="loadingProveedores"
                                        [items]="proveedores" name="dsfdbrdafwseqcda" [(ngModel)]="proveedor"
                                        notFoundText="no se encontraron resultados" placeholder="Buscar Proveedor"
                                        bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div>
                                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>



                                                <small>{{item.RUC | ruc}}</small>
                                            </div>

                                        </ng-template>
                                    </ng-select>
                                </div>


                                <div class="" *ngIf="proveedor">
                                    <div>
                                        <div *ngIf="proveedor.APELLIDOS">{{proveedor .NOMBRES}} {{proveedor .APELLIDOS}}
                                        </div>

                                        <small>{{proveedor.RUC | ruc}}</small>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xs col-md-3 mt-2">
                                <div class="inline-flex">
                                    <label class="">Origen Fondo</label>
                                    <div *ngIf="fondo" class="pointer close-icon ml-auto">
                                        <button (click)="fondo = null" type="button" class="btn btn-sm btn-light"><i
                                                class=" ti-close  "></i></button>
                                    </div>
                                </div>
                                <div class="" *ngIf="!fondo">
                                    <ng-select [items]="fondos" [(ngModel)]="fondo" (search)="searchBancos($event)"
                                        notFoundText="no se encontraron resultados" placeholder="buscar origen fondo"
                                        bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div>
                                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>

                                                <small>{{item.RUC | ruc}}</small>
                                            </div>

                                        </ng-template>
                                    </ng-select>
                                </div>

                                <div class="" *ngIf="fondo">
                                    <div>
                                        <div *ngIf="fondo.APELLIDOS">{{fondo.NOMBRES}} {{fondo.APELLIDOS}}</div>

                                        <small>{{fondo.RUC | ruc}}</small>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xs col-md-3 mt-2">
                                <div class="inline-flex">
                                    <label class="">Usuario</label>
                                    <div *ngIf="cliente" class="pointer close-icon ml-auto">
                                        <button (click)="cliente = null" type="button" class="btn btn-sm btn-light"><i
                                                class=" ti-close  "></i></button>
                                    </div>
                                </div>
                                <div class="" *ngIf="!cliente">
                                    <ng-select [items]="clientes" [(ngModel)]="cliente"
                                        (search)="searchClientes($event)" notFoundText="no se encontraron resultados"
                                        placeholder="buscar usuario" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div>
                                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>



                                                <small>{{item.RUC | ruc}}</small>
                                            </div>

                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="" *ngIf="cliente">
                                    <div>
                                        <div *ngIf="cliente.NOMBRES">{{cliente.NOMBRES}} {{cliente.APELLIDOS}}</div>
                                        <div *ngIf="cliente.NOMBRES && cliente.RAZON"> - </div>
                                        <div *ngIf="cliente.RAZON">{{cliente.RAZON}} </div>

                                        <small>{{cliente.RUC | ruc}}</small>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xs col-md-3 mt-2">
                                <div class=" form-group">
                                    <label for="">Nro Timbrado</label>
                                    <input type="text" [(ngModel)]="nro" class="form-control" name="" id="nro"
                                        aria-describedby="helpId" placeholder="">
                                </div>

                            </div>
                            <div class="col-xs col-md-3 mt-2">

                                <div class=" form-group">
                                    <label for="">Nro. Factura Proveedor</label>
                                    <input type="text" [(ngModel)]="nroFacturaProveedor" class="form-control" name=""
                                        id="nrofacturaproveedor" aria-describedby="helpId" placeholder="">
                                </div>

                            </div>
                            <div class="col-xs col-md-3 mt-2">
                                <div class=" form-group">
                                    <label for="">Comentario</label>
                                    <input type="text" [(ngModel)]="comentario" class="form-control" name="" id="monto"
                                        aria-describedby="helpId" placeholder="">
                                </div>
                            </div>
                            <div class="col-xs col-md-3 mt-2">
                                <div class=" form-group">
                                    <label for="">Monto</label>
                                    <input type="number" [(ngModel)]="monto" class="form-control" name="" id="monto"
                                        aria-describedby="helpId" placeholder="">
                                </div>
                            </div>
                            <div class="col-xs col-md-3 mt-2">
                                <div class=" form-group">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Vencimiento Timbrado</mat-label>
                                        <input matInput name="asdsadrdsvet321" [(ngModel)]="fechaVencimientoTimbrado"
                                            [matDatepicker]="pickerVencimiento">
                                        <mat-datepicker-toggle matSuffix [for]="pickerVencimiento">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerVencimiento></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>





                        <div style="width: 100%; display: flex;" class="">
                            <div style="display: flex;" class="">
                                <span>Contrato</span>
                                <div *ngIf="contrato" style="height: fit-content;" class="pointer close-icon ml-auto">
                                    <button (click)="removeContrato()" type="button" class="btn btn-sm btn-light"><i
                                            class=" ti-close  "></i></button>
                                </div>
                            </div>




                            <div *ngIf="!contrato" class="">

                                <div style="display: flex;" class="">
                                    <div class="card">
                                        <div class="card-body">
                                            <button type="button" (click)="showModal = true"
                                                class="btn btn-inverse">Buscar
                                                Contrato</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="contrato" class="">
                                <div style="display: flex;" class="">

                                    <div class="card bg-light">
                                        <div class="card-body">

                                            <span><b>Titular:</b> {{contrato.titular.NOMBRES}}
                                                {{contrato.titular.APELLIDOS}}</span>
                                            <br>
                                            <span><b>Servicio:</b> {{contrato.producto.NOMBRE}}</span>
                                            <br>
                                            <span><b>Número de contrato:</b> {{contrato.nro_contrato}}</span>
                                            <br>
                                            <span><b>Fecha de creación:</b> {{contrato.fecha_creacion_unix | date
                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mx-auto">
                                <h5>Cuenta Gasto</h5>

                                <div *ngIf="cuentaGasto" style="display: block;">
                                    <span><b class="lead">Tipo: </b> {{cuentaGasto.nombre_padre}}</span>
                                    <br>
                                    <span><b class="lead">Descripcion: </b> {{cuentaGasto.descripcion}}</span>
                                </div>

                                <button *ngIf="!cuentaGasto" type="button" (click)="showModalCuentas = true"
                                    class="btn mt-2  btn-info">Seleccionar Cuenta</button>
                                <button *ngIf="cuentaGasto" type="button" (click)="showModalCuentas = true"
                                    class="btn mt-2 btn-inverse">Cambiar Cuenta</button>
                            </div>
                            <div class="mx-auto" style="min-width: 180px;">
                                <div class=" form-group">
                                    Tipo de IVA
                                    <ng-select [(ngModel)]="tipoIva">
                                        <ng-option *ngFor="let item of ['IVA 10%','IVA 5%', 'EXENTAS']" [value]="item">
                                            <div>
                                                {{item}}
                                            </div>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="mx-auto">
                                <h5>Cuenta Abaco</h5>

                                <div *ngIf="cuentaAbaco" class="form-input">
                                    <span for=""><b class="lead">Codigo:</b> {{cuentaAbaco.codigo}}</span><br>
                                    <span for=""><b class="lead">Descripcion:</b> {{cuentaAbaco.descripcion}}</span><br>
                                    <span for=""><b class="lead">Tipo:</b> {{cuentaAbaco.tipo}}</span>
                                </div>
                                <div *ngIf="!cuentaAbaco" class="form-input">
                                    <small>Seleccione una cuenta gasto</small>
                                </div>
                                <!-- <div *ngIf="cuentasAbaco && !cuentaAbaco">
                                    <div style="min-width: 300px;" class="form-group">
                                        <label for="">Selecciona una cuenta</label>

                                        <ng-select [(ngModel)]="tipoCuentaAbaco">
                                            <ng-option *ngFor="let item of cuentasAbaco" [value]="item">
                                                <div>
                                                    {{item.codigo}} - {{item.descripcion}}
                                                </div>
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div> -->
                            </div>
                            <div class="mx-auto">
                                <mat-form-field appearance="fill">
                                    <mat-label>Fecha de Creacion</mat-label>
                                    <input matInput name="asdsdrdsvet321" [(ngModel)]="fechaCreacion"
                                        [matDatepicker]="pickerPago">
                                    <mat-datepicker-toggle matSuffix [for]="pickerPago">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerPago></mat-datepicker>
                                </mat-form-field>
                            </div>

                        </div>

                        <div class="float-right">
                            <button type="button" (click)="saveshowCrearMovimiento(false)"
                                class="btn btn-inverse btn-rounded">cancelar</button>
                            <button type="submit" (click)="crearMovimiento()" [disabled]="!allowCreateMovimiento()"
                                class="btn btn-rounded btn-info ml-2">Crear</button>
                        </div>

                    </div>
                </div>
                <div class="tab-pane  " id="newCuentaGasto" [ngClass]="{'active': secction === 'newCuentaGasto'}"
                    role="tabpanel">
                    <div class="form-group">
                        <label for="">id</label>
                        <input type="text" #cuentaid class="form-control" aria-describedby="helpId" placeholder="">
                        <label for="">Nombre</label>
                        <input type="text" #nombre class="form-control" aria-describedby="helpId" placeholder="">
                    </div>
                    <div style="width: 100%;">
                        <div style="display: flex;">
                            <div class="mx-auto">
                                <h5>Cuenta Abaco</h5>
                                <div *ngIf="cuentasAbaco">
                                    <div style="min-width: 300px;" class="form-group">
                                        <label for="">Selecciona una cuenta</label>
                                        <ng-select [(ngModel)]="tipoCuentaAbaco">
                                            <ng-option *ngFor="let item of cuentasAbaco" [value]="item">
                                                <div>
                                                    {{item.codigo}} - {{item.descripcion}}
                                                </div>
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="mx-auto">
                                <h5>Seleccionar Categoría</h5>
                                <div *ngIf="categorySelected" style="display: block;">
                                    <span><b class="lead">Tipo: </b> {{categorySelected.nombre_padre}}</span>
                                    <br>
                                    <span><b class="lead">Descripcion: </b> {{categorySelected.descripcion}}</span>
                                </div>
                                <button *ngIf="!categorySelected" type="button" (click)="showModalCuentas = true"
                                    (click)="ocultarOnCategory = true" class="btn mt-2  btn-info">Seleccionar
                                    Categoría</button>
                                <button *ngIf="categorySelected" type="button" (click)="showModalCuentas = true"
                                    class="btn mt-2 btn-inverse">Cambiar Cuenta</button>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div style="display: flex;">
                            <div class="mx-auto mt-2">
                                <button type="button" [disabled]="!nombre.value || !categorySelected"
                                    (click)="crearCuentaGasto(cuentaid.value, nombre.value, categorySelected,tipoCuentaAbaco )"
                                    class="btn btn-rounded btn-inverse   ">Crear Cuenta Gasto</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="tab-pane " id="newCuentaAbaco" [ngClass]="{'active': secction === 'newCuentaAbaco'}"
                    role="tabpanel">
                    <div class="form-group">
                        <label for="">Código</label>
                        <input type="text" class="form-control" #codigo aria-describedby="helpId" placeholder="">
                        <label for="">Descripción</label>
                        <input type="text" class="form-control" #descripcion aria-describedby="helpId" placeholder="">
                        <label for="">Tipo</label>
                        <ng-select [(ngModel)]="tipoCuentaAbaco">
                            <ng-option *ngFor="let item of ['MAYORIZADOR','IMPUTABLE']" [value]="item">
                                <div>
                                    {{item}}
                                </div>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="mx-auto">
                        <h5>Seleccionar Categoría</h5>

                        <div *ngIf="cuentaGasto" style="display: block;">
                            <span><b class="lead">Tipo: </b> {{cuentaGasto.nombre_padre}}</span>
                            <br>
                            <span><b class="lead">Descripcion: </b> {{cuentaGasto.descripcion}}</span>
                        </div>

                        <button *ngIf="!cuentaGasto" type="button"
                            (click)="ocultarOnCategory = true; showModalCuentas = true"
                            class="btn mt-2  btn-info">Relacionar Cuenta Gasto</button>
                        <button *ngIf="cuentaGasto" type="button"
                            (click)="ocultarOnCategory = true; showModalCuentas = true"
                            class="btn mt-2 btn-inverse">Cambiar Cuenta</button>
                    </div>
                    <div style="width: 100%;">
                        <div style="display: flex;">
                            <div class="mx-auto mt-2"><button type="button"
                                    (click)="crearCuentaAbaco(codigo.value, descripcion.value, tipoCuentaAbaco, cuentaGasto)"
                                    class="btn btn-rounded btn-outline-inverse">Crear Cuenta Abaco</button></div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane " id="newCategoria" [ngClass]="{'active': secction === 'newCategoria'}"
                    role="tabpanel">
                    <div class="form-group">
                        <label for="">Código</label>
                        <input type="text" class="form-control" #codigocat aria-describedby="helpId" placeholder="">
                        <label for="">Descripción</label>
                        <input type="text" class="form-control" #descripcioncat aria-describedby="helpId"
                            placeholder="">

                    </div>
                    <!-- <div class="mx-auto">
                        <h5>Cuenta Gasto</h5>

                        <div *ngIf="cuentaGasto" style="display: block;">
                            <span><b class="lead">Tipo: </b> {{cuentaGasto.nombre_padre}}</span>
                            <br>
                            <span><b class="lead">Descripcion: </b> {{cuentaGasto.descripcion}}</span>
                        </div>

                        <button *ngIf="!cuentaGasto" type="button"
                            (click)="ocultarOnCategory = true; showModalCuentas = true"
                            class="btn mt-2   btn-rounded btn-info">Relacionar Cuenta Gasto</button>
                        <button *ngIf="cuentaGasto" type="button"
                            (click)="ocultarOnCategory = true; showModalCuentas = true"
                            class="btn mt-2 btn-rounded  btn-inverse">Cambiar Cuenta</button>
                    </div> -->
                    <div style="width: 100%;">
                        <div style="display: flex;">
                            <div class="mx-auto mt-2"><button type="button"
                                    (click)="crearCategoria(codigocat.value, descripcioncat.value)"
                                    class="btn btn-rounded btn-outline-inverse">Crear Categoria</button></div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane " id="updateCuentas" [ngClass]="{'active': secction === 'updateCuentas'}"
                    role="tabpanel">
                    <div style="min-height: 300px;">
                        <h2 *ngIf="!tipoEdicion">Seleccion cuenta a editar</h2>

                        <div class="row my-3" *ngIf="!tipoEdicion">
                            <div class="col-xs col-md">
                                <button type="button" class="btn btn-info">Cuenta Categoria</button>
                            </div>
                            <div class="col-xs col-md">
                                <button (click)="tipoEdicion = 'cuenta_gasto'" type="button" class="btn btn-info">Cuenta
                                    Gasto</button>
                            </div>
                            <div class="col-xs col-md">
                                <button type="button" class="btn btn-info">Cuenta Abaco</button>

                            </div>

                        </div>

                        <div class="row" *ngIf="tipoEdicion === 'cuenta_categoria'">
                            <div class="col-xs col-md">
                                <h5>Buscar Categoria</h5>
                                <div class="form-input">
                                    <div *ngIf="!categoria">
                                        <ng-select [typeahead]="inputcategorias" [loading]="loadingcategorias"
                                            [items]="categorias" name="dsfdsbraweqcda" [(ngModel)]="categoria"
                                            (ngModelChange)="seleccionarCategoriaToEdit(categoria)"
                                            notFoundText="no se encontraron resultados" placeholder="buscar categoria"
                                            bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                            <ng-template ng-option-tmp let-item="item">
                                                <div> {{item.codigo}} - {{item.descripcion}}

                                                </div>

                                            </ng-template>
                                        </ng-select>

                                    </div>

                                    <div *ngIf="categoria" class="form-group">
                                        <label for="">Código</label>
                                        <input type="text" class="form-control" [(ngModel)]="categoria.codigo"
                                            aria-describedby="helpId" placeholder="">
                                        <label for="">Descripción</label>
                                        <input type="text" class="form-control" [(ngModel)]="categoria.descripcion"
                                            aria-describedby="helpId" placeholder="">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="tipoEdicion === 'cuenta_gasto'">

                            <div *ngIf="!cuentaGastoToEdit" style="display: block;">
                                <div style="width: 100%; " class="py-auto">
                                    <div style="display: flex;">
                                        <div class="mx-auto">
                                            <button
                                                (click)=" editandoCuentaGasto= true; ocultarOnCategory = true; showModalCuentas = true;"
                                                type="button" class="btn btn-info">Seleccionar Cuenta Gasto</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="cuentaGastoToEdit" class="row">
                                <div class="col-xs col-md">

                                    <div *ngIf="cuentaGastoToEdit">
                                        <div class="form-group">
                                            <label for="">id</label>
                                            <input type="text" [(ngModel)]="cuentaGastoToEdit.cuenta"
                                                class="form-control" aria-describedby="helpId" placeholder="">
                                            <label for="">Nombre</label>
                                            <input type="text" [(ngModel)]="cuentaGastoToEdit.descripcion"
                                                class="form-control" aria-describedby="helpId" placeholder="">
                                        </div>
                                        <div class="mx-auto">
                                            <h5>Seleccionar Cuenta Padre</h5>

                                            <div *ngIf="cuentaPadreToEdit" style="display: block;">
                                                <span><b class="lead">Id: </b>
                                                    {{cuentaPadreToEdit.cuenta}}</span>
                                                <br>
                                                <span><b class="lead">Descripcion: </b>
                                                    {{cuentaPadreToEdit.descripcion}}</span>
                                            </div>

                                            <button *ngIf="!cuentaPadreToEdit" type="button"
                                                (click)="showModalCuentas = true" (click)="ocultarOnCategory = true"
                                                class="btn mt-2  btn-info">Seleccionar
                                            </button>
                                            <button *ngIf="cuentaPadreToEdit" type="button"
                                                (click)="editandoCuentaPadre = true; showModalCuentas = true"
                                                class="btn mt-2 btn-inverse">Cambiar
                                                Cuenta Padre</button>
                                        </div>
                                        <div style="width: 100%;" class="mt-auto">
                                            <div style="display: flex;">
                                                <div class="mx-auto mt-2">
                                                    <button type="button"
                                                        (click)="eliminarCuentaGasto(cuentaGastoToEdit._id)"
                                                        class="btn mx-1 btn-rounded btn-outline-danger">Elimiar Cuenta
                                                        Gasto</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xs col-md">

                                    <div class="form-input">
                                        <div *ngIf="!cuentaAbaco">
                                            <ng-select [items]="cuentasAbaco" name="dsfdbsasdrwseqcda"
                                                [(ngModel)]="cuentaAbaco"
                                                (ngModelChange)="seleccionarCuentaAbacoToEdit(cuentaAbaco)"
                                                notFoundText="no se encontraron resultados"
                                                placeholder="buscar Cuenta Abaco" bindLabel="descripcion">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div> {{item.codigo}} - {{item.descripcion}}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div *ngIf="cuentaAbaco" class="form-group">
                                            <h3>Cuenta Abaco</h3>
                                            <div for="">Código: {{cuentaAbaco.codigo}}</div>
                                            <!-- <input type="text" class="form-control" [(ngModel)]="cuentaAbaco.codigo"
                                                aria-describedby="helpId" placeholder=""> -->
                                            <div for="">Descripción: {{cuentaAbaco.descripcion}}</div>
                                            <!-- <input type="text" class="form-control" [(ngModel)]="cuentaAbaco.descripcion"
                                                aria-describedby="helpId" placeholder=""> -->
                                            <div for="">Tipo: {{cuentaAbaco.tipo}}</div>
                                            <!-- <ng-select [(ngModel)]="cuentaAbaco.tipo">
                                                <ng-option *ngFor="let item of ['MAYORIZADOR','IMPUTABLE']" [value]="item">
                                                    <div>
                                                        {{item}}
                                                    </div>
                                                </ng-option>
                                            </ng-select> -->
                                            <button type="button" (click)="cuentaAbaco = null"
                                                class="btn mx-1 mt-2 btn-rounded btn-inverse">Cambiar</button>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="form-group">
                                        <div *ngIf="!categoriaGasto">
                                            <ng-select [items]="categoriaGastos" name="dsfdbssaasdrwseqcda"
                                                [(ngModel)]="categoriaGasto"
                                                notFoundText="no se encontraron resultados"
                                                placeholder="buscar Categoria Gasto" bindLabel="codigo">
                                                <ng-template ng-option-tmp let-item="item">
                                                     {{item.codigo}}
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div *ngIf="categoriaGasto">
                                            <h4>Categoria: <b>{{categoriaGasto.codigo}}</b></h4>
                                            <span>Descripcion: {{categoriaGasto.descripcion}}</span>
                                            <div></div>
                                            <button type="button" (click)="categoriaGasto = null"
                                                class="btn mx-1 mt-2 btn-rounded btn-inverse">Cambiar</button>
                                        </div>
                                    </div>


                                </div>

                                <div style="width: 100%;" class="mt-auto">
                                    <div style="display: flex;">
                                        <div class="mx-auto mt-2">
                                            <button type="button" (click)="cancelarEditar()"
                                                class="btn mx-1 btn-rounded btn-outline-danger">Cancelar</button>
                                            <button type="button" (click)="guardarCambios()"
                                                class="btn mx-1 btn-rounded btn-success">Guardar Cambios</button>
                                        </div>
                                    </div>
                                </div>

                            </div>












                        </div>
                        <div *ngIf="tipoEdicion === 'cuenta_abaco'">

                            <div class="col-xs col-md">
                                <h5>Buscar Cuenta Abaco</h5>
                                <div class="form-input">
                                    <div *ngIf="!cuentaAbaco">
                                        <ng-select [typeahead]="inputcuentaAbacos" [loading]="loadingcuentaAbacos"
                                            [items]="cuentasAbaco" name="dsfdbrwseqcda" [(ngModel)]="cuentaAbaco"
                                            (ngModelChange)="seleccionarCuentaAbacoToEdit(cuentaAbaco)"
                                            notFoundText="no se encontraron resultados"
                                            placeholder="buscar Cuenta sAbaco" bindLabel="NOMBRES"
                                            [searchFn]="customSearchFn">
                                            <ng-template ng-option-tmp let-item="item">
                                                <div> {{item.codigo}} - {{item.descripcion}}

                                                </div>

                                            </ng-template>
                                        </ng-select>

                                    </div>


                                    <div *ngIf="cuentaAbaco" class="form-group">
                                        <label for="">Código</label>
                                        <input type="text" class="form-control" [(ngModel)]="cuentaAbaco.codigo"
                                            aria-describedby="helpId" placeholder="">
                                        <label for="">Descripción</label>
                                        <input type="text" class="form-control" [(ngModel)]="cuentaAbaco.descripcion"
                                            aria-describedby="helpId" placeholder="">
                                        <label for="">Tipo</label>
                                        <ng-select [(ngModel)]="cuentaAbaco.tipo">
                                            <ng-option *ngFor="let item of ['MAYORIZADOR','IMPUTABLE']" [value]="item">
                                                <div>
                                                    {{item}}
                                                </div>
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>



















                    </div>
                </div>
                <div class="tab-pane " id="listaGastos" [ngClass]="{'active': secction === 'listaGastos'}"
                    role="tabpanel">
                    <app-lista-movimientos [options]="movimientosOptions"></app-lista-movimientos>

                <div class="card">
                    <div class="card-body">
                        <h3>Reporte Resumido por meses</h3>
                        <div class="d-flex">
                           <div class="mx-auto">
                            <mat-form-field appearance="fill">
                                <mat-label>Fecha</mat-label>
                                <mat-date-range-input [formGroup]="rangeReporte" [rangePicker]="pickerReporte">
                                    <input autocomplete="off" matStartDate formControlName="start" placeholder="fecha inicio">
                                    <input matEndDate formControlName="end" placeholder="fecha fin">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="pickerReporte"></mat-datepicker-toggle>
                                <mat-date-range-picker #pickerReporte></mat-date-range-picker>
                                <mat-error *ngIf="rangeReporte.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                <mat-error *ngIf="rangeReporte.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                            </mat-form-field>
                            <div style="display: block;">

                                <button type="button" (click)="generarReporte()" class="btn btn-rounded btn-inverse mx-auto">Descargar Excel</button></div>
                           </div>
                        </div>
                    </div>
                </div>

                    <!-- <table *ngIf="!showCrearMovimiento && movimientos.length>0"
                        class="table full-color-table full-{{'inverse'}}-table hover-table   table-hover">
                        <thead>
                            <tr>
                                <th>Nro Factura</th>
                                <th style="min-width: 100px">Fecha</th>
                                <th>Proveedor</th>
                                <th>Cuenta de Gasto</th>
                                <th  >Comentario</th>
                                <th style="min-width: 150px">Monto total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (click)="goToInfo(item._id)"  *ngFor="let item of movimientos" [ngClass]="'pointer'">
                                <td>{{item.nro_factura}}</td>
                                <td>{{item.fecha_creacion_unix | date: 'dd-MM-YY'}}</td>
                                <td>{{item.proveedor?.APELLIDOS}}</td>
                                <td>{{item.nombre}}</td>
                                <td>{{item.comentario}}</td>

                                <td>{{item.monto_total | currency: '':'':'2.0'}} Gs</td>

                            </tr>

                        </tbody>
                    </table> -->
                </div>

            </div>

        </div>
    </div>

    <div *ngIf="false" class="card">
        <div class="card-body">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                <!-- This is the tree node template for leaf nodes -->
                <!-- There is inline padding applied to this node using styles.
                  This padding value depends on the mat-icon-button width. -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    {{node.descripcion}}
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <div class="mat-tree-node">
                        <b matTreeNodeToggle class="pointer px-1">
                            <i
                                [ngClass]="{'icon-arrow-right': treeControl.isExpanded(node) ? false : true, 'icon-arrow-down': treeControl.isExpanded(node) ? true : false}"></i>
                        </b> {{node.descripcion}}
                    </div>
                    <!-- There is inline padding applied to this div using styles.
                        This padding value depends on the mat-icon-button width.  -->
                    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </div>
                </mat-nested-tree-node>
            </mat-tree>
        </div>
    </div>


</div>

<app-modal-contratos (contratoSelected)="onContratoSelected($event)" [showFilters]="true" [cliente]="cliente"
    (onClose)="showModal = false" *ngIf="showModal"></app-modal-contratos>
<app-modal-cuentas-gastos *ngIf="showModalCuentas" (onSelectedCategory)="
    editandoCuentaGasto? seleccionarCuentaGastoAEditar($event) : categorySelected = $event;
    editandoCuentaPadre? seleccionarCuentaPadreToEdit($event) : categorySelected = $event;

    ocultarOnclickCategory()" (onSelected)="
    editandoCuentaGasto? seleccionarCuentaGastoAEditar($event) : '';
    editandoCuentaPadre? seleccionarCuentaPadreToEdit($event) : '';
    cuentaGasto = $event;
    cuentaGastoSelected();
    showModalCuentas = false;
    ocultarOnCategory? categorySelected = $event : ''
    " (onClose)="
showModalCuentas = false

">
</app-modal-cuentas-gastos>
