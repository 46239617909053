<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Striped Progress bar </h4>
                <div class="progress m-t-20">
                    <div class="progress-bar bg-info progress-bar-striped active" 
                        style="height:10px;" 
                        [style.width]="getProgreso1"
                        role="progressbar"> 
                        
                    </div>
                </div>

                <div class="progress m-t-20">
                    <div class="progress-bar bg-success progress-bar-striped active" 
                        style="height:10px;" 
                        [style.width]="getProgreso2"
                        role="progressbar">
                        
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <app-incrementador (valorSalida)="progreso1=$event" [valor]='progreso1'> </app-incrementador>
            </div>
        </div>

    </div>
</div>

<div class="row">
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <app-incrementador (valorSalida)="progreso2=$event" [valor]='progreso2' [btnClass]="'btn-success'"></app-incrementador>
            </div>
        </div>

    </div>
</div>