<div class="my-k">
    <!--
    <ng-template #customLoadingTemplate>

    </ng-template> -->

    <div class="row">
        <div class="col-xs col-md">
            <div class="card">
                <div class="card-body  ">
                    <span> <span class="lstick"></span>Filtros </span>
                    <div class="row">
                        <div class="col-xs col-md-5 pl-3 mr-3">
                            <p>Fondo: </p>
                            <div style="min-width: max-content;" class="inline-flex">
                                <div style="min-width: 100%;" *ngIf="!fondo">
                                    <ng-select [items]="fondos" [(ngModel)]="fondo"
                                        (ngModelChange)="seleccionarFondo(fondo._id)" (search)="searchBancos($event)"
                                        notFoundText="no se encontraron resultados" placeholder="Filtrar por fondo"
                                        bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div>
                                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>



                                                <small>{{item.RUC | ruc}}</small>
                                            </div>

                                        </ng-template>
                                    </ng-select>
                                </div>

                                <div class="ml-2" *ngIf="fondo">
                                    <span>
                                        <div *ngIf="fondo.APELLIDOS">{{fondo.APELLIDOS}} {{fondo.NOMBRES}} </div>

                                        <small>{{fondo.RUC | ruc}}</small>
                                    </span>
                                </div>
                                <div *ngIf="fondo" (click)="fondo = null; movimientosPrueba = null"
                                    class="pointer close-icon ml-2">
                                    <button type="button" (click)="seleccionarFondo(null)"
                                        class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                                </div>


                            </div>

                        </div>
                        <div class="col-xs col-md-5 pl-3 mr-3">
                            <p>Estado: </p>
                            <div class="inline-flex">
                                <div style="min-width: 100%;">
                                    <ng-select [items]="['CONCILIADOS', 'PENDIENTES', 'TODOS']" [(ngModel)]="estado"
                                        (ngModelChange)="filtrarPorEstado(estado)"
                                        notFoundText="no se encontraron resultados" placeholder="Filtrar por estado"
                                        bindLabel="NOMBRES">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div>
                                                {{item}}
                                            </div>

                                        </ng-template>
                                    </ng-select>
                                </div>

                            </div>

                        </div>
                        <div class="col-xs col-md-5 pl-3 mr-3">
                            <p>Fecha: </p>
                            <div class="inline-flex">
                                <div style="min-width: 100%;">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Rango de Fecha</mat-label>
                                        <mat-date-range-input [formGroup]="rangeFecha" [rangePicker]="pickerFecha">
                                            <input autocomplete="off" matStartDate formControlName="start"
                                                placeholder="fecha inicio">
                                            <input matEndDate formControlName="end" (dateChange)="filtroPorFecha()"
                                                placeholder="fecha fin">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="pickerFecha"></mat-datepicker-toggle>
                                        <mat-date-range-picker #pickerFecha></mat-date-range-picker>
                                        <mat-error *ngIf="rangeFecha.controls.start.hasError('matStartDateInvalid')">
                                            Invalid start date</mat-error>
                                        <mat-error *ngIf="rangeFecha.controls.end.hasError('matEndDateInvalid')">Invalid
                                            end date</mat-error>
                                    </mat-form-field>
                                </div>

                            </div>

                        </div>
                        <div class="col-xs col-md-5 pl-3 mr-3">
                            <p>Cobrador: </p>
                            <div style="min-width: 300px;" class="inline-flex">
                                <div style="min-width: 100%;" *ngIf="!cobrador">
                                    <ng-select [items]="cobradores" [(ngModel)]="cobrador"
                                        (ngModelChange)="seleccionarCobrador(cobrador._id)"
                                        (search)="searchCobradores($event)" notFoundText="no se encontraron resultados"
                                        placeholder="Filtrar por cobrador" bindLabel="NOMBRES"
                                        [searchFn]="customSearchFn">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div>
                                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                                <small>{{item.RUC | ruc}}</small>
                                            </div>

                                        </ng-template>
                                    </ng-select>
                                </div>

                                <div class="ml-2" *ngIf="cobrador">
                                    <span>
                                        <div *ngIf="cobrador.APELLIDOS">{{cobrador.APELLIDOS}} {{cobrador.NOMBRES}}
                                        </div>

                                        <small>{{cobrador.RUC | ruc}}</small>
                                    </span>
                                </div>
                                <div *ngIf="cobrador" (click)="cobrador = null; movimientosPrueba = null"
                                    class="pointer close-icon ml-2">
                                    <button type="button" (click)="seleccionarCobrador(null)"
                                        class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                                </div>


                            </div>

                        </div>
                        <!-- <div class="b-l col-md col-xs">
                            <div class="">
                                <div class="inline-flex">
                                    <div style="max-width:120%;" class="form-group p-0 mb-0">
                                        <p for="">inicio</p>
                                        <input type="text" [(ngModel)]="ngmodelstart" (ngModelChange)="calcularFecha(true, ngmodelstart)" class=" form-control" name="" id="" aria-describedby="helpId" placeholder="AAAA MM DD">
                                        <small class="ml-2">{{start | date}}</small>

                                    </div>
                                    <div style="max-width:100%;" class="ml-2 mb-0 form-group">
                                        <p for="">fin</p>
                                        <input type="text" [(ngModel)]="ngmodelend" (ngModelChange)="calcularFecha(false, ngmodelend)" class="form-control" name="" id="" aria-describedby="helpId" placeholder="AAAA MM DD">
                                        <small class="ml-2">{{end | date}}</small>
                                    </div>
                                </div>
                                <button type="button" (click)="ngOnInit()" class="btn float-right mt-1 btn-primary">Filtrar</button>

                            </div>
                        </div> -->
                    </div>


                </div>
            </div>
        </div>


        <div class="col-xs col-md-5">

            <div class="card">
                <div class="card-body ">

                    <span class="t1"> <span class="lstick"></span>Arqueo
                    </span>
                    <div class="row">
                        <div class="col-xs col-md">
                            <div class="t3">Gastos</div>
                            <span><b>Monto Total: </b>{{totalEgreso | currency: '':'':'2.0'}} Gs</span>
                            <br>
                            <!-- <span><b>Cantidad: </b>{{0}}</span> -->
                            <!-- <span><b>Total Monto: </b>{{totalMovimientos| currency: '':'':'2.0'}} Gs</span> -->
                        </div>
                        <div class="ml-3 col-xs col-md b-l">
                            <div class="t3">Ingresos</div>
                            <span><b>Monto total: </b> {{totalIngreso | currency: '':'':'2.0'}} Gs</span>
                            <br>
                            <!-- <span><b>Cantidad: </b> {{facturaCount}}</span> -->
                        </div>

                    </div>
                    <div class="row mt-3">
                        <div class="col">

                            <div style="min-width: max-content;">
                                <span class="t1"><b>Total: </b> <span
                                        [ngStyle]="TotalArqueo >= 0? {color: '#009000'} : {color: ' #d53032'}">
                                        {{ TotalArqueo | currency: '':'':'2.0'}}
                                    </span> Gs</span>
                            </div>
                        </div>
                        <div class="col">
                            <div style="min-width: max-content;">
                                <button type="button" [disabled]="!fondo " (click)="showModalBilletes = true"
                                    class="btn ml-auto btn-rounded btn-inverse">Contar Billetes</button>
                                <button type="button" [disabled]="!fondo " (click)="cerrarCaja()"
                                    class="btn ml-2 btn-rounded btn-success">Cerrar Caja</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading> -->
    <div class="card mb-0">
        <div class="card-body ">
            <!-- <div style=" width: 100%;">
                <div [style.display]="'flex'">

                </div>
            </div> -->
            <!-- <div>Saldo En fondo: {{saldoFondo | currency: '':'':'2.0'}} Gs</div> -->
            <!-- <div>Saldo + total: {{(saldoFondo + (totalFacturas + HaberMovimientos)) | currency: '':'':'2.0'}} Gs</div> -->
            <!-- <div>Saldo + Seleccionados: {{(saldoFondo + montoTotal) | currency: '':'':'2.0'}} Gs</div> -->
            <div class="position:sticky; top: 0;" style="width: 100%;">
                <div style="display: flex;">
                    <div class="mx-auto">
                        <table class="table  table-responsive">
                            <thead class="table-inverse">
                                <tr>
                                    <th class="text-center ">Ingreso <span
                                            class="badge badge-pill bg-info">{{cantidadIngreso}}</span>
                                    </th>
                                    <th class="text-center ">Egreso <span
                                            class="badge badge-pill bg-danger">{{cantidadEgreso}}</span></th>
                                    <th class="text-center ">Total <span
                                            class="badge badge-pill bg-secondary">{{cantidadTotal}}</span> </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td class="text-center px-4">{{montoIngreso | currency: '':'':'2.0'}} Gs</td>
                                    <td class="text-center px-4">{{montoEgreso | currency: '':'':'2.0'}} Gs</td>
                                    <td class="text-center px-4">{{montoTotal | currency: '':'':'2.0'}} Gs</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class=" my-1">
                        <button type="button" (click)="switchTableColor()" class="btn  btn-rounded btn-{{tableColor}}">
                            <i class="fa fa-{{iconTableColor}}"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="movimientosPrueba" style="overflow: auto; max-height: 500px;">
                <table class="table full-color-table full-{{tableColor}}-table hover-table   table-sm">
                    <thead>
                        <tr>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '15px'}">#</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '30px'}">info</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '120px'}">fecha</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '180px'}">Nro. Factura</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '120px'}">Conciliado</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '120px'}">Ingreso</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '120px'}">Egreso</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '120px'}">Saldo</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '90px'}">Nro. Cuota</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '120px'}">Nro. Contrato</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '120px'}">Vencimiento</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '200px'}">Cliente</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '100px'}">Servicio</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '400px'}">Cuenta Gasto</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '400px'}">Proveedor</th>
                            <th [ngClass]="'text-center'" [ngStyle]="{minWidth: '400px'}">Nota</th>

                        </tr>
                    </thead>
                    <tbody id="the_body">
                        <tr class="pointer" id="id-{{item._id}}" (click)="selectItem(item)"
                            *ngFor="let item of movimientosPrueba | paginate: { itemsPerPage: 100,totalItems:count, currentPage: page }; let i = index;">
                            <td>{{i+1}}</td>
                            <td>
                                <div *ngIf="item.tipo == 'INGRESO' && item.factura">
                                    <a [routerLink]="['/admin/ingreso', item.factura._id]"
                                        routerLinkActive="router-link-active" target="_blank">
                                        <i class="mdi mdi-information"> </i>
                                    </a>
                                </div>
                                <div *ngIf="item.tipo == 'EGRESO' && item.movimiento">
                                    <a [routerLink]="['/admin/gasto', item.movimiento._id]"
                                        routerLinkActive="router-link-active" target="_blank">
                                        <i class="mdi mdi-information"> </i>
                                    </a>
                                </div>
                                <div *ngIf="item.tipo == 'INGRESO' && item.movimiento">
                                    <a [routerLink]="['/admin/gasto', item.movimiento._id]"
                                        routerLinkActive="router-link-active" target="_blank">
                                        <i class="mdi mdi-information"> </i>
                                    </a>
                                </div>

                            </td>
                            <!-- fecha -->
                            <td [ngClass]="'text-center'">{{item.fecha | date: 'dd MMM yy'}}</td>
                            <!-- nro factura -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.factura">
                                    <div *ngIf="item.factura.pago   ">
                                        {{item.factura.pago.numero}}-{{fill(item.factura.pago.nro_factura, 7) }}</div>
                                    <div *ngIf="!item.factura">---</div>
                                </div>
                                <div *ngIf="item.movimiento">
                                    <div *ngIf="item.movimiento.nro_comp_banco">{{item.movimiento.nro_comp_banco }}
                                    </div>
                                    <div *ngIf="!item.movimiento.nro_comp_banco">---</div>
                                </div>
                            </td>

                            <!-- cerrado -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.cerrado">
                                    SI
                                </div>
                                <div *ngIf="!item.cerrado">
                                    NO
                                </div>
                            </td>
                            <!-- ingreso -->
                            <td [ngClass]="'text-center'" [style.color]="'#009000'">
                                <div *ngIf="item.tipo === 'INGRESO'">
                                    {{item.monto | currency: '':'':'2.0'}}
                                </div>
                                <div *ngIf="item.tipo != 'INGRESO'">
                                    ---
                                </div>
                            </td>
                            <td [ngClass]="'text-center'" [style.color]="' #d53032'">
                                <div *ngIf="item.tipo === 'EGRESO'">
                                    {{item.monto | currency: '':'':'2.0'}}
                                </div>
                                <div *ngIf="item.tipo != 'EGRESO'">
                                    ---
                                </div>
                            </td>
                            <td *ngIf="estado == 'TODOS'" [ngClass]="'text-center'"
                                [ngStyle]="item.total > 0? {color: '#009000'} : {color: ' #d53032'}">
                                {{item.total | currency: '':'':'2.0'}}

                            </td>
                            <td *ngIf="estado != 'TODOS'" [ngClass]="'text-center'">
                                ---

                            </td>
                            <!-- nro cuota -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.factura">
                                    <div *ngIf="item.factura.nro_factura   ">
                                        {{item.factura.nro_factura}}</div>
                                    <div *ngIf="!item.factura">---</div>
                                </div>

                            </td>
                            <!-- nro contrato -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.contrato">{{item.contrato?.nro_contrato | ruc}}</div>
                                <div *ngIf="!item.contrato?.nro_contrato">---</div>
                            </td>
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.factura">{{item.factura?.vencimiento | date}}</div>
                                <div *ngIf="!item.factura">---</div>
                            </td>

                            <!-- cliente -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.cliente">
                                    {{item.cliente?.APELLIDOS | primerNombre}} {{item.cliente?.NOMBRES | primerNombre}}
                                </div>
                                <div *ngIf="item.titular">
                                    {{item.titular?.APELLIDOS | primerNombre}} {{item.titular?.NOMBRES | primerNombre}}
                                </div>
                            </td>
                            <!-- servicio -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.servicio">
                                    {{item.servicio?.COD_CORTO}}
                                </div>
                                <div *ngIf="item.contrato?.producto && item.tipo != 'INGRESO'">
                                    {{item.contrato?.producto?.COD_CORTO}}
                                </div>
                            </td>
                            <!-- proveedor -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.movimiento">
                                    {{item.movimiento.nombre}}
                                </div>
                                <div *ngIf="!item.movimiento">
                                    ---
                                </div>
                            </td>

                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.movimiento">
                                    {{item.movimiento.proveedor?.APELLIDOS | primerNombre}}
                                    {{item.movimiento.proveedor?.NOMBRES | primerNombre}}
                                </div>
                                <div *ngIf="!item.movimiento">
                                    ---
                                </div>
                            </td>
                            <td [ngClass]="'text-center'">
                                {{item.movimiento?.comentario || item.factura?.nota || '---' }}
                                <!--
                                <div *ngIf="item.factura?.nota">
                                </div>
                                <div *ngIf="item.movimiento?.comentario">
                                </div> -->

                            </td>


                        </tr>
                        <tr>
                            <td scope="row"></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="false" id="the_body">
                        <tr class="pointer" id="id-{{item._id}}" (click)="selectItem(item)"
                            *ngFor="let item of movimientosPrueba; let i = index;">
                            <td>{{i+1}}</td>
                            <!-- fecha -->
                            <td [ngClass]="'text-center'">{{item.fecha_sort | date: 'dd-MM-YY'}}</td>

                            <!-- ingreso -->
                            <td [ngClass]="'text-center'" [style.color]="'#009000'">
                                <div *ngIf="item.haber">
                                    {{item.haber | currency: '':'':'2.0'}}
                                </div>
                                <div *ngIf="!item.haber">
                                    ---
                                </div>
                            </td>
                            <td [ngClass]="'text-center'" [style.color]="' #d53032'">
                                <div *ngIf="item.monto_total">
                                    {{item.monto_total | currency: '':'':'2.0'}}
                                </div>
                                <div *ngIf="!item.monto_total">
                                    ---
                                </div>
                            </td>
                            <td [ngClass]="'text-center'"
                                [ngStyle]="item.total > 0? {color: '#009000'} : {color: ' #d53032'}">{{item.total |
                                currency: '':'':'2.0'}}</td>

                            <!-- nro contrato -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.contrato">{{item.contrato?.nro_contrato | ruc}}</div>
                                <div *ngIf="!item.contrato?.nro_contrato">---</div>
                            </td>
                            <!-- nro factura -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.nro_factura >0 ">{{item.nro_factura }}</div>
                                <div *ngIf="!item.nro_factura">---</div>
                            </td>
                            <!-- cliente -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.cliente">
                                    {{item.cliente?.APELLIDOS | primerNombre}} {{item.cliente?.NOMBRES | primerNombre}}
                                </div>
                                <div *ngIf="item.titular">
                                    {{item.titular?.APELLIDOS | primerNombre}} {{item.titular?.NOMBRES | primerNombre}}
                                </div>
                            </td>
                            <!-- servicio -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.servicio">
                                    {{item.servicio?.COD_CORTO}}
                                </div>
                                <div *ngIf="item.contrato">
                                    {{item.contrato?.producto?.COD_CORTO}}
                                </div>
                            </td>
                            <!-- proveedor -->
                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.nombre">
                                    {{item.nombre}}
                                </div>
                                <div *ngIf="!item.nombre">
                                    ---
                                </div>
                            </td>




                            <td [ngClass]="'text-center'">
                                <div *ngIf="item.proveedor">
                                    {{item.proveedor?.APELLIDOS}} {{item.proveedor?.NOMBRES}}
                                </div>
                                <div *ngIf="!item.proveedor">
                                    ---
                                </div>
                            </td>



                        </tr>
                        <tr>
                            <td scope="row"></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <div style="width: 100%;">
                    <div style="display: flex;">
                        <div class="mx-auto">
                            <pagination-controls *ngIf="count>10" (pageChange)="pageChanged($event); page = $event">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!movimientosPrueba" style="overflow: auto; max-height: 500px;">
                <div *ngIf="!loading" style="width: 100%;">
                    <div style="display: flex;">
                        <div class="my-5 mx-auto">
                            <span class="display-4">Seleccione un fondo </span>

                        </div>
                    </div>
                </div>
                <div style="width: 100%;" *ngIf="loading">
                    <div style="display: flex;">
                        <div class="my-5 mx-auto">
                            <mat-spinner></mat-spinner>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div style="margin-top: 25px;"></div>
<div class="row">
    <div class="col-md col-xs">
        <div class="card">
            <div class="card-body">
                <h3>Reporte ingreso contra egreso</h3>
                <div class="d-flex">
                    <div class="mx-auto">
                        <mat-form-field appearance="fill">
                            <mat-label>Fecha</mat-label>
                            <mat-date-range-input [formGroup]="rangeReporte" [rangePicker]="pickerReporte">
                                <input autocomplete="off" matStartDate formControlName="start"
                                    placeholder="fecha inicio">
                                <input matEndDate formControlName="end" placeholder="fecha fin">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="pickerReporte"></mat-datepicker-toggle>
                            <mat-date-range-picker #pickerReporte></mat-date-range-picker>
                            <mat-error *ngIf="rangeReporte.controls.start.hasError('matStartDateInvalid')">Invalid start
                                date
                            </mat-error>
                            <mat-error *ngIf="rangeReporte.controls.end.hasError('matEndDateInvalid')">Invalid end date
                            </mat-error>
                        </mat-form-field>
                        <div style="display: block;">

                            <button type="button" (click)="generarReporte()"
                                class="btn btn-rounded btn-inverse mx-auto">Descargar Excel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md col-xs">
        <div class="card">
            <div class="card-body">
                <mat-form-field appearance="fill">
                    <mat-label>Fecha</mat-label>
                    <input matInput name="asdas" [(ngModel)]="fecha_monto_actual_fondos" [matDatepicker]="picker_fecha_monto_actual_fondos" (dateChange)="on_fecha_monto_actual_fondos() ">
                    <mat-datepicker-toggle matSuffix [for]="picker_fecha_monto_actual_fondos"></mat-datepicker-toggle>
                    <mat-datepicker #picker_fecha_monto_actual_fondos></mat-datepicker>
                </mat-form-field>
                <h3>Monto Actual de Fondos</h3>
                <table class="table table-hover">
                    <thead>
                        <tr #tableHeaad class="thead">
                            <th class="mx-3">Fondo</th>
                            <th class="mx-3">Monto</th>
                        </tr>

                    </thead>
                    <tbody>

                        <tr *ngFor="let item of listaFondosActuales">
                            <td>{{item.fondo}}</td>
                            <td>{{item.monto | currency:'':'':'2.0' }}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<app-modal-billetes *ngIf="showModalBilletes" [montoTotal]="TotalArqueo"
    (cerrarCaja)="cerrarCaja(); showModalBilletes= false" (onClose)="showModalBilletes= false"></app-modal-billetes>
