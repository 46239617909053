<div class="card ">
  <div style="min-height: 517.52px" class="card-body" *ngIf="!reciboSeleccionado">
    <div style="overflow-x: auto;" class="">
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th class="text-center">#</th>
            <th class="text-center">Cliente</th>
            <th class="text-center">Nro Factura</th>
            <th class="text-center">Monto</th>
            <th class="text-center">Fecha</th>
            <th class="text-center">Pagado</th>
          </tr>
        </thead>
        <tbody>

          <tr class="pointer" *ngFor="let item of items; let i = index" (click)="verCuotas(item)">
            <td class="text-center">{{ i + 1 }}</td>
            <td class="text-center">{{ item.cliente.APELLIDOS }}</td>
            <td class="text-center">{{ item.pago?.numero }}</td>
            <td class="text-center">{{ item.pago?.monto | currency:'':'':'2.0' }}</td>
            <td class="text-center">{{ item.fecha | date:'dd/MM/yyyy' }}</td>
            <td class="text-center">{{ item.pagado ? (item.fechaPagado | date:'dd/MM/yyyy') : 'PENDIENTE' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div *ngIf="reciboSeleccionado" class="card">
  <div class="card-body">
    Cliente: {{ reciboSeleccionado?.cliente?.NOMBRES }} {{ reciboSeleccionado?.cliente?.APELLIDOS }}
    <br />
    Fecha: {{ reciboSeleccionado?.fecha | date:'dd/MM/yyyy' }}
    <br />
    Monto: {{ reciboSeleccionado?.pago?.monto | currency:'':'':'2.0' }}

    <div class="d-flex my-3 w-100 justify-content-center">
      <button (click)="mostrarModal(reciboSeleccionado.pago?._id)" type="button" class="btn mx-2 btn-success">
        Ver Factura
      </button>
      <button (click)="reciboSeleccionado = null" type="button" class="btn btn-dark">
        Volver
      </button>
    </div>
  </div>

</div>


<div *ngIf="reciboSeleccionado">
  <app-facturas [options]="facturasOptions" [total]="montoTotal" [facturas]="facturas"></app-facturas>
</div>

<div class="" *ngIf="facturapdf">
  <app-modal-factura (onClose)="facturapdf = null" [facturaPDF]="facturapdf"></app-modal-factura>
</div>


<div *ngIf="reciboSeleccionado" class="d-flex w-100 justify-content-center">
  <div class="card">
    <div class="card-body mb-3">
      <label for="" class="form-label">Pagar</label>
      <input type="number" #montoAPagar class="form-control" name="" id="" aria-describedby="helpId" placeholder="" />
      <button (click)="pagarRecibo(montoAPagar.value)" type="button" class="btn btn-primary">
        Confirmar
      </button>

    </div>

  </div>
</div>


<div *ngIf="reciboSeleccionado" class="d-flex w-100">
  <div class="card">
    <div class="card-body mb-3" style="min-width: 400px;">
      <label for="" class="form-label">Pagados</label>
      <div *ngFor="let reciboPagado of recibosPagados">
        <div class="d-flex justify-content-between">
          <div>
            <div>Monto: {{ reciboPagado?.monto | currency:'':'':'2.0' }}</div>
            <div>Fecha: {{ reciboPagado?.fecha | date }}</div>
            <div>Nro recibo: {{ reciboPagado?.nro_recibo }}</div>
          </div>

          <div>
            <button
            (click)="printRecibo(reciboPagado)"
              type="button"
              class="btn btn-info"
            >
            <span class="mdi mdi-printer"></span>
            </button>

          </div>

        </div>
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th class="text-center">Nro Factura</th>
              <th class="text-center">Monto</th>
            </tr>
          </thead>
          <tbody>

            <tr class="pointer" *ngFor="let factura of reciboPagado.facturas; let i = index">
              <td class="text-center" scope="row">{{factura.nro_factura}}</td>
              <td class="text-center">
                <div *ngIf="factura.nro_contrato">{{factura.nro_contrato | currency:'':'':'2.0'}}</div>
                <div *ngIf="!factura.nro_contrato">---</div>
              </td>
              <td class="text-center">{{factura.haber | currency:'':'':'2.0'}} Gs</td>
            </tr>
          </tbody>
        </table>

        <hr>
      </div>

    </div>


  </div>
</div>
