<p>incrementador works!</p>
<div class="input-group">
    <span class="input-group-btn">
        <button [ngClass]="btnClass" type="button" (click)="cambiarValor(-5)"> <i class="fa fa-minus"></i></button>
    </span>
    <input type="text" class="form-control" [ngClass]="{'is-invalid': progreso >= 100 || progreso <= 0}" placeholder="Progreso" [(ngModel)]="progreso" (ngModelChange)="onChange($event)" >
    <span class="input-group-btn">
        <button [ngClass]="btnClass" type="button" (click)="cambiarValor(5)"><i class="fa fa-plus"> </i></button>
    </span>
</div>
