<div *ngIf="contrato" class="container-fluid">
    <form ngNativeValidate ngForm (ngSubmit)="editarContrato()">
        <div class="row">
            <div class="col-md-6 col-xs">
                <div style="min-width: fit-content;" class="card ">
                    <div class="card-body">
                        <div style="justify-content: space-between;" class="inline-flex">
                            <h4 class="card-title">Cliente</h4>
                            <div *ngIf="contrato.titular" (click)="contrato.titular = null"
                                class="pointer close-icon ml-auto">
                                <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                            </div>
                        </div>

                        <div *ngIf="!contrato.titular">

                            <ng-select [typeahead]="inputClientes" [loading]="loadingClientes" [items]="clientes"
                                name="dsfdbrweqcda" [(ngModel)]="contrato.titular" (ngModelChange)="null"
                                notFoundText="no se encontraron resultados" placeholder="buscar cliente"
                                bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div> {{item.APELLIDOS}} {{item.NOMBRES}} <br>
                                        <small>{{item.RUC | ruc}}</small>
                                    </div>

                                </ng-template>
                            </ng-select>

                        </div>
                        <div style="width: 100%; " *ngIf="contrato.titular ">
                            <h5>Apellidos: <b>{{contrato.titular.APELLIDOS}}</b></h5>
                            <h5>Nombres: <b>{{contrato.titular.NOMBRES}}</b></h5>

                            <h5>RUC: <b>{{contrato.titular.RUC | ruc}}</b></h5>
                            <h5>Direccion P: <b>{{contrato.titular.DIRECCION}}</b></h5>
                            <h5>Ciudad P: <b>{{contrato.titular.NOMCIUDAD}}</b></h5>
                            <h5>Direccion L: <b>{{contrato.titular.direccion_laboral}}</b></h5>
                            <h5>Ciudad L: <b>{{contrato.titular.ciudad_trabajo}}</b></h5>
                            <h5>Telefonos: <b>{{contrato.titular.TELEFONO1}}</b></h5>

                            <div class="row ">
                                <div class="col ">
                                </div>
                                <div class="col ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xs">
                <div style="min-width: fit-content;" class="card ">
                    <div class="card-body">
                        <div style="justify-content: space-between;" class="inline-flex">
                            <h4 class="card-title">Titular Alternativo</h4>
                            <div *ngIf="contrato.titular_alternativo" (click)="contrato.titular_alternativo = null"
                                class="pointer close-icon ml-auto">
                                <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                            </div>
                        </div>

                        <div *ngIf="!contrato.titular_alternativo">

                            <ng-select [items]="clientesAlternativo" name="tyhhytgrfedca"
                                [(ngModel)]="contrato.titular_alternativo" [typeahead]="inputAlternativo"
                                [loading]="loadingAlternativo" notFoundText="no se encontraron resultados"
                                placeholder="buscar titular alternativo" bindLabel="NOMBRES"
                                [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div> {{item.APELLIDOS}} {{item.NOMBRES}} <br>
                                        <small>{{item.RUC | ruc}}</small>
                                    </div>

                                </ng-template>
                            </ng-select>

                        </div>
                        <div style="width: 100%; " *ngIf="contrato.titular_alternativo ">
                            <h5>Apellidos: <b>{{contrato.titular_alternativo.APELLIDOS}}</b></h5>
                            <h5>Nombres: <b>{{contrato.titular_alternativo.NOMBRES}}</b></h5>
                            <h5>RUC: <b>{{contrato.titular_alternativo.RUC | ruc}}</b></h5>
                            <h5>Direccion P: <b>{{contrato.titular_alternativo.DIRECCION}}</b></h5>
                            <h5>Ciudad P: <b>{{contrato.titular_alternativo.NOMCIUDAD}}</b></h5>
                            <h5>Direccion L: <b>{{contrato.titular_alternativo.direccion_laboral}}</b></h5>
                            <h5>Ciudad L: <b>{{contrato.titular_alternativo.ciudad_trabajo}}</b></h5>
                            <h5>Telefonos: <b>{{contrato.titular_alternativo.TELEFONO1}}</b></h5>

                            <div class="row ">
                                <div class="col ">
                                </div>
                                <div class="col ">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xs">
                <div style="min-width: fit-content;" class="card ">
                    <div class="card-body">
                        <div style="justify-content: space-between;" class="inline-flex">
                            <h4 class="card-title">Vendedor</h4>
                            <div *ngIf="contrato.vendedor" (click)="contrato.vendedor = null"
                                class="pointer close-icon ml-auto">
                                <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                            </div>
                        </div>
                        <!-- <ng-select [items]="cars" bindLabel="name" bindValue="id" [(ngModel)]="selectedCar">
                        </ng-select> -->
                        <div *ngIf="!contrato.vendedor">

                            <ng-select [typeahead]="inputVendedor" [loading]="loadingVendedor" [items]="vendedores"
                                name="dsfdbrwenkkqcda" [(ngModel)]="contrato.vendedor"
                                notFoundText="no se encontraron resultados" placeholder="buscar vendedor"
                                bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div> {{item.APELLIDOS}} {{item.NOMBRES}} <br>
                                        <small>{{item.RUC | ruc}}</small>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div style="width: 100%; " *ngIf="contrato.vendedor ">
                            <h5>Apellidos: <b>{{contrato.vendedor.APELLIDOS}}</b></h5>
                            <h5>Nombres: <b>{{contrato.vendedor.NOMBRES}}</b></h5>
                            <h5>RUC: <b>{{contrato.vendedor.RUC | ruc}}</b></h5>
                            <h5>Direccion P: <b>{{contrato.vendedor.DIRECCION}}</b></h5>
                            <h5>Ciudad P: <b>{{contrato.vendedor.NOMCIUDAD}}</b></h5>
                            <h5>Direccion L: <b>{{contrato.vendedor.direccion_laboral}}</b></h5>
                            <h5>Ciudad L: <b>{{contrato.vendedor.ciudad_trabajo}}</b></h5>
                            <h5>Telefonos: <b>{{contrato.vendedor.TELEFONO1}}</b></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xs">
                <div style="min-width: fit-content;" class="card ">
                    <div class="card-body">
                        <div style="justify-content: space-between;" class="inline-flex">
                            <h4 class="card-title">Cobrador</h4>
                            <div *ngIf="contrato.cobrador" (click)="contrato.cobrador = null"
                                class="pointer close-icon ml-auto">
                                <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                            </div>
                        </div>
                        <div class="ng-autocomplete">
                            <div *ngIf="!contrato.cobrador">
                                <ng-select [typeahead]="inputCobrador" [loading]="loadingCobrador" [items]="cobradores"
                                    name="dsfdbrwesnkkqcda" [(ngModel)]="contrato.cobrador"
                                    notFoundText="no se encontraron resultados" placeholder="buscar cobrador"
                                    bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div>
                                            <div class="" *ngIf="item.APELLIDOS">
                                                {{item.APELLIDOS}} {{item.NOMBRES}}
                                            </div>

                                            <div *ngIf="item.RAZON"> {{item.RAZON}} </div>

                                            <small>{{item.RUC | ruc}}</small>
                                        </div>

                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div style="width: 100%; " *ngIf="contrato.cobrador">
                            <h5>Apellidos: <b>{{contrato.cobrador.APELLIDOS}}</b></h5>
                            <h5>Nombres: <b>{{contrato.cobrador.NOMBRES}}</b></h5>
                            <h5>RUC: <b>{{contrato.cobrador.RUC | ruc}}</b></h5>
                            <h5>Direccion P: <b>{{contrato.cobrador.DIRECCION}}</b></h5>
                            <h5>Ciudad P: <b>{{contrato.cobrador.NOMCIUDAD}}</b></h5>
                            <h5>Direccion L: <b>{{contrato.cobrador.direccion_laboral}}</b></h5>
                            <h5>Ciudad L: <b>{{contrato.cobrador.ciudad_trabajo}}</b></h5>
                            <h5>Telefonos: <b>{{contrato.cobrador.TELEFONO1}}</b></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md col-xs">
                <div class="card">
                    <div class="card-body">
                        <div style="justify-content: space-between;" class="inline-flex">
                            <h3 class="card-title">Contrato</h3>
                        </div>
                        <div class="form-group">
                            <div>

                                <input [(ngModel)]="editar_nro_contrato" type="checkbox"
                                    name="check_editar_nro_contrato" id="check_editar_nro_contrato"
                                    class="filled-in chk-col-light-blue" checked />
                                <label class="mx-3" for="check_editar_nro_contrato">Editar numero de contrato</label>
                            </div>
                            <label for="">Numero de Contrato: </label>
                            <input [disabled]="!editar_nro_contrato" type="number" class="form-control"
                                name="nrocontrato" autocomplete="off !important" [(ngModel)]="contrato.nro_contrato"
                                id="facturanumero" aria-describedby="helpId" placeholder="">
                        </div>
                        <mat-form-field appearance="fill">
                            <mat-label>Fecha de creacion</mat-label>
                            <input matInput name="fecha_creacion" [(ngModel)]="fecha_creacion" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div>
                            <div style="justify-content: space-between;" class="inline-flex">
                                <h3 class="card-title">Producto</h3>
                                <div *ngIf="producto" class="pointer close-icon ml-auto">
                                    <button (click)="producto = null; removerProducto()" [disabled]='!editarproducto'
                                        type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                                </div>
                            </div>
                            <ng-select *ngIf="!producto" [(ngModel)]="producto"
                                (ngModelChange)="seleccionarProducto(producto)" [items]="productos"
                                notFoundText="no se encontraron resultados" placeholder="buscar producto"
                                bindLabel="NOMBRE">
                                <ng-template ng-option-tmp let-item="item">
                                    <div> {{item.NOMBRE}} {{item.COD_CORTO}} <br>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <div class="" *ngIf="producto">
                                <h5>Nombre: <b>{{producto.NOMBRE}} - {{producto.COD_CORTO}}</b></h5>
                                <h5>Precio actual: <b>{{producto.PRECIO_MAYORISTA | currency:'':'':'2.0'}} Gs</b></h5>
                                <h5>Total: <b>{{contrato.precio_total | currency:'':'':'2.0'}} Gs</b></h5>
                                <h5 *ngIf="!esPsm">Deuda: <b>{{montoTotal.deuda | currency:'':'':'2.0'}} Gs</b></h5>

                                <h5 *ngIf="esPsm">Saldo: <b>{{saldo | currency:'':'':'2.0'}} Gs</b></h5>
                                <!-- <h5>Entrega: </h5>  -->
                                <hr>
                                <div *ngIf="!esPsm" class="mt-2 inline-flex">
                                    <h4 class=" mr-3">Forma de Pago: </h4>
                                    <div style="width: 90%;" class="row">
                                        <div class="col-md-4 col-xs">
                                            <input [disabled]='!editarproducto' [(ngModel)]="pagoradioValue"
                                                (click)="resetPlazo()" class="with-gap radio-col-blue-grey"
                                                name="idTispoPlan2" type="radio" id="radio_4" value="contado">

                                            <label for="radio_4">Contado</label>
                                        </div>
                                        <div class="col-md-4 col-xs">
                                            <input [disabled]='!editarproducto' [(ngModel)]="pagoradioValue"
                                                name="iddTipsoPlan2" class="with-gap radio-col-blue-grey" type="radio"
                                                id="radio_5" value="cuota">
                                            <label for="radio_5">Cuotas</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div *ngIf="!esPsm" class="col-md col-xs">
                                        <div class="form-group">
                                            <label for="">Entrega</label>
                                            <input [disabled]='!editarproducto' name="entrega" type="number"
                                                class="form-control" (ngModelChange)="calcularSaldo(en.value)" #en
                                                [(ngModel)]="entrega">
                                        </div>
                                        <h5>saldo <b>{{saldo | currency:'':'':'2.0'}} Gs</b></h5>
                                        <div class="form-group">
                                            <label for="">Modificar Deuda</label>
                                            <input [disabled]='!editarproducto' name="deuda" type="number"
                                                class="form-control" #deuda [(ngModel)]="montoTotal.deuda"
                                                (ngModelChange)="calcularCuotas()">
                                        </div>
                                        <h5>Deuda <b>{{montoTotal.deuda | currency:'':'':'2.0'}} Gs</b></h5>
                                    </div>
                                    <div class="col-md col-xs">
                                        <div class="form-group" [ngClass]="{'has-danger':  !plazo && editarproducto}">
                                            <label for="">plazo</label>
                                            <input [required]="!plazo && editarproducto" [disabled]='!editarproducto'
                                                name="plazo" type="number" class="form-control" [(ngModel)]="plazo"
                                                (ngModelChange)="calcularCuotas()">
                                            <div>
                                                <small class="form-control-feedback " *ngIf=" !plazo && editarproducto">
                                                    plazo es obligatorio</small>
                                            </div>
                                        </div>
                                        <h5 *ngIf="!esPsm">Monto Cuotas: <b>{{montoCuotas | currency:'':'':'2.0' }}
                                                Gs</b></h5>
                                        <h5 *ngIf="esPsm">Monto Cuotas: <b></b></h5>
                                        <input [disabled]='!editarproducto' name="saldos" type="number"
                                            class="form-control" #deuda [(ngModel)]="saldo">
                                        <h5 *ngIf="esPsm"> <b>{{saldo | currency:'':'':'2.0' }} Gs</b></h5>
                                    </div>
                                    <div class="col-md col-xs">

                                        <!-- <label for="">Fecha de pago </label>
                                        <input [disabled]='!editarproducto' type="text" placeholder="AAAA-MM-DD" class="form-control" [(ngModel)]="stringFechaPago" (ngModelChange)="calcularFechaPago()"> -->
                                        <mat-form-field appearance="fill">
                                            <mat-label>Fecha de primer pago</mat-label>
                                            <input name="fecha_primer_pago" matInput [disabled]="!editarproducto"
                                                [(ngModel)]="fechaPago" [matDatepicker]="pickerPrimerPago"
                                                (dateChange)="calcularFechaPago()">
                                            <mat-datepicker-toggle matSuffix [for]="pickerPrimerPago">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #pickerPrimerPago></mat-datepicker>
                                        </mat-form-field>
                                        <!-- <h5> <b>{{fechaPago |date}}</b></h5> -->
                                    </div>
                                </div>
                                <hr>
                                <div *ngIf="false" class="mt-2 inline-flex">
                                    <div style="width: 100%;" class="row">
                                        <div class="col-md-4 col-xs">
                                            <input [disabled]='!editarproducto' [(ngModel)]="radioValue"
                                                name="idTipotewePlan" type="radio" class="with-gap radio-col-blue-grey"
                                                id="radio_1" value="cobrador" required>
                                            <label for="radio_1">Cobrador</label>
                                        </div>
                                        <div class="col-md-4 col-xs">
                                            <input [disabled]='!editarproducto' [(ngModel)]="radioValue"
                                                name="idTripoPlan" type="radio" class="with-gap radio-col-blue-grey"
                                                id="radio_2" value="debito" required>
                                            <label for="radio_2">Débito Automático</label>
                                        </div>
                                        <div class="col-md-4 col-xs">
                                            <input [disabled]='!editarproducto' [(ngModel)]="radioValue"
                                                name="idTipoeqwqwPlan" type="radio" class="with-gap radio-col-blue-grey"
                                                id="radio_3" value="administracion" required>
                                            <label for="radio_3">Administracion</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 inline-flex">
                                    <div style="width: 100%;" class="row">
                                        <div *ngFor="let item of tipos_pago; let i = index;" class="col-md-3 col-xs">
                                            <input [(ngModel)]="contrato.tipo_pago" name="idTiporgdsPlan" type="radio"
                                                class="with-gap radio-col-blue-grey" id="radio_-{{i}}"
                                                [value]="item.value" required>
                                            <label for="radio_-{{i}}">{{item.name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" *ngIf="editarproducto" (click)="cancelarEdiciondeProducto()"
                            class="btn float-right btn-inverse">Cancelar</button>
                        <button type="button" *ngIf="!editarproducto" (click)="editarProducto()"
                            class="btn float-right btn-danger"><i class="fa fa-pencil"></i> Editar </button>
                    </div>
                </div>
                <div class="" *ngIf="facturas">
                    <div class="card">
                        <div class="card-body">
                            <h3 class="card-title">Cuotas</h3>
                            <div style="overflow: auto; max-height: 400px;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Vencimiento</th>
                                            <th>Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cuota of facturas; let i = index">
                                            <td scope="row">{{i+1}}</td>
                                            <td>{{cuota.vencimiento | date}}</td>
                                            <td>{{cuota.monto| currency:'':'':'2.0' }} Gs</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="producto">
                    <div *ngIf="!esUdp && !esPsv" class="">
                        <div class="card">
                            <div class="card-body">
                                <h3>Beneficiarios</h3>
                                <table class=" table  ">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Nombre</th>
                                            <th class="text-center">C.I.</th>
                                            <th class="text-center">Fecha Nacimiento</th>
                                            <th class="text-center">edad</th>
                                            <th class="text-center">Plus por edad</th>
                                            <th class="text-center">quitar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of contrato.beneficiarios; let i = index">
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="item.nombre"
                                                    name="naaaaamea-{{i}}" placeholder="nombre completo">
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="item.ci"
                                                    name="namsea-{{i}}" placeholder="cedula de identidad">

                                            </td>
                                            <td>
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Fecha de nacimiento</mat-label>
                                                    <input name="fecha_nacimiento-{{i}}" matInput
                                                        [(ngModel)]="item.fecha_nacimiento"
                                                        [matDatepicker]="pickernacimiento">
                                                    <mat-datepicker-toggle matSuffix [for]="pickernacimiento">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #pickernacimiento></mat-datepicker>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <label #edad for="">{{ item.fecha_nacimiento | edad}}</label>
                                            </td>
                                            <td>
                                                <div [ngClass]="{'has-danger': false}" class="form-group ">
                                                    <input type="text" class="form-control fcorm-control-danger "
                                                        [(ngModel)]="item.plus_edad" name="name-{{i}}"
                                                        (ngModelChange)="calcularPrecioConPlusPorEdad()"
                                                        placeholder="plus por edad">
                                                    <div>
                                                        <small class="form-control-feedback " *ngIf=" false">plus
                                                            por edad es
                                                            obligatorio</small>
                                                    </div>
                                                    <div>
                                                        <!-- <small class="form-control-feedback"
                                                            *ngIf="i > 2 && !item.plus_edad">plus
                                                            por cantidad es obligatorio</small> -->
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <i [ngStyle]="{fontSize: '20px'}"
                                                    (click)="contrato.beneficiarios.splice(i, 1)"
                                                    class="fa fa-trash pointer text-danger" aria-hidden="true"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="beneficiarios.length < 6" class="inline-flex">
                                    <button type="button" (click)="beneficiarioPush()"
                                        class="btn m-auto btn-circle btn-info">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="esUdp">
                        <div class="card">
                            <div class="card-body  ">
                                <h3>Ubicacion</h3>
                                <table style="width: 100%; " class=" table ">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Manzana*</th>
                                            <th class="text-center">Fila*</th>
                                            <th class="text-center">Parcela*</th>
                                            <th class="text-center">Sector*</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input required type="text" [(ngModel)]="contrato.manzana"
                                                    class="form-control" name="manzana" aria-describedby="helpId"
                                                    placeholder="">
                                            </td>
                                            <td>
                                                <input required type="text" [(ngModel)]="contrato.fila"
                                                    class="form-control" name="fila" aria-describedby="helpId"
                                                    placeholder="">
                                            </td>
                                            <td>
                                                <input required type="text" [(ngModel)]="contrato.parcela"
                                                    class="form-control" name="parcela" aria-describedby="helpId"
                                                    placeholder="">
                                            </td>
                                            <td>
                                                <input type="text" [(ngModel)]="contrato.sector" class="form-control"
                                                    name="sector" aria-describedby="helpId" placeholder="">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="servicioCMP" class="card">
                            <div class="card-body  ">
                                <h3>Cuota de Mantenimiento {{servicioCMP.PRECIO_MAYORISTA | currency: '':'':'2.0'}} Gs
                                </h3>
                                <mat-form-field appearance="fill">
                                    <mat-label>Fecha de pago</mat-label>
                                    <input matInput name="mentenimiento-picker" [(ngModel)]="fechaMantenimiento"
                                        [matDatepicker]="pickermantenimiento">
                                    <mat-datepicker-toggle matSuffix [for]="pickermantenimiento">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickermantenimiento></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="card" style="min-width: auto !important;">
                            <div class="card-body">
                                <h3>Inhumaciones</h3>
                                <div class="card" style="display: initial;">
                                    <div style="overflow-x: auto;">
                                        <table class=" table  ">
                                            <thead>
                                                <tr>
                                                    <th style="min-width: 300px;" class="text-center">Nombres y
                                                        Apellidos</th>
                                                    <th style="min-width: 200px;" class="text-center">C.I.</th>
                                                    <th style="min-width: 300px;" class="text-center">Fecha
                                                        fallecimiento</th>
                                                    <th style="min-width: 300px;" class="text-center">Fecha Inhumacion
                                                    </th>
                                                    <th style="min-width: 200px;" class="text-center">Nro Inhumacion
                                                    </th>
                                                    <th style="min-width: 200px;" class="text-center">Nacionalidad
                                                    </th>
                                                    <th style="min-width: 300px;" class="text-center">Fecha Nacimiento
                                                    </th>
                                                    <th style="min-width: 200px;" class="text-center">Certif N</th>
                                                    <th style="min-width: 200px;" class="text-center">Tomo Libro</th>
                                                    <th style="min-width: 200px;" class="text-center">Oficina</th>
                                                    <th style="min-width: 200px;" class="text-center">Folio N</th>
                                                    <th style="min-width: 200px;" class="text-center">Acta N</th>
                                                    <th style="min-width: 200px;" class="text-center">Lugar parcela</th>
                                                    <th style="min-width: 100px;" class="text-center">quitar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of contrato.inhumados; let i = index">

                                                    <td>
                                                        <input required type="text" class="form-control"
                                                            name="name-infhu-{{i}}" [(ngModel)]="item.nombre">
                                                    </td>
                                                    <td>
                                                        <input required type="text" class="form-control"
                                                            [(ngModel)]="item.ci" name="name-ianhu-{{i}}">
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>Fecha de fallecimiento</mat-label>
                                                            <input matInput name="fallecimiento-picker{{i}}"
                                                                [(ngModel)]="item.fecha_fallecimiento"
                                                                [matDatepicker]="pickerFallecimiento">
                                                            <mat-datepicker-toggle matSuffix
                                                                [for]="pickerFallecimiento">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #pickerFallecimiento></mat-datepicker>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>Fecha de inhumacion</mat-label>
                                                            <input matInput name="inhumacion-picker{{i}}"
                                                                [(ngModel)]="item.fecha_inhumacion"
                                                                [matDatepicker]="pickerinhumacion">
                                                            <mat-datepicker-toggle matSuffix [for]="pickerinhumacion">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #pickerinhumacion></mat-datepicker>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" name="name-inshu-{{i}}"
                                                            [(ngModel)]="item.nro">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" name="name-certif-{{i}}"
                                                            [(ngModel)]="item.nacionalidad">
                                                    </td>
                                                    <td>
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>Fecha de Nacimiento</mat-label>
                                                            <input matInput name="nacimiento-picker{{i}}"
                                                                [(ngModel)]="item.fecha_nacimiento"
                                                                [matDatepicker]="pickernafecha_nacimiento">
                                                            <mat-datepicker-toggle matSuffix
                                                                [for]="pickernafecha_nacimiento">
                                                            </mat-datepicker-toggle>
                                                            <mat-datepicker #pickernafecha_nacimiento></mat-datepicker>
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" name="name-certif-{{i}}"
                                                            [(ngModel)]="item.certif">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control"
                                                            name="name-tomoLibro-{{i}}" [(ngModel)]="item.tomoLibro">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control"
                                                            name="name-oficina-{{i}}" [(ngModel)]="item.oficina">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" name="name-folio-{{i}}"
                                                            [(ngModel)]="item.folio">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" name="name-acta-{{i}}"
                                                            [(ngModel)]="item.acta">
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" name="name-lugar_parcela-{{i}}"
                                                            [(ngModel)]="item.lugar_parcela">
                                                    </td>
                                                    <td class="text-center">
                                                        <i [ngStyle]="{fontSize: '20px'}"
                                                            (click)="contrato.inhumados.splice(i, 1)"
                                                            class="fa fa-trash pointer text-danger"
                                                            aria-hidden="true"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                                <div *ngIf="inhumados.length < 6" class="inline-flex">
                                    <button type="button" (click)="inhumadoPush()"
                                        class="btn m-auto btn-circle btn-info">+</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="esPsv">
                        <div class="card">
                            <div class="card-body">
                                <h3>Servicio utilizado en:</h3>
                                <table class=" table "
                                    style="min-width: 400px  ; min-height: 400px; display: block;  overflow-x: auto;">
                                    <thead>
                                        <tr>
                                            <th style="min-width: 360px;" class="text-center">Nombres y Apellidos</th>
                                            <th style="min-width: 160px;" class="text-center">C.I.</th>
                                            <th style="min-width: 160px;" class="text-center">Fecha fallecimiento</th>
                                            <th style="min-width: 160px;" class="text-center">Fecha Inhumacion</th>
                                            <th *ngIf="false" class="text-center">Nro Inhumacion</th>
                                            <th style="min-width: 360px;" class="text-center">Producto</th>
                                            <th style="min-width: 160px;" class="text-center">quitar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of contrato.inhumados; let i = index">

                                            <td>
                                                <input required type="text" class="form-control" name="dsfname-{{i}}"
                                                    [(ngModel)]="item.nombre">
                                            </td>
                                            <td>
                                                <input required type="text" class="form-control" [(ngModel)]="item.ci"
                                                    name="asdname-{{i}}">
                                            </td>
                                            <td>
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Fecha de fallecimiento</mat-label>
                                                    <input matInput name="132rdss_{{i}}"
                                                        [(ngModel)]="item.fecha_fallecimiento"
                                                        [matDatepicker]="pickerFallecimiento">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerFallecimiento">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #pickerFallecimiento></mat-datepicker>
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Fecha de inhumacion</mat-label>
                                                    <input matInput name="132rds_{{i}}"
                                                        [(ngModel)]="item.fecha_inhumacion"
                                                        [matDatepicker]="pickerinhumacion">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerinhumacion">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #pickerinhumacion></mat-datepicker>
                                                </mat-form-field>
                                            </td>
                                            <td *ngIf="false">
                                                <input type="text" class="form-control" name="edname-{{i}}"
                                                    [(ngModel)]="item.nro">
                                            </td>
                                            <td class="text-center">
                                                <ng-select name="sssssssasdasd{{i}}" [(ngModel)]="item.producto"
                                                    [items]="productos" notFoundText="no se encontraron resultados"
                                                    placeholder="buscar producto" bindLabel="COD_CORTO">
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <div>{{item.COD_CORTO}} <br>
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </td>
                                            <td class="text-center">
                                                <i [ngStyle]="{fontSize: '20px'}"
                                                    (click)="contrato.inhumados.splice(i, 1)"
                                                    class="fa fa-trash pointer text-danger" aria-hidden="true"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="inhumados.length < 6" class="inline-flex">
                                    <button type="button" (click)="inhumadoPush()"
                                        class="btn m-auto btn-circle btn-info">+</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs  col-md">
                            <div class="card">
                                <div class="card-body">
                                    <h4>Notas</h4>
                                    <textarea class="form-control" [(ngModel)]="contrato.notas" name="notas" id="notas"
                                        rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs  col-md">
                            <div class="card">
                                <div class="card-body">
                                    <h4>Relacionar Contrato</h4>
                                    <input class="form-control" type="number" placeholder="nro de contrato sin puntos"
                                        [(ngModel)]="contrato.nro_contrato_relacionado" name="contrato_relacionado"
                                        id="contrato_relacionado">
                                    <div *ngIf="esPsv">
                                        <h4>Lugar de Inhumación</h4>

                                        <input class="form-control" [(ngModel)]="contrato.lugar_inhumacion"
                                            name="lugar_inhumacion" id="lugar_inhumacion">
                                    </div>
                                    <div *ngIf="_usuarioService.usuario.role == 'ADMIN_ROLE'" class="mt-3">
                                        <h4>Estado</h4>
                                        <input [(ngModel)]="contrato.utilizado" type="checkbox" name="check_utilizado"
                                            id="check_utilizado" class="filled-in chk-col-light-blue" checked />
                                        <label class="mx-3" for="check_utilizado">Utilizado</label>

                                        <input [(ngModel)]="contrato.de_baja" type="checkbox" name="check_de_baja"
                                            id="check_de_baja" class="filled-in chk-col-light-blue" checked />
                                        <label class="mx-3" for="check_de_baja">de baja</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="width: 100%;">
                        <div style="display: flex;">
                            <button type="button" (click)="eliminarContrato()" class="btn  btn-danger">Eliminar
                                contrato</button>

                            <div class="ml-auto">

                                <button type="reset" (click)="cancelar()"
                                    class="btn mx-2  btn-inverse">Cancelar</button>

                                <button type="submit" [disabled]="disableCrearContrato()"
                                    class="btn  btn-success">Guardar
                                    <i *ngIf="!guardando" class="fa fa-check"></i>
                                    <i *ngIf="guardando" class="fa fa-spin fa-circle-o-notch"> </i>
                                </button>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="esPsm && saldoOriginal != saldo" style="width: 100%;">
                        <div style="display: flex;">
                            <div class=" mt-1 ml-auto">

                                <span class="mt-2 ml-5 s-right">*Todas las cuotas pendientes tendrán un precio de
                                    {{saldo |currency: '':'':'2.0'}}*</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
