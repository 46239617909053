<div (click)="onClick($event)" id="main-wrapper">

    <app-header></app-header>
    <app-sidebar></app-sidebar>

    <div class="page-wrapper">
        <div class="container-fluid">


            <router-outlet> </router-outlet>


        </div>
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
            © 2024 Imperial Servicio Sociales
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>


</div>
