<div class="card">
    <div style="font-size: medium;" class="card-body">
        <h4><span class="lstick"></span>Filtros </h4>

        <div class="row">

            <div class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <h4 class="card-title">Cliente</h4>
                    <div *ngIf="cliente" (click)="reset()" class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>

                <div *ngIf="!cliente">

                    <ng-select [typeahead]="inputClientes" [loading]="loadingClientes" #search [(ngModel)]="cliente"
                        [items]="clientes" (ngModelChange)="filtrar(); onSelectClient(cliente);" name="dsfdbrweqcda"
                        notFoundText="no se encontraron resultados" placeholder="buscar cliente" bindLabel="NOMBRES"
                        [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div> {{item.APELLIDOS}} {{item.NOMBRES}} <br>
                                <small>{{item.RUC | ruc}}</small>
                            </div>

                        </ng-template>
                    </ng-select>

                </div>
                <div style="width: 100%; " *ngIf="cliente ">
                    <h5>Apellidos: <b>{{cliente.APELLIDOS}}</b></h5>
                    <h5>Nombres: <b>{{cliente.NOMBRES}}</b></h5>

                    <h5>RUC: <b>{{cliente.RUC | ruc}}</b></h5>
                    <h5>Direccion P: <b>{{cliente.DIRECCION}}</b></h5>
                    <h5>Ciudad P: <b>{{cliente.NOMCIUDAD}}</b></h5>
                    <h5>Direccion L: <b>{{cliente.direccion_laboral}}</b></h5>
                    <h5>Ciudad L: <b>{{cliente.ciudad_trabajo}}</b></h5>
                    <h5>Telefonos: <b>{{cliente.TELEFONO1}}</b></h5>

                    <div class="row ">
                        <div class="col ">
                        </div>
                        <div class="col ">
                        </div>
                    </div>
                </div>
                <div class="" *ngIf="cliente">
                    <div>
                        <div *ngIf="cliente.NOMBRES">{{cliente.NOMBRES}} {{cliente.APELLIDOS}}</div>
                        <div *ngIf="cliente.NOMBRES && cliente.RAZON"> - </div>
                        <div *ngIf="cliente.RAZON">{{cliente.RAZON}} </div>

                        <small>{{cliente.RUC | ruc}}</small>
                    </div>
                </div>
            </div>

            <div class="col-xs col-md">
                <div *ngIf="cliente && !contrato" style="width: 100%;" class=" mt-3">
                    <div style="display: block; width: 100%;">
                        <div style="display: flex;">
                            <div class="mx-auto">

                                <button  type="button" (click)="showModal = true"
                                    class="btn mx-auto btn-info">Seleccionar
                                    Contrato</button>


                            </div>
                        </div>
                    </div>
                    <div style="display: block; width: 100%;">

                        <div style="display: flex;">
                            <div class="mx-auto">

                                <small for="">Si desea pagar varias cuotas a la vez seleccione un contrato</small>



                            </div>
                        </div>
                    </div>

                    <div class="mx-auto">

                    </div>
                </div>

                <div style="width: fit-content;" class="mt-3" *ngIf="contrato">
                    <div style="display: flex;" class="">
                        <span>Contrato</span>
                        <div *ngIf="contrato" style="height: fit-content;" class="pointer close-icon ml-auto">
                            <button (click)="contrato = null; filtrar()" type="button" class="btn btn-sm btn-light"><i
                                    class=" ti-close  "></i></button>
                        </div>
                    </div>

                    <div *ngIf="contrato">
                        <div style="display: flex;" class="">

                            <div class="card bg-light">
                                <div class="card-body">
                                    <span><b>Número de contrato:</b> {{contrato.nro_contrato | ruc}}</span>
                                    <br>

                                    <span><b>Titular:</b> {{contrato.titular.NOMBRES}}
                                        {{contrato.titular.APELLIDOS}}</span>
                                    <br>
                                    <span><b>Servicio:</b> {{contrato.producto.NOMBRE}}</span>
                                    <br>
                                    <br>
                                    <span><b>Fecha de creación:</b> {{contrato.fecha_creacion_unix | date }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-facturas *ngIf="!showModal && cliente" (onSelectedItem)="onSelectedItem($event)"  [is_cobranza]="true" [count]="count" [options]="opciones" [facturas]="facturas"></app-facturas>

<div *ngIf="contrato" class="card">
    <div class="card-body">
        <h4> <span class="lstick"></span>Cuotas a pagar</h4>
        <small>Contrato # {{contrato.nro_contrato | ruc}} </small>
        <form (ngSubmit)="getFacturasApagar(contrato._id, montoAPagar.value)" #form="ngForm">
            <div class="my-3 d-flex">
                <div class="mx-auto">
                    <div class="form-group">
                        <input type="number" class="form-control" #montoAPagar name="" id="" aria-describedby="helpId"
                            placeholder="ingresar monto a pagar">
                    </div>
                </div>

            </div>
            <div class="d-flex">
                <div class="mx-auto">
                    <button type="submit" class="btn btn-info">calcular</button>
                </div>

            </div>
        </form>
        <div class="" *ngIf="facturasAPagar">

            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">Cuotas</h3>

                    <div style="overflow: auto; max-height: 400px;">

                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nro Contrato</th>
                                    <th>Vencimiento</th>
                                    <th>Monto</th>
                                    <th>Servicio</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr *ngFor="let cuota of facturasAPagar; let i = index">
                                    <td scope="row">{{cuota.nro_factura }}</td>
                                    <td scope="row">{{cuota.nro_contrato | ruc}}</td>
                                    <td>{{cuota.vencimiento | date}}</td>
                                    <td>{{cuota.haber| currency:'':'':'2.0' }} Gs</td>
                                    <td>{{cuota.servicio.NOMBRE}}</td>
                                </tr>


                            </tbody>

                        </table>
                    </div>

                </div>
            </div>

            <div *ngIf="facturasAPagarAux.length < 1" class="inline-flex">


            </div>

            <div class="d-flex">
                <div class="mx-auto">
                    <button id="btnContinuar" (click)="agregarIngreso(contrato._id, montoAPagar.value)"
                        class="btn mx-2 btn-invert">Continuar</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div *ngIf="facturasAPagarAux.length >0" class="card">
    <div class="card-body">
        <h3 class="card-title">Items a pagar</h3>

        <div style="overflow: auto; max-height: 400px;">

            <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nro Contrato</th>
                        <th>Vencimiento</th>
                        <th>Monto</th>
                        <th>Servicio</th>

                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor="let cuota of facturasAPagarAux; let i = index">
                        <td scope="row">{{cuota.nro_factura}}</td>
                        <td scope="row">{{cuota.nro_contrato | ruc}}</td>
                        <td>{{cuota.vencimiento | date}}</td>
                        <td>{{cuota.haber| currency:'':'':'2.0' }} Gs</td>
                        <td>{{cuota.servicio.NOMBRE}}</td>

                    </tr>


                </tbody>

            </table>
        </div>

        <div style="width: 100%;">
            <div style="display: flex;">
                <div class="mx-auto">
                    <h3>Total: {{ sumaTotal | currency: '':'':'2.0'}} Gs</h3>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="card" *ngIf="cliente">
    <div class="card-body">
        <div class="row">
            <div class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <h4>Fondo Destino:</h4>
                    <div *ngIf="fondo" (click)="fondo = null" class="pointer close-icon ml-5">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <div class="" *ngIf="!fondo">
                    <ng-select [ngStyle]="{minWidth: '300px'}" [items]="fondos" [(ngModel)]="fondo"
                        (search)="searchBancos($event)" notFoundText="no se encontraron resultados"
                        placeholder="buscar destinp fondo" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>


                                <small>{{item.RUC | ruc}}</small>
                            </div>

                        </ng-template>
                    </ng-select>
                </div>

                <div class="" *ngIf="fondo ">
                    <h4>
                        <div *ngIf="fondo.APELLIDOS">{{fondo.NOMBRES}} {{fondo.APELLIDOS}}</div>



                        <small>{{fondo.RUC | ruc}}</small>
                    </h4>
                </div>
            </div>
            <div class="col-xs col-md">
                <div style="justify-content: space-between;" class="inline-flex">
                    <h4>Cobrador</h4>
                    <div *ngIf="cobrador" class="pointer close-icon ml-auto">
                        <button *ngIf="_userService.usuario.role != 'USER_ROLE'" (click)="cobrador = null; filtrar()"
                            type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>

                <div *ngIf="!cobrador">

                    <ng-select [typeahead]="inputCobrador" [loading]="loadingCobrador" [items]="cobradores"
                        name="dsfdbrdweqcda" [(ngModel)]="cobrador" (ngModelChange)="null"
                        notFoundText="no se encontraron resultados" placeholder="buscar cobrador" bindLabel="NOMBRES"
                        [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div> {{item.APELLIDOS}} {{item.NOMBRES}} <br>
                                <small>{{item.RUC | ruc}}</small>
                            </div>

                        </ng-template>
                    </ng-select>

                </div>

                <div class="" *ngIf="cobrador">
                    <div>
                        <div *ngIf="cobrador.APELLIDOS">{{cobrador.NOMBRES}} {{cobrador.APELLIDOS}}</div>
                        <div *ngIf="cobrador.NOMBRES && cobrador.RAZON"> - </div>
                        <div *ngIf="cobrador.RAZON">{{cobrador.RAZON}} </div>
                        <small>{{cobrador.RUC | ruc}}</small>
                    </div>
                </div>
            </div>
            <div class="col-xs col-md">
                <mat-form-field appearance="fill">
                    <mat-label>Fecha de Cobro</mat-label>
                    <input matInput name="asdrdsvet321" [(ngModel)]="fechaPago" [matDatepicker]="pickerPago">
                    <mat-datepicker-toggle matSuffix [for]="pickerPago">
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerPago></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-xs-2 col-md-2">

              <div class="input-group mb-3 d-block">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">Tasa</label>
                </div>
                <select class="custom-select" id="inputGroupSelect01" [(ngModel)]="excentaSeleccionada">
                  <option *ngFor="let opcion of tiposDeExcentas" [value]="opcion">{{ opcion }}</option>
                </select>
              </div>
            </div>
            <div class="col-xs-2 col-md-2">

              <div class="input-group mb-3 d-block">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01paraTipoDEFactura">Factura</label>
                </div>
                <select class="custom-select" id="inputGroupSelect01paraTipoDEFactura" [(ngModel)]="tipoFacturaSeleccionada">
                  <option *ngFor="let opcion of tiposDeFactura" [value]="opcion">{{ opcion }}</option>
                </select>
              </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="cliente" class="card">
    <div class="card-body">
        <h4>Datos Factura:</h4>
        <div class="row">
            <div class="col-xs col-md">
                <div class="form-group">
                    <label for="nombre">Nombre</label>
                    <input type="text" class="form-control" [(ngModel)]="nombreFactura" name="nombre" id="nombre"
                        aria-describedby="nombreid" placeholder="">
                </div>
            </div>
            <div class="col-xs col-md">
                <div class="form-group">
                    <label for="ruc">RUC/CI</label>
                    <input type="text" class="form-control" name="ruc" [(ngModel)]="rucFactura" id="ruc"
                        aria-describedby="rucid" placeholder="">
                </div>
            </div>

            <div class="col-xs col-md">
                <div class="form-group">
                    <label for="tel">Tel</label>
                    <input type="text" class="form-control" name="tel" [(ngModel)]="telFactura" id="tel"
                        aria-describedby="telid" placeholder="">
                </div>
            </div>

        </div>
        <div class="form-group">
            <label for="direccion">direccion</label>
            <input type="text" class="form-control" name="direccion" [(ngModel)]="direccionFactura" id="direccion"
                aria-describedby="direccionid" placeholder="">
        </div>
        <div class="form-group">
            <label for="direccion">Notas</label>
            <textarea class="form-control" [(ngModel)]="comentario" name="notas" id="notas" rows="5"></textarea>
        </div>

    </div>
</div>

<div class="" style="width: 100%;">
    <div style="display: flex;">
        <div class="ml-auto">
            <button type="button" [routerLink]="['/admin/crear_ingreso']" routerLinkActive="router-link-active"
                class="btn btn-info mx-auto">Crear Nuevo Ingreso</button>

            <button (click)="reset()" class="btn mx-2 btn-inverse">
                <span> Reestablecer</span>


            </button>
            <button (click)="facturaPdf = crearPDF(facturasAPagarAux) ;showPDF = true"
                [disabled]="!cobrador || facturasAPagarAux.length < 1" class="btn mx-2 btn-info">
                Vista previa PDF</button>
            <button [disabled]="!loadingConfirmarPago" style="width: 138px;" (click)="confirmarPago( )"
                [disabled]="!fondo || facturasAPagarAux.length < 1" class="btn mx-2 btn-success">
                <span *ngIf="!loadingConfirmarPago">Confirmar Pago</span>
                <div *ngIf="loadingConfirmarPago" style="width: 100%;">
                    <div style="display: flex;">

                        <div class="mx-auto">
                            <mat-spinner [color]="'primary'" [diameter]="20"></mat-spinner>
                        </div>
                    </div>
                </div>
            </button>
        </div>
    </div>
</div>
<app-modal-factura *ngIf="showPDF" (onClose)="showPDF = false" [existe]="false" [facturaPDF]="facturaPdf">
</app-modal-factura>
<app-modal-contratos (contratoSelected)="onContratoSelected($event)" [cliente]="cliente" (onClose)="showModal = false"
    *ngIf="showModal"></app-modal-contratos>

<div class="" *ngIf="facturapdf">
    <app-modal-factura (onClose)="facturapdf = null; onClosedModalFactura()" [facturaPDF]="facturapdf"></app-modal-factura>
</div>
