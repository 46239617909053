<div (click)="print($event)" class="fondo-negro animated fadeIn">

    <div id="afuera" class="modal" style="display: block;" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div *ngIf="style" [ngStyle]="style"  class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Cuentas</h5>
                    <button (click)="onClose.emit()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body " style="max-height: 700px; overflow: auto;">
                    <div class="form-material">

                        <input class="form-control" placeholder="Buscar Cuenta" autocomplete="off" [ngModel]="search" #searchInput name="search" (ngModelChange)="search = searchInput.value; setChildOk(search,dataSource.data)">

                    </div>


                    <mat-tree *ngIf="false" [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">

                        <mat-tree-node (click)="onclickitem(node)" *matTreeNodeDef="let node" matTreeNodeToggle [style.display]=" node.ok?'block':'none'">
                            <a class="effect-underline pointer">{{node.descripcion}}</a>
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [style.display]="node.ok?'block':'none'">
                            <li>
                                <div class="mat-tree-node">
                                    <b matTreeNodeToggle class="pointer px-1" [attr.aria-label]="'toggle ' + node.name">
                                        <i
                                            [ngClass]="{'icon-arrow-right': treeControl.isExpanded(node) ? false : true, 'icon-arrow-down': treeControl.isExpanded(node) ? true : false}"></i>

                                        <span class="px-2" (click)="onclickCategory(node)">{{node.descripcion}}</span>
                                    </b>
                                </div>
                                <!-- There is inline padding applied to this div using styles.
                                This padding value depends on the mat-icon-button width.  -->
                                <div [class.example-tree-invisible]="!treeControl.isExpanded(node) && !search">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </div>
                            </li>
                        </mat-nested-tree-node>
                    </mat-tree>

                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                        <mat-tree-node (click)="onclickitem(node)" *matTreeNodeDef="let node" matTreeNodeToggle [style.display]="!search || node.ok?'block':'none'">
                            <a class="effect-underline pointer">{{node.descripcion}}</a>
                        </mat-tree-node>
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [style.display]="!search || node.ok?'block':'none'">
                            <li>
                                <!-- <div class="mat-tree-node">
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.descripcion">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                    </button>
                                    <mat-checkbox [checked]="node.selected" [indeterminate]="node.indeterminate && !node.selected" (change)="todoItemSelectionToggle($event.checked,node)">{{node.descripcion}}
                                    </mat-checkbox>
                                </div> -->
                                <div class="mat-tree-node">
                                    <b matTreeNodeToggle class="pointer px-1" [attr.aria-label]="'toggle ' + node.name">
                                        <i
                                            [ngClass]="{'icon-arrow-right': treeControl.isExpanded(node) ? false : true, 'icon-arrow-down': treeControl.isExpanded(node) ? true : false}"></i>

                                        <span class="px-2" (click)="onclickCategory(node)">{{node.descripcion}}</span>
                                    </b>
                                </div>
                                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node) && !search">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </ul>
                            </li>
                        </mat-nested-tree-node>
                    </mat-tree>

                </div>

                <div class="modal-footer">
                    <button (click)="onClose.emit()" class="btn btn-rounded btn-outline-danger"> Cancelar</button>

                </div>
            </div>
        </div>
    </div>
</div>