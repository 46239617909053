<div *ngIf="es_factura == false" style="
    width: 1000px;
    height: fit-content;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  ">
  comprobante de ingreso por monto {{ total }}
</div>

<div *ngIf="factura && timbrado" class="" style="
    width: 1000px;
    height: fit-content;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  ">
  <div style="width: 1000px" class="mx-auto bg-white">
    <div style="width: 100%">
      <div style="display: flex">
        <div class="mx-auto">Factura Autoimpresor</div>
      </div>
    </div>
    <div style="margin-left: 16px" class="mt-3 row mr-3">
      <div class="p-0 col-8">
        <div style="
            padding: 8px;
            border: 2px solid;
            border-radius: 15px;
            height: 200px;
          ">
          <div class="row">
            <div class="p-x col-4">
              <img width="190px" src="../../../assets/images/logo2020.png" alt="" />
            </div>

            <div class="px-1 col-8">
              <div align="center" style="display: block; justify-content: center" class="">
                <h1>IMPERIAL</h1>
                <h4 class="m-0">Cementerio Privado - Servicios Funerarios</h4>
                <span>de <b>EDUARDO CLEBSCH S.A.</b></span>
                <hr class="m-1" />
                <span style="font-size: 14px" for="">
                  <b>Suc.:</b> Cerro Cora Nº 333 - Encarnación <br /><b>Central:</b>
                  Santo Domingo a 200 Mts de la Ruta 1 - (071) 202803
                  <br />
                  Itapúa - Paraguay
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-1 col-4">
        <div style="
            text-align: initial;
            height: 200px;
            padding: 8px;
            border: 2px solid;
            border-radius: 15px;
          ">
          <span style="font-size: 18px">
            <b>TMBRADO Nº {{ timbrado.timbrado }} <br />
              Fecha Inicio Vigencia: {{ timbrado.fecha_vigente_inicio | date }}
              <br />
              Fecha Fin Vigencia: {{ timbrado.fecha_vigente_fin | date }} <br />
              RUC: {{ timbrado.ruc }}
            </b>
            <div style="display: block">
              <div>FACTURA Nº:</div>

              <div style="font-size: 24px; margin-left: 16px" class="text-danger">
                {{ nro_talonario }}-{{ fill(nro_factura || "0", 7) }}
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="mx-3 my-1">
      <div style="
          padding: 8px;
          border: 2px solid;
          border-radius: 15px;
          height: 100%;
        ">
        <div style="display: flex" class="py-1">
          <div class="mr-auto">
            <span>Encarnación,
              <u><b>{{ factura.fecha | date: "dd" }} de
                  {{ factura.fecha | date: "MMMM" }} de
                  {{ factura.fecha | date: "YYYY" }}</b></u>
            </span>
          </div>
          <div style="width: 40%; display: flex" class="">
            <div class="mr-1">Cond. de Venta:</div>
            <div class="mr-auto">
              <span>Contado: <i class="ml-1 mdi "
                [ngClass]="{
                  'mdi-checkbox-blank-outline': pago.tipo_factura == 'CREDITO',
                  'mdi-checkbox-marked-outline': pago.tipo_factura == 'CONTADO'

                  }"
                ></i></span>
            </div>
            <div class="mr-auto">
              credito: <i class="ml-1 mdi " [ngClass]="{
                'mdi-checkbox-marked-outline': pago.tipo_factura == 'CREDITO',
                'mdi-checkbox-blank-outline': pago.tipo_factura == 'CONTADO'

                }"  ></i>
            </div>
          </div>
        </div>
        <div style="display: flex" class="py-1">
          <div class="mr-auto">
            <span>Señor(es):
              <u><b>{{ factura.nombres }}</b></u></span>
          </div>
          <div style="width: 40%; display: flex" class="">
            <div class="mr-1">
              C.I. o RUC: <u>{{ factura.ruc | ruc }}</u>
            </div>
          </div>
        </div>
        <div style="display: flex" class="py-1">
          <div class="mr-auto">
            <span>Direccion:
              <u><b>{{ factura.direccion }}</b></u></span>
          </div>
          <div style="width: 40%; display: flex" class="">
            <div class="mr-auto">
              Tel: <u>{{ factura.tel }}</u>
            </div>
            <div class="">Nota de Remisión Nº: <u></u></div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-3 my-1">
      <div style="
          padding: 0px;
          border: 2px solid;
          border-radius: 15px;
          height: 100%;
        ">
        <div style="height: 100%; display: flex" class="">
          <div style="
              width: 10%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <div class="m-auto">Cant</div>
          </div>
          <div style="
              width: 50%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <div class="m-auto">Clase de Mercaderías y/o Servicios</div>
          </div>
          <div style="
              width: 10%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <div class="m-auto">
              Precio <br />
              Unitario
            </div>
          </div>
          <div style="width: 30%; display: flex; border-bottom: 1px solid">
            <div style="width: 100%; display: block">
              <div style="display: flex" class="">
                <div class="m-auto">Valor de Venta</div>
              </div>
              <div style="width: 100%; border-bottom: 1px solid" class=""></div>
              <div class="" style="display: flex">
                <div style="width: 100%; border-right: 1px solid; display: flex">
                  <div class="m-auto">exentas</div>
                </div>

                <div style="width: 100%; display: flex; border-right: 1px solid">
                  <div class="m-auto">5%</div>
                </div>

                <div style="width: 100%; display: flex">
                  <div class="m-auto">10%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngFor="let item of items" style="height: 40px; width: 100%; display: flex" class="">
          <div style="
              width: 10%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <span class="m-auto">{{ item.cantidad }}</span>
          </div>
          <div style="
              width: 50%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <span class="m-auto">{{ item.contrato?.nro_contrato | ruc }} {{ item.concepto }}</span>
          </div>
          <div style="
              width: 10%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <span class="m-auto">{{
              item.precioUnitario | currency: "" : "" : "2.0"
              }}</span>
          </div>
          <div style="
              width: 10%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">

            <span   (click)="item.tasa = 'Excenta'" title="Click para usar tasa Excenta" [ngStyle]="{'opacity': item.tasa == 'Excenta'? '1' : '0'}"
              class="pointer m-auto">{{
              item.precio | currency: "" : "" : "2.0"
              }}</span>

          </div>
          <div style="
              width: 10%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <span   (click)="item.tasa = '5%'" title="Click para usar tasa 5%" [ngStyle]="{'opacity': item.tasa == '5%'? '1' : '0'}"
              class="pointer m-auto">{{
              item.precio | currency: "" : "" : "2.0"
              }}</span>
          </div>
          <div style="width: 10%; display: flex; border-bottom: 1px solid">
            <!-- <span class="m-auto">{{
              item.precio | currency: "" : "" : "2.0"
              }}</span> -->
              <span   (click)="item.tasa = '10%'" title="Click para usar tasa 10%" [ngStyle]="{'opacity': item.tasa == '10%' || !item.tasa? '1' : '0'}"
              class="pointer m-auto">{{
              item.precio | currency: "" : "" : "2.0"
              }}</span>
          </div>
        </div>
        <div style="height: 40px; width: 100%; display: flex" class="">
          <div style="
              width: 70%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <h2 class="ml-2 my-auto">VALOR PARCIAL</h2>
          </div>

          <div style="
              width: 10%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            "></div>
          <div style="
              width: 10%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            "></div>
          <div style="width: 10%; display: flex; border-bottom: 1px solid">
            <!-- <span class="m-auto">{{totalIva | currency: '':'':'2.0'}}</span> -->
          </div>
        </div>
        <div style="height: 80px; width: 100%; display: flex" class="">
          <div style="
              width: 75%;
              display: flex;
              border-bottom: 1px solid;
              border-right: 1px solid;
            ">
            <h2 class="ml-2 my-auto">TOTAL A PAGAR Gs:</h2>

            <span class="ml-2 my-auto">{{ totalTexto }}</span>
          </div>

          <div style="width: 25%; display: flex; border-bottom: 1px solid">
            <h2 class="m-auto">{{ total | currency: "" : "" : "2.0" }} Gs</h2>
          </div>
        </div>
        <div style="height: 40px; width: 100%; display: flex" class="">
          <div style="width: 25%; display: flex">
            <h4 class="ml-2 my-auto">LIQUIDACION DEL IVA:</h4>
          </div>
          <div style="width: 20%; display: flex">
            <span class="my-auto"> (5%): {{ totalIva5 | currency: "" : "" : "2.0" }}</span>
          </div>
          <div style="width: 20%; display: flex">
            <span class="my-auto">
              (10%): {{ totalIva10 | currency: "" : "" : "2.0" }}</span>
          </div>
          <div style="width: 35%; display: flex">
            <span class="ml-2 my-auto">TOTAL. IVA: {{ totalIva10 + totalIva5 | currency: "" : "" : "2.0" }} Gs</span>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-left: 50px; text-align: left">
      Autorizado como Autoimpresor por la SET
      <br />
      Solicitud Nº {{ timbrado.nro_solicitud }}
      <br />
      Fecha: {{ timbrado.fecha_solicitud | date }}
      <div *ngIf="printAltoke ">Nota: {{pago.comentario }}</div>
      <!-- hacer que comentario sea un textarea y tenga un boton de guardar -->
      <div *ngIf="!printAltoke && pago">
        <h5>Nota:</h5>
        <textarea [(ngModel)]="pago.comentario" class="form-control"></textarea>
        <button (click)="guardarComentario(pago._id, pago.comentario)" class="btn btn-primary">
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
