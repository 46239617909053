<div *ngIf="showFilter" class="card">
    <div class="card-body">
        <!-- <h4><span class="lstick"></span> Filtros </h4> -->
        <div class="row">
            <div class="col-xs col-md-3">
                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Cliente /CI</label>
                    <div *ngIf="cliente" (click)=" cliente = null; filtrar()" class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <div class="" *ngIf="!cliente">
                    <ng-select [typeahead]="inputClientes" [loading]="loadingClientes" [items]="clientes"
                        name="dsfdbrweqcda" [(ngModel)]="cliente" (ngModelChange)="saveClienteToRoute(); filtrar()"
                        notFoundText="no se encontraron resultados" placeholder="buscar cliente" bindLabel="NOMBRES"
                        [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS"> {{item.APELLIDOS}} {{item.NOMBRES}} </div>
                                <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                <div *ngIf="item.RAZON">{{item.RAZON}} </div>

                                <small>{{item.RUC | ruc}}</small>

                            </div>

                        </ng-template>
                    </ng-select>
                </div>
                <div class="" *ngIf="cliente">
                    <div>
                        <div *ngIf="cliente.NOMBRES">{{cliente.NOMBRES}} {{cliente.APELLIDOS}}</div>
                        <div *ngIf="cliente.NOMBRES && cliente.RAZON"> - </div>
                        <div *ngIf="cliente.RAZON">{{cliente.RAZON}} </div>

                        <small>{{cliente.RUC | ruc}}</small>
                    </div>
                </div>
            </div>

            <div class="col-xs  col-md">
                <mat-form-field appearance="fill">
                    <mat-label>Fecha de creación</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="pickerCreacion">
                        <input matStartDate formControlName="start" placeholder="fecha inicio">
                        <input matEndDate formControlName="end" (dateChange)="filtrar()" placeholder="fecha fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="pickerCreacion"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerCreacion></mat-date-range-picker>

                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha inicio incorrecta
                    </mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha fin incorrecta</mat-error>
                </mat-form-field>

                <!-- <p>Selected range: {{range.value | json}}</p> -->
            </div>

            <div class="col-xs col-md-3">

                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Cobrador</label>
                    <div *ngIf="cobrador" (click)=" cobrador = null; filtrar(); " class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <div class="" *ngIf="!cobrador">
                    <ng-select [typeahead]="inputCobradores" [loading]="loadingCobradores" [items]="cobradores"
                        name="dsfdsfdgfdbrweqcda" [(ngModel)]="cobrador"
                        (ngModelChange)="cambiarQueryParams([{cobrador: cobrador._id}]); filtrar()"
                        notFoundText="no se encontraron resultados" placeholder="buscar cobrador" bindLabel="NOMBRES"
                        [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                <div *ngIf="item.RAZON">{{item.RAZON}} </div>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>


                <div class="" *ngIf="cobrador">
                    <div>
                        <div *ngIf="cobrador.NOMBRES">{{cobrador .NOMBRES}} {{cobrador .APELLIDOS}}</div>
                        <div *ngIf="cobrador   .NOMBRES && cobrador  .RAZON"> - </div>
                        <div *ngIf="cobrador   .RAZON">{{cobrador .RAZON}} </div>

                        <small>{{cobrador.RUC | ruc}}</small>
                    </div>
                </div>
            </div>
            <div class="col-xs col-md-3">

                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Vendedor</label>
                    <div *ngIf="vendedor" (click)=" vendedor = null; filtrar(); " class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <div class="" *ngIf="!vendedor">
                    <ng-select [typeahead]="inputVendedores" [loading]="loadingVendedores" [items]="vendedores"
                        name="ddfsfdsfdgfdbrweqcda" [(ngModel)]="vendedor" (ngModelChange)="filtrar()"
                        notFoundText="no se encontraron resultados" placeholder="buscar vendedor" bindLabel="NOMBRES"
                        [searchFn]="customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <div>
                                <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                <div *ngIf="item.RAZON">{{item.RAZON}} </div>

                            </div>

                        </ng-template>
                    </ng-select>
                </div>


                <div class="" *ngIf="vendedor">
                    <div>
                        <div *ngIf="vendedor.NOMBRES">{{vendedor .NOMBRES}} {{vendedor .APELLIDOS}}</div>
                        <div *ngIf="vendedor   .NOMBRES && vendedor  .RAZON"> - </div>
                        <div *ngIf="vendedor   .RAZON">{{vendedor .RAZON}} </div>

                        <small>{{vendedor.RUC | ruc}}</small>
                    </div>
                </div>
            </div>

        </div>
        <!-- <hr> -->

        <div class="row">

            <div class="col-xs col-md-4">
                <div style="justify-content: space-between;" class="inline-flex">
                    <label>Servicio</label>
                    <div *ngIf="servicio" (click)="servicio = null; filtrar() " class="pointer close-icon ml-auto">
                        <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                    </div>
                </div>
                <ng-select *ngIf="!servicio" [(ngModel)]="servicio" (ngModelChange)="seleccionarProducto(servicio)"
                    [items]="servicios" notFoundText="no se encontraron resultados" placeholder="buscar servicio"
                    bindLabel="NOMBRE">
                    <ng-template ng-option-tmp let-item="item">
                        <div> {{item.NOMBRE}} {{item.COD_CORTO}} <br>
                        </div>
                    </ng-template>
                </ng-select>

                <div class="" *ngIf="servicio">
                    <div>
                        {{servicio.NOMBRE}}
                    </div>
                    <small>{{servicio.COD_CORTO}}</small>
                </div>
            </div>
            <div class="col-xs col-md">
                <div class="form-group">
                    <label for="manzana">manzana</label>
                    <input type="text" [(ngModel)]="manzana" (ngModelChange)="filtrar()" name="manzana"
                        id="manzanainput" class="form-control" placeholder="manzana">
                </div>
            </div>
            <div class="col-xs col-md">
                <div class="form-group">
                    <label for="fila">fila</label>
                    <input type="text" [(ngModel)]="fila" (ngModelChange)="filtrar()" name="fila" id="filainput"
                        class="form-control" placeholder="fila">
                </div>
            </div>
            <div class="col-xs col-md">
                <div class="form-group">
                    <label for="parcela">parcela</label>
                    <input type="text" name="parcela" [(ngModel)]="parcela" (ngModelChange)="filtrar()"
                        id="parcelainput" class="form-control" placeholder="parcela">
                </div>
            </div>

            <div class="col-xs col-md">
                <div class="form-group">
                    <label for="nro_contrato">Nro contrato</label>
                    <input type="text" #nroContrato [(ngModel)]="nro_contrato" (ngModelChange)=" filtrar()"
                        id='nro_contrato_search' name="nro_contrato" class="form-control"
                        placeholder="numero de contrato" aria-describedby="nrocontratoid">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs col-md-2 ">
                <h4>Estado</h4>

                <ng-select [(ngModel)]="estadoSeleccionado" (ngModelChange)="filtrar()">
                    <ng-option *ngFor="let item of ['ACTIVOS','TODOS','UTILIZADO', 'DE BAJA']" [value]="item">
                        <div>
                            {{item}}
                        </div>
                    </ng-option>
                </ng-select>

                <h4>Cod Servicio</h4>
                <div class="">
                    <ng-select [(ngModel)]="codSeleccionado" (ngModelChange)="filtrar()">
                        <ng-option *ngFor="let item of cod_servicios" [value]="item">
                            <div>
                                {{item}}
                            </div>
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-xs col-md ">
                <mat-form-field appearance="fill">
                    <mat-label>Fecha de servicio</mat-label>
                    <mat-date-range-input [formGroup]="rangeInhumado" [rangePicker]="pickerInhumado">
                        <input autocomplete="off" matStartDate formControlName="start" placeholder="fecha inicio">
                        <input matEndDate formControlName="end" (dateChange)="filtrar()" placeholder="fecha fin">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="pickerInhumado"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerInhumado></mat-date-range-picker>
                    <mat-error *ngIf="rangeInhumado.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                    <mat-error *ngIf="rangeInhumado.controls.end.hasError('matEndDateInvalid')">Invalid end date
                    </mat-error>
                </mat-form-field>

                <div>
                  <h4>Revisados</h4>

                <ng-select [(ngModel)]="revisadoSeleccionado" (ngModelChange)="retrasarFiltrar()">
                    <ng-option *ngFor="let item of ['TODOS','REVISADOS', 'SIN REVISAR']" [value]="item">
                        <div>
                            {{item}}
                        </div>
                    </ng-option>
                </ng-select>
                </div>
            </div>
            <div class="col-xs col-md mt-3">
                <h4>Beneficiarios</h4>
                <div class="row">
                    <div class="col-md col-xs">
                        <div class="form-group">
                            <label for="beneficiario_nombre">nombre</label>
                            <input type="text" [(ngModel)]="beneficiarioNombre" (ngModelChange)="filtrar()"
                                name="beneficiario_nombre" id="beneficiario_nombreinput" class="form-control"
                                placeholder="nombre de beneficiario">
                        </div>
                    </div>
                    <div class="col-md col-xs">
                        <div class="form-group">
                            <label for="beneficiario_ci">ci</label>
                            <input type="text" [(ngModel)]="beneficiarioCi" (ngModelChange)="filtrar()"
                                name="beneficiario_ci" id="beneficiario_ci" class="form-control"
                                placeholder="ci de beneficiario">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs col-md mt-3">
                <h4>Inhumados</h4>
                <div class="row">
                    <div class="col-md col-xs">
                        <div class="form-group">
                            <label for="inhumado_nombre">nombre</label>
                            <input type="text" [(ngModel)]="inhumadoNombre" (ngModelChange)="filtrar()"
                                name="inhumado_nombre" id="inhumado_nombreinput" class="form-control"
                                placeholder="nombre de inhumado">
                        </div>
                    </div>
                    <div class="col-md col-xs">
                        <div class="form-group">
                            <label for="inhumado_ci">ci</label>
                            <input type="text" [(ngModel)]="inhumadoCi" (ngModelChange)="filtrar()" name="inhumado_ci"
                                id="inhumado_ci" class="form-control" placeholder="ci de inhumado">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;">
            <div style="display: flex;">
                <div class="  mx-auto">
                    <button type="button" (click)="showFilter = false"
                        class="btn btn-sm  btn-rounded btn-outline-inverse">X</button>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="!showFilter" class="card">
    <div class="card-body">
        <div style="width: 100%;">
            <div style="display: flex;">
                <div class="  mx-auto">
                    <button type="button" (click)="showFilter = true"
                        class="btn btn-sm btn-rounded btn-inverse">menu</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-body">
        <h4 class="card-title">Contratos</h4>
        <div class="table-responsive">
            <table class="table table-sm table-hover">
                <thead>
                    <tr #tableHeaad class="thead">
                        <th>#</th>
                        <th *ngIf="_usuarioService.usuario.role=='ADMIN_ROLE'">Revisado</th>
                        <th style="min-width: 120px;" id="fecha_creacion_unix" (click)="ordenar('fecha_creacion_unix')">
                            fecha <i *ngIf="false" class="ml-1 fa fa-sort-desc"></i></th>
                        <th style="min-width: 120px;" id="nro_contrato" (click)="ordenar('nro_contrato')">Nro. Contrato
                            <i *ngIf="false" class="ml-1 fa fa-sort"></i>
                        </th>
                        <th style="min-width: 80px;" id="producto" (click)="ordenar('producto')">Servicio <i
                                *ngIf="false" class="ml-1 fa fa-sort"></i></th>
                        <th style="min-width: 200px;">Cliente </th>
                        <th style="min-width: 120px;">RUC/CI</th>
                        <th id="cantidad_inhumados">Cant. Inhm </th>
                        <th id="manzana" (click)="ordenar('manzana')">Manzana <i *ngIf="false"
                                class="ml-1 fa fa-sort"></i> </th>
                        <th id="fila" (click)="ordenar('fila')">Fila <i *ngIf="false" class="ml-1 fa fa-sort"></i> </th>
                        <th id="parcela" (click)="ordenar('parcela')">Parcela <i *ngIf="false"
                                class="ml-1 fa fa-sort"></i> </th>
                        <th id="estado" (click)="ordenar('utilizado')">Utilizado <i *ngIf="false"
                                class="ml-1 fa fa-sort"></i> </th>
                        <th id="estado" (click)="ordenar('utilizado')">De baja <i *ngIf="false"
                                class="ml-1 fa fa-sort"></i> </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- -->
                    <tr (click)="onSelectContrato(contrato)" class="pointer"
                        *ngFor="let contrato of contratos | paginate: { itemsPerPage: 10,totalItems:count, currentPage: page } ; let i = index;">
                        <td scope="row">{{page*10 + (i+1) - 10}}</td>
                        <td *ngIf="_usuarioService.usuario.role=='ADMIN_ROLE'">
                            <div class="checkbox checkbox-primary p-t-0" (click)="$event.stopPropagation(); " style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            flex-wrap: nowrap;
                        ">
                                <input id="checkbox-signup{{i}}" [(ngModel)]="contrato.revisado"
                                    (ngModelChange)="switchContratoRevisado(contrato)" name="revisado{{i}}"
                                    type="checkbox">
                                <label for="checkbox-signup{{i}}"> </label>
                            </div>
                            <!-- <input type="checkbox"   name="revisado{{i}}"
                                    class="form-check-input" value="true" id="flexCheckDefault{{i}}"> -->

                            <!-- <input type="checkbox" [(ngModel)]="contrato.revisado" name="revisado{{i}}"> -->
                            <!-- {{contrato.revisado}} -->
                        </td>
                        <td>{{contrato.fecha_creacion_unix | date}}</td>
                        <td>{{contrato.nro_contrato | ruc}}</td>
                        <td>{{contrato.producto.COD_CORTO}}</td>
                        <td *ngIf="contrato.titular">{{contrato.titular.NOMBRES | primerNombre}}
                            {{contrato.titular.APELLIDOS | primerNombre}}</td>
                        <td *ngIf="!contrato.titular">---</td>
                        <td *ngIf="contrato.titular">{{contrato.titular.RUC | ruc}}</td>
                        <div *ngIf="contrato.producto.COD_CORTO == 'P.S.V.'">
                            <td *ngIf="contrato.inhumados">{{contrato.inhumados.length}}</td>
                            <td *ngIf="!contrato.inhumados.length">0</td>

                        </div>
                        <div *ngIf="contrato.producto.COD_CORTO !== 'P.S.V.'">
                        <td *ngIf="contrato.inhumados">{{contrato.inhumados.length}}</td>
                        </div>

                        <td *ngIf="!contrato.manzana">---</td>
                        <td *ngIf="contrato.manzana">{{contrato.manzana}}</td>

                        <td *ngIf="!contrato.fila">---</td>
                        <td *ngIf="contrato.fila">{{contrato.fila}}</td>
                        <td *ngIf="!contrato.parcela">---</td>


                        <td *ngIf="contrato.parcela">{{contrato.parcela}}</td>
                        <td>
                            <div *ngIf="contrato.utilizado">SI</div>
                            <div *ngIf="!contrato.utilizado">NO</div>

                        </td>
                        <td>
                            <div *ngIf="contrato.de_baja">SI</div>
                            <div *ngIf="!contrato.de_baja">NO</div>

                        </td>

                    </tr>

                </tbody>
            </table>
        </div>
        <pagination-controls *ngIf="count> 10" (pageChange)="pageChanged($event); page = $event"></pagination-controls>
    </div>
</div>

<div class="card" style="max-width: fit-content;">
    <div class="card-body">
        <table class="table table-hover">
            <thead>
                <tr #tableHeaad class="thead">
                    <th class="mx-3">Servicio</th>
                    <th class="mx-3">Activos</th>
                    <th class="mx-3">%</th>
                    <th class="mx-3">Baja</th>
                    <th class="mx-3">%</th>
                    <th class="mx-3">Total</th>
                </tr>

            </thead>
            <tbody>
                <tr *ngFor="let item of cantidadServicios">
                    <td>{{item.servicio}}</td>
                    <td>{{item.activo}}</td>
                    <td>{{item.activo_percent | number: '1.0-0'}}</td>
                    <td>{{item.baja}}</td>
                    <td>{{item.baja_percent | number: '1.0-0'}}</td>
                    <td>{{item.count}}</td>
                    <!-- <td>{{item.percentage}}</td> -->

                </tr>
            </tbody>
        </table>

    </div>
</div>


<div *ngIf="_usuarioService.usuario.role == 'ADMIN_ROLE'" class="card">
    <div class="card-body">
        <div style="width: 100%;">
            <div style="display: flex;">
                <div class="my-5 mx-auto">
                    <button type="button" (click)="exportarPDF()" class="btn mt-2 btn-info">Exportar PDF</button>
                </div>
                <div class="my-5 mx-auto">
                    <button type="button" (click)="exportarEXCEL()" class="btn mt-2 btn-inverse">Exportar EXCEL</button>
                </div>
                <div class="my-5 mx-auto">
                    <button type="button" (click)="reporteVentas()" class="btn mt-2 btn-inverse">Reporte Ventas EXCEL</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div  *ngIf="_usuarioService.usuario.role == 'ADMIN_ROLE'" > -->
<div   >
    <div class="row">
        <div *ngIf="_usuarioService.usuario.role == 'ADMIN_ROLE'" class="col-xs col-md-4">
            <div class="card">
                <div class="card-body">
                    <h3>Reporte de Bajas</h3>
                    <div class="d-flex">
                        <div class="mx-auto">
                            <mat-form-field appearance="fill">
                                <mat-label>Fecha</mat-label>
                                <mat-date-range-input [formGroup]="rangeReporteBajas"
                                    [rangePicker]="pickerReporteBajas">
                                    <input autocomplete="off" matStartDate formControlName="start"
                                        placeholder="fecha inicio">
                                    <input matEndDate formControlName="end" placeholder="fecha fin">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="pickerReporteBajas"></mat-datepicker-toggle>
                                <mat-date-range-picker #pickerReporteBajas></mat-date-range-picker>
                                <mat-error *ngIf="rangeReporteBajas.controls.start.hasError('matStartDateInvalid')">
                                    Invalid start date</mat-error>
                                <mat-error *ngIf="rangeReporteBajas.controls.end.hasError('matEndDateInvalid')">
                                    Invalid end date</mat-error>
                            </mat-form-field>
                            <div></div>
                            <button style="width: 155.57px;" [disabled]="!rangeReporteBajas.value.start
                    || !rangeReporteBajas.value.end
                    || loadingGenerarReporteBajas " (click)="generarReporteBajas()"
                                class="btn btn-rounded mr-auto btn-inverse">
                                <span *ngIf="!loadingGenerarReporteBajas">Descargar Excel</span>
                                <div *ngIf="loadingGenerarReporteBajas" style="width: 100%;">
                                    <div style="display: flex;">

                                        <div class="mx-auto">
                                            <mat-spinner [color]="'accent'" [diameter]="20"></mat-spinner>
                                        </div>
                                    </div>
                                </div>

                            </button>

                            <!-- <div style="display: block;">

                        <button type="button" [disabled]="!rangeReporteBajas.value.start || !rangeReporteBajas.value.end" (click)="generarReporteMovimientos()" class="btn btn-rounded btn-inverse mx-auto">Descargar Excel</button></div>
                   </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-4">
            <div class="card">
                <div class="card-body">
                    <h3>Reporte de vendedores ultimos pagos</h3>
                    <div class="d-flex">
                        <div class="mx-auto">
                            <button (click)="reporteVendededoresUlt()" [disabled]="!vendedor || !range.value.end"
                                class="btn btn-inverse">
                                Generar Reporte
                            </button>

                            <br>
                            <small *ngIf="!vendedor || !range.value.end" style="color: red;">
                                Seleccione Arriba vendedor y rango de fecha de creacion de contrato
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-4">
            <div class="card">
                <div class="card-body">
                    <h3>Inhumados en Camposanto Imperial</h3>
                    <div class="d-flex">
                        <div class="mx-auto">
                            <button (click)="reporte_inhumados()" class="btn btn-inverse">
                                Generar Reporte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="_usuarioService.usuario.role == 'ADMIN_ROLE'" class="col-xs col-md-4">
            <div class="card">
                <div class="card-body">
                    <h3>Reporte usuarios Nro tarjeta</h3>
                    <div class="d-flex">
                        <div class="mx-auto">
                            <button (click)="reporte_atrasados()" class="btn btn-inverse">
                                Generar Reporte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-4">
            <div class="card">
                <div class="card-body">
                    <h3>Reporte CLIENTES Nro tarjeta</h3>
                    <small style="color: red;" *ngIf="vendedor">Vendedor: {{vendedor.NOMBRES}} {{vendedor.APELLIDOS}}</small>
                    <mat-form-field appearance="fill">
                        <mat-label>fecha de venta</mat-label>
                        <mat-date-range-input [formGroup]="ventaDeTarjetaRange" [rangePicker]="pickerVentaTarjeta">
                            <input matStartDate formControlName="start" placeholder="fecha inicio">
                            <input matEndDate formControlName="end" placeholder="fecha fin">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="pickerVentaTarjeta"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerVentaTarjeta></mat-date-range-picker>

                        <mat-error *ngIf="ventaDeTarjetaRange.controls.start.hasError('matStartDateInvalid')">Fecha inicio incorrecta
                        </mat-error>
                        <mat-error *ngIf="ventaDeTarjetaRange.controls.end.hasError('matEndDateInvalid')">Fecha fin incorrecta</mat-error>
                    </mat-form-field>
                    <div class="mt-1  d-flex">
                        <div class="mx-auto">
                            <button (click)="reporte_clientes_nro_tarjeta()" class="btn btn-inverse">
                                Generar Reporte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs col-md-4">
            <div class="card">
                <div class="card-body">
                    <h3>Reporte PSM a PSV</h3>
                    <mat-form-field appearance="fill">
                        <mat-label>Rango de fecha</mat-label>
                        <mat-date-range-input [formGroup]="CMPaPSVRange" [rangePicker]="pickerCMPaPSV">
                            <input matStartDate formControlName="start" placeholder="fecha inicio">
                            <input matEndDate formControlName="end" placeholder="fecha fin">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="pickerCMPaPSV"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerCMPaPSV></mat-date-range-picker>

                        <mat-error *ngIf="CMPaPSVRange.controls.start.hasError('matStartDateInvalid')">Fecha inicio incorrecta
                        </mat-error>
                        <mat-error *ngIf="CMPaPSVRange.controls.end.hasError('matEndDateInvalid')">Fecha fin incorrecta</mat-error>
                    </mat-form-field>
                    <div class="mt-1  d-flex">
                        <div class="mx-auto">
                            <button (click)="reporte_CMP_a_PSV()" class="btn btn-inverse">
                                Generar Reporte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
