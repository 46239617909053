<div class="">
    <div class="row">
        <div class="col-md-6 col-xs">
            <div style="min-width: fit-content;" class="card ">
                <div class="card-body">
                    <div style="justify-content: space-between;" class="inline-flex">
                        <h3 class="card-title">
                            <a *ngIf="cliente" [routerLink]="['/admin/usuario', cliente._id]"
                                routerLinkActive="router-link-active">
                                Cliente
                            </a>
                            <a *ngIf="!cliente">
                                Cliente
                            </a>
                        </h3>
                    </div>

                    <div style="width: 100%; " *ngIf="cliente ">
                        <h5>Apellidos: <b>{{cliente.APELLIDOS}}</b></h5>
                        <h5>Nombres: <b>{{cliente.NOMBRES}}</b></h5>
                        <h5>RUC: <b>{{cliente.RUC | ruc}}</b></h5>
                        <h5>Direccion P: <b>{{cliente.DIRECCION}}</b></h5>
                        <h5>Ciudad P: <b>{{cliente.NOMCIUDAD}}</b></h5>
                        <h5>Direccion L: <b>{{cliente.direccion_laboral}}</b></h5>
                        <h5>Ciudad L: <b>{{cliente.ciudad_trabajo}}</b></h5>
                        <h5>Telefono Particular: <b>{{cliente.TELEFONO1}}</b></h5>
                        <h5>Lugar Trabajo: <b>{{cliente.LUGAR_TRABAJO}}</b></h5>
                        <h5>Telefono Trabajo: <b>{{cliente.TELEFONO3}}</b></h5>
                        <div class="row ">
                            <div class="col ">
                            </div>
                            <div class="col ">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xs">
            <div style="min-width: fit-content;" class="card ">
                <div class="card-body">
                    <div style="justify-content: space-between;" class="inline-flex">
                        <h3 class="card-title">
                            <a *ngIf="titularAlternativo" [routerLink]="['/admin/usuario', titularAlternativo._id]"
                                routerLinkActive="router-link-active">
                                Titular Alternativo
                            </a>
                            <a *ngIf="!titularAlternativo">
                                Titular Alternativo
                            </a>
                        </h3>
                    </div>

                    <div style="width: 100%; " *ngIf="titularAlternativo ">
                        <h5>Apellidos: <b>{{titularAlternativo.APELLIDOS}}</b></h5>
                        <h5>Nombres: <b>{{titularAlternativo.NOMBRES}}</b></h5>
                        <h5>RUC: <b>{{titularAlternativo.RUC | ruc}}</b></h5>
                        <h5>Direccion P: <b>{{titularAlternativo.DIRECCION}}</b></h5>
                        <h5>Ciudad P: <b>{{titularAlternativo.NOMCIUDAD}}</b></h5>
                        <h5>Direccion L: <b>{{titularAlternativo.direccion_laboral}}</b></h5>
                        <h5>Ciudad L: <b>{{titularAlternativo.ciudad_trabajo}}</b></h5>
                        <h5>Telefono Particular: <b>{{titularAlternativo.TELEFONO1}}</b></h5>
                        <h5>Lugar Trabajo: <b>{{titularAlternativo.LUGAR_TRABAJO}}</b></h5>
                        <h5>Telefono Trabajo: <b>{{titularAlternativo.TELEFONO3}}</b></h5>
                        <div class="row ">
                            <div class="col ">
                            </div>
                            <div class="col ">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xs">
            <div style="min-width: fit-content;" class="card ">
                <div class="card-body">
                    <div style="justify-content: space-between;" class="inline-flex">
                        <h4 class="card-title">
                            <a *ngIf="cliente" [routerLink]="['/admin/usuario', vendedor._id]"
                                routerLinkActive="router-link-active">
                                Vendedor
                            </a>
                            <a *ngIf="!cliente">
                                Vendedor
                            </a>
                        </h4>

                    </div>
                    <!-- <ng-select [items]="cars" bindLabel="name" bindValue="id" [(ngModel)]="selectedCar">
                    </ng-select> -->

                    <div style="width: 100%; " *ngIf="vendedor ">
                        <h5>Apellidos: <b>{{vendedor.APELLIDOS}}</b></h5>
                        <h5>Nombres: <b>{{vendedor.NOMBRES}}</b></h5>
                        <h5>RUC: <b>{{vendedor.RUC | ruc}}</b></h5>
                        <h5>Direccion P: <b>{{vendedor.DIRECCION}}</b></h5>
                        <h5>Ciudad P: <b>{{vendedor.NOMCIUDAD}}</b></h5>
                        <h5>Direccion L: <b>{{vendedor.direccion_laboral}}</b></h5>
                        <h5>Ciudad L: <b>{{vendedor.ciudad_trabajo}}</b></h5>
                        <h5>Telefono Particular: <b>{{vendedor.TELEFONO1}}</b></h5>
                        <h5>Lugar Trabajo: <b>{{vendedor.LUGAR_TRABAJO}}</b></h5>
                        <h5>Telefono Trabajo: <b>{{vendedor.TELEFONO3}}</b></h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xs">
            <div style="min-width: fit-content;" class="card ">
                <div class="card-body">
                    <div style="justify-content: space-between;" class="inline-flex">
                        <h4 class="card-title">
                            <a *ngIf="cobrador" [routerLink]="['/admin/usuario', cobrador._id]"
                                routerLinkActive="router-link-active">
                                Cobrador
                            </a>
                            <a *ngIf="!cobrador">
                                Cobrador
                            </a>
                        </h4>
                    </div>
                    <div style="width: 100%; " *ngIf="cobrador">
                        <h5>Apellidos: <b>{{cobrador.APELLIDOS}}</b></h5>
                        <h5>Nombres: <b>{{cobrador.NOMBRES}}</b></h5>
                        <h5>RUC: <b>{{cobrador.RUC | ruc}}</b></h5>
                        <h5>Direccion P: <b>{{cobrador.DIRECCION}}</b></h5>
                        <h5>Ciudad P: <b>{{cobrador.NOMCIUDAD}}</b></h5>
                        <h5>Direccion L: <b>{{cobrador.direccion_laboral}}</b></h5>
                        <h5>Ciudad L: <b>{{cobrador.ciudad_trabajo}}</b></h5>
                        <h5>Telefono Particular: <b>{{cobrador.TELEFONO1}}</b></h5>
                        <h5>Lugar Trabajo: <b>{{cobrador.LUGAR_TRABAJO}}</b></h5>
                        <h5>Telefono Trabajo: <b>{{cobrador.TELEFONO3}}</b></h5>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div *ngIf="contrato" class="card-body">
            <div style="justify-content: space-between;" class="inline-flex">
                <h3 class="card-title">Contrato</h3>
            </div>
            <h5>Número de contrato: <b>{{contrato.nro_contrato | ruc}}</b></h5>
            <h5>Fecha de creación: <b>{{contrato.fecha_creacion_unix | date}}</b></h5>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div style="justify-content: space-between;" class="inline-flex">
                <h3 class="card-title">Producto</h3>
            </div>
            <div class="" *ngIf="producto">
                <h5>Nombre: <b>{{producto.NOMBRE}} - {{producto.COD_CORTO}}</b></h5>
                <h5>Precio actual: <b>{{producto.PRECIO_MAYORISTA | currency:'':'':'2.0'}} Gs</b></h5>
                <h5 *ngIf="esPsm">Plus por Edad: <b>{{saldo | currency:'':'':'2.0'}} Gs</b></h5>
                <h5>Total: <b>{{contrato.precio_total | currency:'':'':'2.0'}} Gs</b></h5>
                <h5 *ngIf="!esPsm">Deuda: <b>{{montoTotal.deuda | currency:'':'':'2.0'}} Gs</b></h5>
                <!-- <h5 *ngIf="!esPsm" >Saldo: <b>{{contrato.saldo_pendiente | currency:'':'':'2.0'}} Gs</b></h5> -->

                <!-- <h5>Entrega: </h5>  -->
                <div *ngIf="!esPsm" class="mt-2 inline-flex">
                    <h4 class=" mr-3"><b>Forma de Pago:</b>
                        <span *ngIf="contrato.plazo == 1"> Contado</span>
                        <span *ngIf="contrato.plazo != 1"> En {{contrato.plazo}} cuotas</span>
                    </h4>

                    <h4 class=" mr-3"><b>Entrega:</b>
                        <span *ngIf="contrato.entrega"> {{contrato.entrega | currency:'':'':'2.0'}} Gs</span>
                        <span *ngIf="!contrato.entrega">Sin entrega</span>
                    </h4>

                    <h4 *ngIf="contrato.entrega" class=" mr-3"><b>Total:</b> {{contrato.precio_total | currency:
                        '':'':'2.0'}} Gs
                    </h4>
                </div>
                <hr>
                <div class="mt-2 inline-flex">
                    <div style="width: 100%;" class="row">
                        <div *ngFor="let item of tipos_pago; let i = index;" class="col-md-3 col-xs">
                            <input [(ngModel)]="radioValue" name="idTipoPlan" type="radio"
                                class="with-gap radio-col-blue-grey" [value]="item.value" required>

                            <label>{{item.name}}</label>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-4 col-xs">
                        <div class="form-group">
                            <label for=""><b>Numero de contrato:</b> {{contrato.nro_contrato}}</label>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="movimientos" class="col-md col-xs">
            <app-lista-movimientos [count]="countMovimientos" [total]="totalMovimientos" [hiddeFilter]="true" [options]="egresoOptions" [movimientos]="movimientos"></app-lista-movimientos>
        </div>
        <div class="col-lg">
            <app-facturas [count]="facturasCount" [total]="montoTotal" [options]="facturaOptions" [fromOutside]='true'
                [facturas]='facturas'>
            </app-facturas>
        </div>
    </div>
    <div *ngIf="false" class="card">
        <div class="card-body">
            <h4> <span class="lstick"></span>Pagar Cuotas</h4>
            <div class="my-3 d-flex">
                <div class="mx-auto">
                    <div class="form-group">
                        <input type="text" class="form-control" #montoAPagar name="" id="" aria-describedby="helpId"
                            placeholder="ingresar monto a pagar">
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="mx-auto">
                    <button (click)="getFacturasApagar(contrato._id, montoAPagar.value)"
                        class="btn btn-info">calcular</button>
                </div>
            </div>
            <div class="" *ngIf="facturasAPagar">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Cuotas</h3>
                        <div style="overflow: auto; max-height: 400px;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Vencimiento</th>
                                        <th>Monto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let cuota of facturasAPagar; let i = index">
                                        <td scope="row">{{i+1}}</td>
                                        <td>{{cuota.vencimiento | date}}</td>
                                        <td>{{cuota.haber| currency:'':'':'2.0' }} Gs</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="inline-flex">
                    <h4 class="mr-3"><b>Fondo:</b></h4>
                    <div class="" *ngIf="!fondo">
                        <ng-select [ngStyle]="{minWidth: '300px'}" [items]="fondos" [(ngModel)]="fondo"
                            (search)="searchBancos($event)" notFoundText="no se encontraron resultados"
                            placeholder="buscar origen fondo" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                            <ng-template ng-option-tmp let-item="item">
                                <div>
                                    <div *ngIf="item.APELLIDOS">{{item.APELLIDOS}} {{item.NOMBRES}}</div>
                                    <div *ngIf="item.NOMBRES && item.RAZON"> - </div>
                                    <div *ngIf="item.RAZON">{{item.RAZON}} </div>
                                    <small>{{item.RUC | ruc}}</small>
                                </div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="" *ngIf="fondo">
                        <h4>
                            <div *ngIf="fondo.NOMBRES">{{fondo.NOMBRES}} {{fondo.APELLIDOS}}</div>
                            <div *ngIf="fondo.NOMBRES && fondo.RAZON"> - </div>
                            <div *ngIf="fondo.RAZON">{{fondo.RAZON}} </div>
                            <small>{{fondo.RUC | ruc}}</small>
                        </h4>
                    </div>

                </div>

                <div class="d-flex">
                    <div class="mx-auto">
                        <button (click)="confirmarPago(contrato._id, montoAPagar.value, fondo)" [disabled]="!fondo"
                            class="btn btn-success">Confirmar Pago</button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div *ngIf="producto">
        <div *ngIf="!esUdp && !esPsv" class="">
            <div class="card">
                <div class="card-body">
                    <h3>Beneficiarios</h3>
                    <table class=" table  ">
                        <thead>
                            <tr>
                                <th class="text-center">Nombre</th>

                                <th class="text-center">C.I.</th>
                                <th class="text-center">Fecha Nacimiento</th>
                                <th class="text-center">edad</th>
                                <th class="text-center">Plus por edad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of contrato.beneficiarios; let i = index">

                                <td class="text-center">
                                    {{item?.nombre}}
                                </td>
                                <td class="text-center">
                                    {{item?.ci| ruc}}
                                </td>
                                <td class="text-center">
                                    {{item?.fecha_nacimiento | date}}
                                </td>
                                <td class="text-center">
                                    {{item?.fecha_nacimiento | edad}}
                                </td>
                                <td class="text-center">
                                    {{item?.plus_edad}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div *ngIf="contrato.beneficiarios.length < 6" class="inline-flex">
                        <button type="button" (click)="beneficiarioPush()"
                            class="btn m-auto btn-circle btn-info">+</button>
                    </div> -->
                </div>
            </div>
        </div>

        <div *ngIf="esUdp">
            <div class="card">
                <div class="card-body  ">
                    <h3>Ubicacion</h3>
                    <table style="width: 100%; " class=" table ">
                        <thead>
                            <tr>
                                <th class="text-center">Manzana</th>
                                <th class="text-center">Fila</th>
                                <th class="text-center">Parcela</th>
                                <th class="text-center">Sector</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    {{contrato.manzana}}
                                </td>
                                <td class="text-center">
                                    {{contrato.fila}}
                                </td>
                                <td class="text-center">
                                    {{contrato.parcela}}
                                </td>
                                <td class="text-center">
                                    {{contrato.sector}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="card">
                <div *ngIf="contrato.inhumados" class="card-body">
                    <h3>Inhumaciones</h3>
                    <table class=" table  ">
                        <thead>
                            <tr>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">C.I.</th>
                                <th class="text-center">Nro Inhumacion</th>


                                <th class="text-center">Fecha fallecimiento</th>
                                <th class="text-center">Fecha Inhumacion</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of contrato.inhumados; let i = index">
                                <td class="text-center">
                                    {{item?.nombre}}
                                </td>
                                <td class="text-center">
                                    {{item?.ci | ruc}}
                                </td>
                                <td class="text-center">
                                    {{item?.nro}}
                                </td>
                                <td class="text-center">
                                    {{item?.fecha_fallecimiento| date}}
                                </td>
                                <td class="text-center">
                                    {{item?.fecha_inhumacion | date}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div> -->
            <div class="card" style="min-width: auto !important;">
                <div class="card-body">
                    <h3>Inhumaciones</h3>
                    <div class="card" style="display: initial;">
                        <div style="overflow-x: auto;">
                            <table class=" table  ">
                                <thead>
                                    <tr>
                                        <th style="min-width: 300px;" class="text-center">Nombres y
                                            Apellidos</th>
                                        <th style="min-width: 200px;" class="text-center">C.I.</th>
                                        <th style="min-width: 300px;" class="text-center">Fecha
                                            fallecimiento</th>
                                        <th style="min-width: 300px;" class="text-center">Fecha Inhumacion
                                        </th>
                                        <th style="min-width: 200px;" class="text-center">Nro Inhumacion
                                        </th>
                                        <th style="min-width: 200px;" class="text-center">Nacionalidad
                                        </th>
                                        <th style="min-width: 300px;" class="text-center">Fecha Nacimiento
                                        </th>
                                        <th style="min-width: 200px;" class="text-center">Certif N</th>
                                        <th style="min-width: 200px;" class="text-center">Oficina</th>
                                        <th style="min-width: 200px;" class="text-center">Tomo Libro</th>
                                        <th style="min-width: 200px;" class="text-center">Folio N</th>
                                        <th style="min-width: 200px;" class="text-center">Acta N</th>
                                        <th style="min-width: 200px;" class="text-center">Lugar parcela</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of contrato.inhumados; let i = index">

                                        <td class="text-center">
                                            {{item?.nombre}}
                                        </td>
                                        <td class="text-center">
                                            {{item?.ci | ruc}}
                                        </td>

                                        <td class="text-center">
                                            {{item?.fecha_fallecimiento| date}}
                                        </td>
                                        <td class="text-center">
                                            {{item?.fecha_inhumacion | date}}
                                        </td>
                                        <td class="text-center">
                                            {{item?.nro}}
                                        </td>
                                        <td class="text-center">
                                            {{item?.nacionalidad }}
                                        </td>
                                        <td class="text-center">
                                            {{item?.fecha_nacimiento | date}}
                                        </td>
                                        <td class="text-center">
                                            {{item?.certif }}
                                        </td>

                                        <td class="text-center">
                                            {{item?.oficina }}
                                        </td>
                                        <td class="text-center">
                                            {{item?.tomoLibro }}
                                        </td>
                                        <td class="text-center">
                                            {{item?.folio }}
                                        </td>
                                        <td class="text-center">
                                            {{item?.acta }}
                                        </td>
                                        <td class="text-center">
                                            {{item?.lugar_parcela }}
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>


                </div>
            </div>

        </div>

        <div *ngIf="esPsv && contrato.inhumados">
            <div class="card">
                <div class="card-body">
                    <h3>Servicio utilizado en:</h3>
                    <table class=" table  ">
                        <thead>
                            <tr>
                                <th class="text-center">Nombres y Apellidos</th>
                                <th class="text-center">C.I.</th>
                                <th class="text-center">Nro Inhumacion</th>
                                <th class="text-center">Fecha fallecimiento</th>
                                <th class="text-center">Fecha Inhumacion</th>
                                <th class="text-center">Producto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of contrato.inhumados; let i = index">

                                <td>
                                    {{item?.nombre}}
                                </td>
                                <td>
                                    {{item?.ci}}
                                </td>
                                <td>
                                    {{item?.nro}}
                                </td>
                                <td>
                                    {{item?.fecha_fallecimiento}}
                                </td>
                                <td>
                                    {{item?.fecha_inhumacion}}
                                </td>
                                <td>
                                    {{item?.producto?.COD_CORTO}}
                                </td>


                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs  col-md">
                <div class="card">
                    <div class="card-body">
                        <h4>Notas</h4>
                        <textarea readonly class="form-control" [(ngModel)]="contrato.notas" name="notas" id="notas"
                            rows="5"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-xs  col-md">
                <div class="card">
                    <div class="card-body">
                        <h4>Relacionar Contrato</h4>
                        <input readonly class="form-control" type="number" placeholder="nro de contrato sin puntos"
                            [(ngModel)]="contrato.nro_contrato_relacionado" name="contrato_relacionado"
                            id="contrato_relacionado">
                        <div *ngIf="esPsv">
                            <h4>Lugar de Inhumación</h4>
                            <input readonly class="form-control" [(ngModel)]="contrato.lugar_inhumacion"
                                name="lugar_inhumacion" id="lugar_inhumacion">
                        </div>
                        <div class="mt-3">
                            <h4>Estado</h4>
                            <input [(ngModel)]="contrato.utilizado" type="checkbox" name="check_utilizado"
                                id="check_utilizado" class="filled-in chk-col-light-blue"
                                (click)="$event.preventDefault()" checked />
                            <label class="mx-3" for="check_utilizado">Utilizado</label>

                            <input [(ngModel)]="contrato.de_baja" type="checkbox" name="check_de_baja"
                                id="check_de_baja" class="filled-in chk-col-light-blue"
                                (click)="$event.preventDefault()" checked />
                            <label class="mx-3" for="check_de_baja">de baja</label>
                            <label class=""><b>Fecha de baja:</b> {{contrato.fecha_de_baja | date }}</label>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 100%;">
        <div style="display: flex;">
            <div class="ml-auto">
                <button type="button" [routerLink]="['/admin/editar_contrato', this.id]"
                    class="btn btn-rounded mr-2 btn-inverse"> <i class="fa fa-pencil" aria-hidden="true"></i> Editar
                    Contrato</button>
                <!-- <button type="button" (click)="crearMovimiento()" class="btn   btn-rounded btn-info">Crear
                    Movimiento</button> -->
                <button type="button" (click)="showModalPdf = true" class="btn btn-rounded  mx-2 btn-success">Ver
                    PDF</button>
            </div>
        </div>
    </div>


</div>

<div class="" *ngIf="showModalPdf">
    <app-modal-pdf [contrato]="contrato" (onClose)="showModalPdf = false"> </app-modal-pdf>
</div>
