<div (click)="print($event)" class="fondo-negro animated fadeIn">
  <div id="afuera" class="modal" style="display: block" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Factura</h5>
          <button
            (click)="onClose.emit()"
            type="button"
            class="pointer close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="facturaPDF?.activo === false" style="width: 100%">
          <div style="display: flex">
            <div class="mx-auto">
              <h1 class="text-danger">Cancelado</h1>
            </div>
          </div>
        </div>

        <div *ngIf="style" [ngStyle]="style" class="modal-body" align="center">
          <app-factura-pdf
            [printAltoke]="false"
            [existe]="existe"
            [facturaPDF]="facturaPDF"
          ></app-factura-pdf>
          <!-- <app-pdf-factura-mobile [printAltoke]="false" [existe]="existe" [facturapdf]="facturaPDF"></app-pdf-factura-mobile> -->
        </div>
        <!-- <div class="p-2">
                    <span>Nota: {{facturaPDF.comentario}}</span>
                </div> -->
        <div class="modal-footer">
          <button
            style="width: 143.84px"
            [disabled]="loadingCancelarPago || facturaPDF?.activo === false"
            (click)="cancelarPago()"
            class="btn btn-rounded mr-auto btn-inverse"
          >
            <span *ngIf="!loadingCancelarPago">Cancelar Pago</span>
            <div *ngIf="loadingCancelarPago" style="width: 100%">
              <div style="display: flex">
                <div class="mx-auto">
                  <mat-spinner [color]="'accent'" [diameter]="20"></mat-spinner>
                </div>
              </div>
            </div>
          </button>

          <button
            *ngIf="facturaStatus == 'factura' || facturaStatus == 'nota'"
            style="width: 173.84px"
            [disabled]="loadingNotaCredito "
            (click)="notaCredito()"
            class="btn btn-rounded ml-auto btn-danger"
          >
            <span *ngIf="!loadingNotaCredito">Nota de Credito</span>
            <div *ngIf="loadingNotaCredito" style="width: 100%">
              <div style="display: flex">
                <div class="mx-auto">
                  <mat-spinner [color]="'accent'" [diameter]="20"></mat-spinner>
                </div>
              </div>
            </div>
          </button>

          <button
            *ngIf="facturaStatus == 'factura' || facturaStatus == 'sin_factura'"
            style="width: 173.84px"
            [disabled]="loadingFE || facturaPDF?.activo === false"
            (click)="facturaElectronica()"
            class="btn btn-rounded ml-auto btn-inverse"
          >
            <span *ngIf="!loadingFE">Factura Electrónica</span>
            <div *ngIf="loadingFE" style="width: 100%">
              <div style="display: flex">
                <div class="mx-auto">
                  <mat-spinner [color]="'accent'" [diameter]="20"></mat-spinner>
                </div>
              </div>
            </div>
          </button>
          <button
            *ngIf="facturaStatus == 'factura' || facturaStatus == 'sin_factura'"
            style="width: 173.84px"
            [disabled]="loadingFE || facturaPDF?.activo === false"
            (click)="KUDETicket()"
            class="btn btn-rounded ml-auto btn-inverse"
          >
            <span *ngIf="!loadingFE">KUDE Ticket</span>
            <div *ngIf="loadingFE" style="width: 100%">
              <div style="display: flex">
                <div class="mx-auto">
                  <mat-spinner [color]="'accent'" [diameter]="20"></mat-spinner>
                </div>
              </div>
            </div>
          </button>
          <button
            (click)="onClose.emit()"
            class="btn btn-rounded btn-outline-secondary"
          >
            Cerrar
          </button>
          <button (click)="printTicket()" class="btn btn-rounded btn-inverse">
            Ticket
          </button>
          <button (click)="printContrato()" class="btn btn-rounded btn-success">
            Imprimir
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
