<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<span class="t2">
    <a class=" nav-link waves-effect " (click)="onback()" href="javascript:void(0)"><i class="ti-angle-double-left"></i></a> {{title | titleBreadcrumb | titlecase}}
</span>
<div *ngIf="false" class="row page-titles">
    <div class="col-md-5 inline-flex align-self-center">
        <!-- <h3 class="  mx-1">
            

        </h3> -->

    </div>
    <div *ngIf="false" class="col-md-7 align-self-center">
        <ol class="breadcrumb">
            <li *ngFor="let item of urlbrm" class="breadcrumb-item">
                <a href="javascript:void(0)">{{item}}</a>
            </li>
            <!-- <li class="breadcrumb-item">Contratos</li>
            <li class="breadcrumb-item active">Nuevo contrato</li> -->
        </ol>
    </div>

</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->