<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class=" left-sidebar">
    <!-- Sidebar scroll-->
    <div  class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul *ngIf="menuItems" id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                        <div *ngIf="sidebarService?._usuario?.usuario" class="inline-flex">
                            <ngx-avatar size='30' name="{{[sidebarService._usuario.usuario.NOMBRES, sidebarService._usuario.usuario.APELLIDOS ] | nombreCorto }}"></ngx-avatar>

                            <span class="hide-menu ml-2 mt-2">{{[sidebarService._usuario.usuario.NOMBRES, sidebarService._usuario.usuario.APELLIDOS ] | nombreCorto }}</span>
                        </div>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a *ngIf="usuario" href="/admin/usuario/{{usuario._id}}">My Profile </a></li>
                        <li><a href="javascript:void()">My Balance</a></li>
                        <li><a href="javascript:void()">Inbox</a></li>
                        <li><a routerLink="account-settings">Account Setting</a></li>
                        <li><a routerLink="/login">Logout</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">MENU</li>
                <li (click)="$event.preventDefault(); onclickParent()" *ngFor="let item of menuItems"> <a class="has-arrow waves-effect waves-dark"  aria-expanded="false"><i
                            [ngClass]="item.icono" ></i><span class="hide-menu"> {{item.titulo}} <span
                                class="label label-rouded label-themecolor pull-right">{{item?.submenu?.length}}</span></span></a>
                    <ul aria-expanded="false" class="collapse">

                        <li *ngFor="let submenu of item.submenu"><a (click)="onclickItem($event)" id="{{submenu.url}}" routerLinkActive="crear_contrato" [routerLink]="submenu.url">{{submenu.titulo}} </a></li>


                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>

<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->