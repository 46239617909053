<div style="width: 100%; height: 60px"></div>
<div class="w-100 bg-white  sticky-top d-flex" style="position: fixed;">
  <div class="mx-auto mt-1 " style="font-size: larger; width: 100%">
    <div style="
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
   ">
      <Button class="btn btn-info" (click)="onScale(-.1)">-</Button>
      <ng-select #productsearch id="searchclient" style="width: 500px;" [typeahead]="inputClientes"
        [loading]="loadingClientes" [items]="clientes" name="dsfdbrweqcda" [(ngModel)]="cliente"
        (click)="selectContrato()" (ngModelChange)="null" notFoundText="no se encontraron resultados"
        placeholder="buscar" bindLabel="NOMBRES" [searchFn]="customSearchFn">
        <ng-template ng-option-tmp let-item="item">
          <div> {{item.inhumados.nombre}}

          </div>

        </ng-template>
      </ng-select>
      <Button class="btn btn-info" (click)="onScale(.1)">+</Button>

    </div>

  </div>
  <div class="mt-1 me-1">
    <button
    (click)="refresh()"
      type="button"
      class="btn btn-light"
    >
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 3V8M21 8H16M21 8L18 5.29168C16.4077 3.86656 14.3051 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.2832 21 19.8675 18.008 20.777 14" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
    </button>

  </div>
</div>

<app-mapa [publico]="true" [ubicacion_contrato]="ubicaion_contrato"></app-mapa>
