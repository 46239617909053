<div class="row">
    <!-- <div class="col-xs col-md">
        <div class="card">
            <div class="card-body">
                <h4><span class="lstick"></span>Filtros</h4>
                <div class="row">
                    <div class="col-xs col-md"><button type="button" *ngIf="!agruparPorcierres" (click)="switchAgrupar()" class="btn btn-info">Agrupar por Cierres</button>
                        <button type="button" *ngIf="agruparPorcierres" (click)="switchAgrupar()" class="btn btn-light">Agrupar por Cierres</button>
                    </div>
                    <div class="col-xs col-md">
                        <div style="justify-content: space-between;" class="inline-flex">
                            <h3 class="card-title">Cliente</h3>
                            <div *ngIf="cliente" (click)="cliente = null" class="pointer close-icon ml-auto">
                                <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                            </div>
                        </div>

                        <div *ngIf="!cliente">

                            <ng-select [items]="clientes" [(ngModel)]="cliente" (search)="searchClientes($event)" notFoundText="no se encontraron resultados" placeholder="buscar cliente" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div> {{item.APELLIDOS}} {{item.NOMBRES}} <br>
                                        <small>{{item.RUC | ruc}}</small>
                                    </div>

                                </ng-template>
                            </ng-select>

                        </div>
                        <div class="" *ngIf="cliente">
                            <div>
                                <div *ngIf="cliente.NOMBRES">{{cliente.NOMBRES}} {{cliente.APELLIDOS}}</div>
                                <div *ngIf="cliente.NOMBRES && cliente.RAZON"> - </div>
                                <div *ngIf="cliente.RAZON">{{cliente.RAZON}} </div>

                                <small>{{cliente.RUC | ruc}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs col-md">
                        <div style="justify-content: space-between;" class="inline-flex">
                            <h3 class="card-title">Vendedor</h3>
                            <div *ngIf="vendedor" (click)="vendedor = null" class="pointer close-icon ml-auto">
                                <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                            </div>
                        </div>

                        <div *ngIf="!vendedor">
                            <ng-select [items]="vendedores" [(ngModel)]="vendedor" (search)="searchVendedore($event)" notFoundText="no se encontraron resultados" placeholder="buscar vendedor" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div> {{item.APELLIDOS}} {{item.NOMBRES}} <br>
                                        <small>{{item.RUC | ruc}}</small>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-xs col-md">
                        <div style="justify-content: space-between;" class="inline-flex">
                            <h3 class="card-title">Cobrador</h3>
                            <div *ngIf="cobrador" (click)="cobrador = null" class="pointer close-icon ml-auto">
                                <button type="button" class="btn btn-sm btn-light"><i class=" ti-close  "></i></button>
                            </div>
                        </div>

                        <div *ngIf="!cobrador">
                            <ng-select [items]="cobradores" [(ngModel)]="cobrador" (search)="searchCobrador($event)" notFoundText="no se encontraron resultados" placeholder="buscar cobrador" bindLabel="NOMBRES" [searchFn]="customSearchFn">
                                <ng-template ng-option-tmp let-item="item">
                                    <div> {{item.APELLIDOS}} {{item.NOMBRES}} <br>
                                        <small>{{item.RUC | ruc}}</small>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-xs col-md"></div>
                </div>
            </div>
        </div>
    </div> -->
</div>
<div class="row">
    <div *ngIf="agruparPorcierres" class="col-xs col-md-5">
        <div class="card">
            <div class="card-body">
                <h4><span class="lstick"></span>Cierres de caja</h4>
                <div class="">
                    <div class="row">
                        <div class="col-xs col-md">
                            <div class="form-group">
                                <label for="fecha_inicio">Fecha inicio :
                                    <span><b>{{date_start | date}}</b></span>
                                </label>
                                <input type="text" name="fecha_inicio" id="fecha_inicio" class="form-control"
                                    placeholder="AAAA MM DD" aria-describedby="fechainicioid">
                                <small id="fechainicioid" class="text-muted">Fecha de inicio en el formato
                                    indicado</small>


                            </div>
                        </div>
                        <div class="col-xs b-r col-md">
                            <div class="form-group">
                                <label for="fecha_fin">Fecha fin:
                                    <span><b>{{date_end | date}}</b></span>
                                </label>
                                <input type="text" name="fecha_fin" id="fecha_fin" class="form-control"
                                    placeholder="AAAA MM DD" aria-describedby="fechafinid">
                                <small id="fechafinid" class="text-muted">Fecha de fin en el formato indicado</small>
                            </div>
                        </div>
                        <div class="p-auto mx-3 my-auto"><button type="button" class="btn btn-primary">filtrar</button>
                        </div>
                    </div>
                </div>

                <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Apertura</th>
                            <th>Cierre</th>
                            <th>Egresos</th>
                            <th>Ingresos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of cierres; let i = index">
                            <td scope="row">{{i+1}}</td>

                            <td>{{item.fecha_apertura_unix| date: 'short'}}</td>
                            <td>{{item.fecha_cierre_unix | date: 'short'}}</td>
                            <td>{{item.monto_egreso| currency: '':'':'2.0'}} Gs</td>
                            <td>{{item.monto_ingreso| currency: '':'':'2.0'}} Gs</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="!agruparPorcierres" class="col-xs col-md-5">
        <div class="card">
            <div class="card-body">
                <h4><span class="lstick"></span>Filtrar por fecha</h4>
                <div class="row">
                    <div class="col-xs col-md">
                        <div class="form-group">
                            <label for="fecha_inicio">Fecha inicio :
                                <span><b>{{date_start | date}}</b></span>
                            </label> <input type="text" name="fecha_inicio" id="fecha_inicio" class="form-control"
                                placeholder="AAAA MM DD" aria-describedby="fechainicioid">
                            <!-- <small id="fechainicioid" class="text-muted">Fecha de inicio en el formato
                                indicado</small> -->
                        </div>
                    </div>
                    <div class="col-xs b-r col-md">
                        <div class="form-group">
                            <label for="fecha_fin">Fecha fin:
                                <span><b>{{date_end | date}}</b></span>
                            </label> <input type="text" name="fecha_fin" id="fecha_fin" class="form-control"
                                placeholder="AAAA MM DD" aria-describedby="fechafinid">
                            <!-- <small id="fechafinid" class="text-muted">Fecha de fin en el formato indicado</small> -->
                        </div>
                    </div>
                    <div class="p-auto mx-3 my-auto"><button type="button" class="btn btn-primary">filtrar</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="false" class="col-xs col-md-6">
        <div class="card">
            <div class="card-body">
                <div style="display: block;">
                    <canvas baseChart width="400" height="400" [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType"></canvas>
                </div>
            </div>
        </div>
    </div>


</div>

<div class="card">
    <div class="p-0 card-body">
        <div class="mx-3 mt-3 d-flex">
            <h4 class="mr-auto">Total en Ingresos: {{totalIngresos| currency: '':'':'2.0'}} Gs</h4>
            <h4 class="mr-auto">Total en Movimientos: {{totalMovimientos| currency: '':'':'2.0'}} Gs</h4>
        </div>
        <div class="d-flex">
            <h1 class="mx-auto">Total: {{totalIngresos + totalMovimientos | currency: '':'':'2.0'}} Gs</h1>

        </div>
        <hr>
        <!-- Nav tabs -->
        <ul class="nav nav-tabs profile-tab" role="tablist">
            <li class="nav-item"> <a class="nav-link  active" data-toggle="tab" href="#home" role="tab">Ingresos</a>
            </li>
            <li class="nav-item"> <a class="nav-link " data-toggle="tab" href="#profile" role="tab">Egresos</a>
            </li>

        </ul>
        <!-- Tab panes -->
        <div class="tab-content">
            <div class="tab-pane active" id="home" role="tabpanel">
                <app-facturas [count]="facturasCount" [showlabel]="false" [fondo]="null" [cerrado]="null"
                    [pagado]="true" [start]="date_start" [end]="date_end" [count]="facturasCount" [facturas]="facturas"
                    [fromOutside]="true">
                </app-facturas>
            </div>
            <!--second tab-->
            <div class="tab-pane" id="profile" role="tabpanel">
                <app-lista-movimientos [showTitle]='false' [movimientos]="movimientos"></app-lista-movimientos>
            </div>

        </div>
    </div>
</div>

<div class="card">
    <div class="card-body">
        <table class="table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Tipo Servicio</th>
                    <th *ngFor="let categoria of categorias">{{categoria.descripcion}}</th>
                    <th>Venta Parcela</th>
                    <th>Gastos</th>
                    <th>Ingresos</th>
                    <th>Diferencia</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let gasto of gastos; let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{gasto.fecha_creacion_unix | date: 'dd/MM/YY'}}</td>
                    <td>{{gasto.tipo_servicio }}</td>
                    <td *ngFor="let categoria of categorias">
                        <div *ngFor="let itemGasto of gasto.gastos">
                            <div *ngIf="categoria.descripcion === itemGasto.descripcion">
                                {{itemGasto.monto_total | currency: '':'':'2.0'}}
                            </div>
                        </div>
                    </td>
                    <td>{{gasto.monto_parcela | currency: '':'':'2.0'}}</td>
                    <td>{{gasto.total_gastos | currency: '':'':'2.0'}}</td>
                    <td>{{gasto.total_ingresos   | currency: '':'':'2.0'}}</td>
                    <td>{{gasto.total_ingresos - gasto.total_gastos   | currency: '':'':'2.0'}}</td>

                </tr>
            </tbody>
        </table>
    </div>
</div>